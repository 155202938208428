export const QuestionListActors = {
    TOGGLE_QUESTION:'TOGGLE_QUESTION',
    QUESTION_LIST:'QUESTION_LIST',
    TOGGLE_OPEN_QUESTION:'TOGGLE_OPEN_QUESTION',
    OPEN_QUESTION_LIST:'OPEN_QUESTION_LIST',
    TOGGLE_PENDING_QUESTION:'TOGGLE_PENDING_QUESTION',
    PENDING_QUESTION_LIST:'PENDING_QUESTION_LIST',
    TOGGLE_WAITING_QUESTION:'TOGGLE_WAITING_QUESTION',
    WAITING_QUESTION_LIST:'WAITING_QUESTION_LIST',
    TOGGLE_SPAMMED_QUESTION:'TOGGLE_SPAMMED_QUESTION',
    SPAMMED_QUESTION_LIST:'SPAMMED_QUESTION_LIST',
    TOGGLE_DELETED_QUESTION:'TOGGLE_DELETED_QUESTION',
    DELETED_QUESTION_LIST:'DELETED_QUESTION_LIST'
}