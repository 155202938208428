import { SpamReplyActors } from "../Actors/SpamRepliesActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    SpamReplyList:{data:{body:[]},count:{},isLoaded:false},
};

const SpamReplyListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SpamReplyActors.SPAM_REPLY_LIST:
            return {
                ...state,
                SpamReplyList:action.payload
            }
        case SpamReplyActors.SPAM_REPLY_TOGGLE:
            return {
                ...state,
                SpamReplyList:{...state.SpamReplyList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default SpamReplyListReducer