import { QuestionListActors } from "../Actors/QuestionListActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    QuestionList:{data:{body:[]},count:{},isLoaded:false},
    OpenQuestionList:{data:{},isLoaded:false},
    PendingQuestionList:{data:{},isLoaded:false},
    WaitingQuestionList:{data:{},isLoaded:false},
    SpammedQuestionList:{data:{},isLoaded:false},
    DeletedQuestionList:{data:{},isLoaded:false}
};

const QuestionListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QuestionListActors.QUESTION_LIST:
            return {
                ...state,
                QuestionList:action.payload
            }
        case QuestionListActors.TOGGLE_QUESTION:
            console.log("Reached")
            return {
                ...state,
                QuestionList:{...state.QuestionList,isLoaded:false}
            }
        case QuestionListActors.DELETED_QUESTION_LIST:
            return {
                ...state,
                DeletedQuestionList:action.payload
            }
        case QuestionListActors.TOGGLE_DELETED_QUESTION:
            return {
                ...state,
                DeletedQuestionList:{...state.DeletedQuestionList,isLoaded:false}
            }
        case QuestionListActors.OPEN_QUESTION_LIST:
            return {
                ...state,
                OpenQuestionList:action.payload
            }
        case QuestionListActors.TOGGLE_OPEN_QUESTION:
            return {
                ...state,
                OpenQuestionList:{...state.OpenQuestionList,isLoaded:false}
            }
        case QuestionListActors.PENDING_QUESTION_LIST:
            return {
                ...state,
                PendingQuestionList:action.payload
            }
        case QuestionListActors.TOGGLE_PENDING_QUESTION:
            return {
                ...state,
                PendingQuestionList:{...state.PendingQuestionList,isLoaded:false}
            }
        case QuestionListActors.SPAMMED_QUESTION_LIST:
            return {
                ...state,
                SpammedQuestionList:action.payload
            }
        case QuestionListActors.TOGGLE_SPAMMED_QUESTION:
            return {
                ...state,
                SpammedQuestionList:{...state.SpammedQuestionList,isLoaded:false}
            }
            case QuestionListActors.WAITING_QUESTION_LIST:
                return {
                    ...state,
                    WaitingQuestionList:action.payload
                }
            case QuestionListActors.TOGGLE_WAITING_QUESTION:
                    return {
                        ...state,
                        WaitingQuestionList:{...state.WaitingQuestionList,isLoaded:false}
                    }
        default:
            return state;
    }
};

export default QuestionListReducer;
