export const UserListPageActors = {
    TOGGLE_INFO_POPUP: 'TOGGLE_INFO_POPUP',
    TOGGLE_DOWNLOAD_POPUP: 'TOGGLE_DOWNLOAD_POPUP',
    SELECTED_ROWS: 'SELECTED_ROWS',
    SELECTED_USERS: 'SELECTED_USERS',
    SELECTED_USER: 'SELECTED_USER',
    EMPTY_SELECTED_USERS: 'EMPTY_SELECTED_USERS',
    USER_LIST:'USER_LIST',
    TOGGLE_USER_LIST:'TOGGLE_USER_LIST',
    ACTIVE_USER_LIST:'ACTIVE_USER_LIST',
    TOGGLE_ACTIVE_USER_LIST:'TOGGLE_ACTIVE_USER_LIST',
    BLOCKED_USER_LIST:'BLOCKED_USER_LIST',
    TOGGLE_BLOCKED_USER_LIST:'TOGGLE_BLOCKED_USER_LIST',
    DELETED_USER_LIST:'DELETED_USER_LIST',
    TOGGLE_DELETED_USER_LIST:'TOGGLE_DELETED_USER_LIST',
    
};
