import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import "../../../assets/stylesheets/common.scss";
import "./AdminsPage.scss";

import StatTile from "../../Common/StatsTile/StatTile";
import Button from "../../Common/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../Common/Table/Table";
import PopupForm from "./PopupForm";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import BlockedPopup from "../../Common/BlockedPopup/BlockedPopup";
import Pagination from "../../Common/Pagination/Pagination";
import queryString from "query-string";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import Spinner from "../../Common/Spinner/Spinner";
import PDFIcon from "../../../assets/icons/PDFIcon";
import CSVIcon from "../../../assets/icons/CSVIcon";
import XLSIcon from "../../../assets/icons/XLSIcon";
// import { send } from '../../WebSocket/WebSocket';
import { useLocation } from "react-router";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../../Api";
import HeaderWithSearch from "../../Common/HeaderWithSearch/HeaderWithSearch";
import { AdminsActors } from "../../../redux/Actors/Admin";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminsPage = ({
  loading,
  AdminsList,
  ActiveList,
  DeactiveList,
  DeletedList,
  permissionIds,
}) => {
  // console.log(AdminsList)
  const navigate = useNavigate();
  const { status } = useParams();
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [countData, setCountData] = useState({
    active: "",
    inactive: "",
    deleted: "",
  });
  const location = useLocation();
  const permission =
    permissionIds.permissionIds[location.pathname.split("/")[1]];
  console.log(
    permission,
    permissionIds?.userPermissions[permission - 1]?.is_enabled === true
  );

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [compLoading, setCompLoading] = useState(true);
  const dispatch = useDispatch();
  const headerLayout = "1fr 1fr 1.5fr 5fr 2fr 2fr ";
  const columnLayout = "1fr 1fr 1.5fr 5fr 2fr 2fr ";
  const header = [
    <div className="checkbox">
      <input
        type="checkbox"
        id="header"
        checked={selectAllChecked}
        onChange={() => {
          setSelectAllChecked(!selectAllChecked);
          !selectAllChecked && userData && userData.result
            ? setSelectedElements(userData.result.map((data) => data.id))
            : setSelectedElements([]);
        }}
      />
      <label htmlFor="header" />
    </div>,
    "#",
    "Avatar",
    // 'Admin Name',
    "Admin Email ID",
    "Admin Role",
    "Status",
  ];

  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchField(e.target.value);
  };

  const handleCheck = (key) => {
    selectedElements.includes(key)
      ? setSelectedElements(
          selectedElements.filter(function (item) {
            return item !== key;
          })
        )
      : setSelectedElements([...selectedElements, key]);
  };

  useEffect(() => {
    if (selectedElements?.length === listData?.length)
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
  }, [selectedElements, listData]);

  const getStatus = (stat) => {
    if (stat === "all") return "All";
    else if (stat === "active") return "Activated";
    else if (stat === "inactive") return "Deactivated";
    else if (stat === "deleted") return "Deleted";
    else if (stat === "blocked") return "Blocked";
  };
  const loadPage = ({ limit, offset }) => {
    const status_name = getStatus(status);
    offset = offset ? offset : 0;
    // console.log(status_name)
    const apiCall = () => {
      try {
        setSelectedElements([]);
        setCompLoading(true);

        Api.getAdmins({ searchField, status: status_name, limit, offset })
          .then((res) => {
            console.log(res, status_name, limit, offset);
            if (limit === "" && offset === 0) {
              if (status_name === "All")
                dispatch({
                  type: "ADMINS_LIST",
                  payload: { data: res.data, isLoaded: true },
                });
              if (status_name === "Activated")
                dispatch({
                  type: AdminsActors.ACTIVE_ADMINS_LIST,
                  payload: { data: res.data, isLoaded: true },
                });
              if (status_name === "Deactivated")
                dispatch({
                  type: AdminsActors.DEACTIVATE_ADMINS_LIST,
                  payload: { data: res.data, isLoaded: true },
                });
              if (status_name === "Deleted")
                dispatch({
                  type: AdminsActors.DELETED_ADMINS_LIST,
                  payload: { data: res.data, isLoaded: true },
                });
            }
            setUserData(res?.data);
            setListData(
              res.data?.body?.map((data, index) => ({
                no: Number(offset) + index + 1,
                ...data,
              }))
            );
          })
          .then((res) => {
            setPageLoading(false);
            setCompLoading(false);
          });
      } catch (error) {
        setGetErrorMessage(error.response?.data?.message);
        setOpenGetError(true);
      }
    };
    if (
      limit !== "" ||
      offset !== 0 ||
      (status_name === "Activated" && !ActiveList.isLoaded) ||
      (status_name === "Deactivated" && !DeactiveList.isLoaded) ||
      (status_name === "Deleted" && !DeletedList.isLoaded) ||
      (status_name === "All" && !AdminsList.isLoaded)
    )
      apiCall();
    else {
      if (status_name === "All") {
        setUserData(AdminsList.data);
        setListData(
          AdminsList.data?.body?.map((data, index) => ({
            no: Number(offset) + index + 1,
            ...data,
          }))
        );
      }
      if (status_name === "Activated") {
        setUserData(ActiveList.data);
        setListData(
          ActiveList.data?.body?.map((data, index) => ({
            no: Number(offset) + index + 1,
            ...data,
          }))
        );
      }
      if (status_name === "Deactivated") {
        setUserData(DeactiveList.data);
        setListData(
          DeactiveList.data?.body?.map((data, index) => ({
            no: Number(offset) + index + 1,
            ...data,
          }))
        );
      }
      if (status_name === "Deleted") {
        setUserData(DeletedList.data);
        setListData(
          DeletedList.data?.body?.map((data, index) => ({
            no: Number(offset) + index + 1,
            ...data,
          }))
        );
      }
      setPageLoading(false);
      setCompLoading(false);
    }
  };
  const loadCounters = () => {
    const apiCall = async () => {
      try {
        Api.getAdminsCount().then((res) => {
          setCountData({
            active: res.data?.body.Activated,
            inactive: res.data?.body.Deactivated,
            deleted: res.data?.body.Deleted,
          });
        });
      } catch (error) {
        setGetErrorMessage(error.response?.data?.detail);
        setOpenGetError(true);
      }
    };
    apiCall();
  };
  useEffect(() => {
    loadPage({ limit: "", offset: "" });
    loadCounters();
  }, [
    status,
    AdminsList.isLoaded,
    ActiveList.isLoaded,
    DeactiveList.isLoaded,
    DeletedList.isLoaded,
  ]);

  useEffect(() => {
    if (searchField === "") loadPage({ limit: "", offset: "" });
  }, [searchField]);

  const keyPress = (event) => {
    if (event.key === "Enter") {
      loadPage({ limit: "", offset: "" });
    }
  };

  const deleteUsers = async () => {
    var data = { ids: [] };
    for (const val of selectedElements) {
      data.ids.push(val);
    }

    const res = await Api.deleteAdmins(data);

    if (res?.status === 204) {
      dispatch({ type: AdminsActors.TOGGLE_ACTIVE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DEACTIVATE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DELETED_ADMINS });
      loadPage({ limit: "", offset: "" });
      loadCounters();
      setMessage("User Deleted");
      setSeverity("success");
      setOpenMessage(true);
    }
  };

  const colorMap = {
    inactive: "#FFDB5B",
    active: "#42DA93",
    blocked: "grey",
    deleted: "#ff5b5b",
    all: "#000000",
  };

  const statNameMap = {
    active: "Active Admins",
    inactive: "Deactivated Admins",
    blocked: "Blocked Admins",
    deleted: "Deleted Admins",
    all: "Total Admins",
  };

  const tileClickHandler = (name) => {
    navigate(`/admins/${name}/?limit=&offset=`);
  };

  const deactivateUsers = async () => {
    var data = { ids: [] };

    for (const val of selectedElements) {
      data.ids.push(val);
    }
    data["status"] = "Deactivated";

    const res = await Api.changeStatusAdmins(data);

    if (res?.status === 200) {
      dispatch({ type: AdminsActors.TOGGLE_ACTIVE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DEACTIVATE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DELETED_ADMINS });
      loadPage({ limit: "", offset: "" });
      loadCounters();
      setMessage("Admin Deactivated");
      setSeverity("success");
      setOpenMessage(true);
    }
  };
  const activateUsers = async () => {
    var data = { ids: [] };

    for (const val of selectedElements) {
      data.ids.push(val);
    }
    data["status"] = "Activated";

    const res = await Api.changeStatusAdmins(data);

    if (res?.status === 200) {
      dispatch({ type: AdminsActors.TOGGLE_ACTIVE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DEACTIVATE_ADMINS });
      dispatch({ type: AdminsActors.TOGGLE_DELETED_ADMINS });
      loadPage({ limit: "", offset: "" });
      loadCounters();

      setMessage("Admin Activated");
      setSeverity("success");
      setOpenMessage(true);
    }
  };
  // const blockUsers = async () => {
  //     var data = new FormData();
  //     for (const val of selectedElements) {
  //         data.append('id', val);
  //     }
  //     data.append('status', 'Blocked');

  //     const res = await Api.changeStatusAdmins(data);

  //     if (res?.status === 200) {
  //         loadPage({ limit: '', offset: '' });
  //         loadCounters();

  //         setMessage('Admin Blocked');
  //         setSeverity('success');
  //         setOpenMessage(true);
  //     }
  // };

  const download = ({ type, all }) => {
    // send({
    //     ids: all ? 'all' : selectedElements[0]?selectedElements:'all',
    //     module: 'download_admins',
    //     download_type: type,
    // });
    setSelectedElements([]);
  };

  const paginationData = (data) => {
    var parsed = queryString.parse(data);
    const offset = parsed.offset ? parsed.offset : 0;
    loadPage({ limit: parsed.limit, offset: parsed.offset });
    navigate(`/admins/${status}/?limit=${parsed.limit}&offset=${offset}`);
  };

  return loading || pageLoading ? (
    <Spinner />
  ) : (
    <div className="adminsPage w-full">
      <HeaderWithSearch
        name="Admins"
        handleChange={handleChange}
        keyPress={keyPress}
      />
      <div className="flex items-center mt-24 mb-20">
        <Button popupBtn adminsPage onClick={() => {
          if(permissionIds.userPermissions[permission]?.is_enabled===true)
          setOpen(true)}}>
          <AddIcon /> Create New Admin
        </Button>
      </div>
      <StatTile
        statData={countData && countData}
        statNameMap={statNameMap}
        colorMap={colorMap}
        onClick={tileClickHandler}
      />
      {selectedElements.length > 0 && (
        <div className="mid flex justify-between items-center my-12">
          <div className="btns flex justify-between">
            <div className="downloadBtnDropdown">
              <Button popupBtn blackOutline>
                Download All
              </Button>
              <div className="dropdownContent b-6">
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "pdf", all: true })}
                >
                  <PDFIcon />
                  Download as PDF
                </button>
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "csv", all: true })}
                >
                  <CSVIcon />
                  Download as CSV
                </button>
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "xlsx", all: true })}
                >
                  <XLSIcon />
                  Download as XLSX
                </button>
              </div>
            </div>
            <div className="downloadBtnDropdown">
              <Button popupBtn blackOutline>
                {`Download ${selectedElements.length} ${
                  selectedElements.length === 1 ? "admin" : "admins"
                }`}
              </Button>
              <div className="dropdownContent b-6" style={{ width: "12.3rem" }}>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "pdf" })}
                >
                  <PDFIcon />
                  Download as PDF
                </button>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "csv" })}
                >
                  <CSVIcon />
                  Download as CSV
                </button>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "xlsx" })}
                >
                  <XLSIcon />
                  Download as XLSX
                </button>
              </div>
            </div>
            {status !== "active" && status !== "deleted" && status !== "all" && (
              <Button
                popupBtn
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission]?.is_enabled ===
                    true
                  )
                    setActivatePopup(true);
                }}
              >
                {selectedElements.length === 1
                  ? "Activate Admin"
                  : "Activate Admins"}
              </Button>
            )}
            {/* {status !== "blocked" && status !== "deleted" && status !== "all" && (
              <Button
                popupBtn
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission]?.is_enabled ===
                    true
                  )
                    setBlockPopup(true);
                }}
              >
                {selectedElements.length === 1 ? "Block Admin" : "Block Admins"}
              </Button>
            )} */}
            {status !== "inactive" && status !== "deleted" && status !== "all" && (
              <Button
                popupBtn
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission]?.is_enabled ===
                    true
                  )
                    setDeactivatePopup(true);
                }}
              >
                {selectedElements.length === 1
                  ? "Deactivate Admin"
                  : "Deactivate Admins"}
              </Button>
            )}
            {status !== "deleted" && status !== "all" && (
              <Button
                popupBtn
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission + 1]
                      ?.is_enabled === true
                  )
                    setDeletePopup(true);
                }}
              >
                {selectedElements.length === 1
                  ? "Delete Admin"
                  : "Delete Admins"}
              </Button>
            )}
          </div>
          <Pagination page={userData} onChange={paginationData} />
        </div>
      )}
      {selectedElements.length === 0 && (
        <div className="flex justify-end items-center my-12">
          <Pagination page={userData} onChange={paginationData} />
        </div>
      )}
      {compLoading ? (
        <Spinner top="50%" left="50%" />
      ) : (
        <div className="customTable">
          <div className="customTable-div">
            <Table
              headerLayout={headerLayout}
              columnLayout={columnLayout}
              headers={header}
              dataSource={listData}
              dataKey={[
                {
                  name: "SelectBox",
                  render: (record) => {
                    return (
                      <div className="py-4 self-center checkbox">
                        <input
                          type="checkbox"
                          id={record.id}
                          checked={selectedElements.includes(record.id)}
                          onChange={() => handleCheck(record.id)}
                        />
                        <label htmlFor={record.id} />
                      </div>
                    );
                  },
                },
                "no",
                {
                  name: "avatar",
                  render: (record) => {
                    return (
                      <div className="py-4 flex items-center">
                        <img
                          src={`${
                            record.avatar
                              ? record.avatar
                              : "../../defaultLogo.png"
                          }`}
                          alt="logo"
                          className="profileImg"
                        />
                      </div>
                    );
                  },
                },
                {
                  name: "email",
                  render: (record) => {
                    return (
                      <div className="py-4 self-center">
                        <Link
                          to={{
                            pathname: `/admin-detail/${record.id}`,
                          }}
                        >
                          {record.email === null
                            ? "[Deleted User]"
                            : record.email}
                        </Link>
                      </div>
                    );
                  },
                },
                "role",
                // 'status',
                {
                  name: "status",
                  render: (record) => {
                    return (
                      <>
                        {record.status === "Activated" && (
                          <button
                            className="self-center normalButton height-30 my-8 "
                            style={{
                              color: "#42da93",
                              border: "1px solid #42da93",
                            }}
                          >
                            {record.status}
                          </button>
                        )}

                        {record.status === "Deleted" && (
                          <button
                            className="self-center normalButton height-30 my-8"
                            style={{
                              color: "red",
                              border: "1px solid red",
                            }}
                          >
                            {record.status}
                          </button>
                        )}

                        {record.status === "Deactivated" && (
                          <button
                            className="self-center normalButton height-30 my-8"
                            style={{
                              color: "#FFDB5B",
                              border: "1px solid #FFDB5B",
                            }}
                          >
                            {record.status}
                          </button>
                        )}
                      </>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
      <PopupForm open={open} setOpen={setOpen} />
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <BlockedPopup
        open={openGetError}
        setOpen={setOpenGetError}
        message={getErrorMessage}
      />
      <ConfirmPopup
        open={deactivatePopup}
        setOpen={setDeactivatePopup}
        name="Deactivate"
        page="admin"
        num={selectedElements.length}
        action="deactivate"
        handleChange={() => deactivateUsers()}
      />
      <ConfirmPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        name="Delete"
        page="admin"
        num={selectedElements.length}
        action="delete"
        handleChange={() => deleteUsers()}
      />
      <ConfirmPopup
        open={activatePopup}
        setOpen={setActivatePopup}
        name="Activate"
        page="user"
        num={selectedElements.length}
        action="activate"
        handleChange={() => activateUsers()}
      />
      {/* <ConfirmPopup
                open={blockPopup}
                setOpen={setBlockPopup}
                name="Block"
                page="user"
                num={selectedElements.length}
                action="block"
                handleChange={() => blockUsers()}
            /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  AdminsList: state.admins.AdminsList,
  ActiveList: state.admins.ActiveAdminsList,
  DeactiveList: state.admins.DeactiveAdminsList,
  DeletedList: state.admins.DeletedAdminsList,
  permissionIds: state.auth,
});

export default connect(mapStateToProps)(AdminsPage);
