import React, { useEffect, useState } from 'react';

import UserLogo from '../../../assets/icons/UserLogo';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
// import Api from '../../../Api';
import './HeaderCommon.scss'
import PDFIcon from '../../../assets/icons/PDFIcon';
import CSVIcon from '../../../assets/icons/CSVIcon';
import XLSIcon from '../../../assets/icons/XLSIcon';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        border: '1px solid #434343',
        borderRadius: '100%',
        transform: 'scale(1.2)',
        color: '#434343',
        cursor: 'pointer',
    },
});

function HeaderWithSearch({
    name,
    handleChange,
    keyPress,
    currentUser,
    back,
    full_name,
}) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [downloads, setDownloads] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);

    // useEffect(() => {
    //     const getDownloads = async () => {
    //         const res = await Api.getDownloads({
    //             limit: limit,
    //             offset: offset,
    //         });
    //         setCount(res.data.count);
    //         setDownloads(res.data.result);
    //     };
    //     getDownloads();
    // }, [limit, offset]);

    return (
        <div className="nav flex items-center mb-24">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
                <div className="nav-title flex items-center">
                    {back && (
                        <div className="cursor-pointer" onClick={back}>
                            <ArrowBackIosOutlined />
                        </div>
                    )}
                    <div>
                        <h2
                            className={`weight-700 ml-10 ${
                                full_name && 'my-0'
                            } pt-4`}
                        >
                            {name}
                        </h2>
                        {full_name && (
                            <p className="ml-10 mb-0 mt-8">{full_name}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="nav-btn">
                <label htmlFor="nav-check">
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </div>
            <div className="nav-links w-full">
                <div className="flex items-center searchDiv">
                    <SearchIcon />
                    <input
                        className="w-100 text-16"
                        type="search"
                        placeholder="Search"
                        onChange={handleChange}
                        onKeyPress={keyPress}
                    />
                </div>
                <div className="cursor-pointer customizeDropdown mr-40">
                    <span className="text-16" style={{ color: '#3abd98' }}>
                        Downloads
                    </span>
                    {downloads && (
                        <div className="headerDropdownContent b-6">
                            <div className="headerDropdownContent-div flex flex-col justify-between">
                                <div>
                                    {downloads?.map((d) => {
                                        var today = new Date();
                                        var date = new Date(
                                            parseInt(
                                                d?.url?.split('Expires=')[1]
                                            ) * 1000
                                        );
                                        return (
                                            <button
                                                key={d.pk}
                                                className="flex justify-between items-center text-14 w-full"
                                            >
                                                <div className="flex items-center">
                                                    {d.type === 'xlsx' && (
                                                        <XLSIcon height="24" />
                                                    )}
                                                    {d.type === 'pdf' && (
                                                        <PDFIcon height="25.5" />
                                                    )}
                                                    {d.type === 'csv' && (
                                                        <CSVIcon height="25" />
                                                    )}
                                                    <span className="ml-6 pb-2">
                                                        {d.title.slice(0, 18)}
                                                        {d.title.length > 18 &&
                                                            '...'}
                                                    </span>
                                                </div>
                                                {date > today ? (
                                                    <a
                                                        href={`${d.url}`}
                                                        style={{
                                                            color: 'blue',
                                                        }}
                                                        download
                                                    >
                                                        Download
                                                    </a>
                                                ) : (
                                                    <span
                                                        style={{
                                                            color: 'grey',
                                                        }}
                                                    >
                                                        Expired
                                                    </span>
                                                )}
                                            </button>
                                        );
                                    })}
                                </div>
                                <div className="text-12 table-pagination mt-10 mb-10 flex justify-center">
                                    <div className="flex items-center h-full pagination-nav justify-end">
                                        <div
                                            className="mr-10"
                                            onClick={() =>
                                                offset > 0 &&
                                                setOffset(offset - limit)
                                            }
                                        >
                                            <ChevronLeftOutlinedIcon
                                                className={classes.root}
                                            />
                                        </div>
                                        <h3
                                            className="text-16 text-center"
                                            style={{ width: '3rem' }}
                                        >{`${offset / limit + 1}-${Math.ceil(
                                            count / limit
                                        )}`}</h3>
                                        <div
                                            className="ml-10"
                                            onClick={() =>
                                                offset < count - limit &&
                                                setOffset(offset + limit)
                                            }
                                        >
                                            <ChevronRightOutlinedIcon
                                                className={classes.root}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="accountLink flex items-center cursor-pointer"
                    onClick={() => navigate('/account')}
                >
                    {!currentUser?.loading && currentUser?.avatar ? (
                        <img
                            src={currentUser.avatar}
                            alt="profile"
                            className="profileImg"
                            style={{}}
                        />
                    ) : (
                        <UserLogo color="#434343" />
                    )}
                    <h3 className="weight-400 ml-10">
                        {!currentUser?.loading && currentUser?.username}
                    </h3>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    // currentUser: state.auth.currentUser,
    currentUser: "Het",
});

export default connect(mapStateToProps)(HeaderWithSearch);

// <div className="flex justify-between items-center mb-24">
//             <div className="flex items-center">
//                 {back && (
//                     <div className="cursor-pointer" onClick={back}>
//                         <ArrowBackIosOutlined />
//                     </div>
//                 )}
//                 <div>
//                     <h2
//                         className={`weight-700 ml-10 ${
//                             full_name && 'my-0'
//                         } pt-4`}
//                     >
//                         {name}
//                     </h2>
//                     {full_name && (
//                         <p className="ml-10 mb-0 mt-8">{full_name}</p>
//                     )}
//                 </div>
//             </div>
//             <div className="flex items-center searchDiv">
//                 <SearchIcon />
//                 <input
//                     className="w-100 text-16"
//                     type="search"
//                     placeholder="Search"
//                     onChange={handleChange}
//                     onKeyPress={keyPress}
//                 />
//             </div>
//             <div className="flex items-center">
//                 <div className="cursor-pointer customizeDropdown mr-40">
//                     <span className="text-16" style={{ color: '#d445ba' }}>
//                         Downloads
//                     </span>
//                     {downloads && (
//                         <div className="headerDropdownContent b-6">
//                             <div className="headerDropdownContent-div flex flex-col justify-between">
//                                 <div>
//                                     {downloads?.map((d) => {
//                                         var today = new Date();
//                                         var date = new Date(
//                                             parseInt(
//                                                 d?.url?.split('Expires=')[1]
//                                             ) * 1000
//                                         );
//                                         return (
//                                             <button
//                                                 key={d.pk}
//                                                 className="flex justify-between items-center text-14 w-full"
//                                             >
//                                                 <div className="flex items-center">
//                                                     {d.type === 'xlsx' && (
//                                                         <XLSIcon height="24" />
//                                                     )}
//                                                     {d.type === 'pdf' && (
//                                                         <PDFIcon height="25.5" />
//                                                     )}
//                                                     {d.type === 'csv' && (
//                                                         <CSVIcon height="25" />
//                                                     )}
//                                                     <span className="ml-6 pb-2">
//                                                         {d.title.slice(0, 18)}
//                                                         {d.title.length > 18 &&
//                                                             '...'}
//                                                     </span>
//                                                 </div>
//                                                 {date > today ? (
//                                                     <a
//                                                         href={`${d.url}`}
//                                                         style={{
//                                                             color: 'blue',
//                                                         }}
//                                                         download
//                                                     >
//                                                         Download
//                                                     </a>
//                                                 ) : (
//                                                     <span
//                                                         style={{
//                                                             color: 'grey',
//                                                         }}
//                                                     >
//                                                         Expired
//                                                     </span>
//                                                 )}
//                                             </button>
//                                         );
//                                     })}
//                                 </div>
//                                 <div className="text-12 table-pagination mt-10 mb-10 flex justify-center">
//                                     <div className="flex items-center h-full pagination-nav justify-end">
//                                         <div
//                                             className="mr-10"
//                                             onClick={() =>
//                                                 offset > 0 &&
//                                                 setOffset(offset - limit)
//                                             }
//                                         >
//                                             <ChevronLeftOutlinedIcon
//                                                 className={classes.root}
//                                             />
//                                         </div>
//                                         <h3
//                                             className="text-16 text-center"
//                                             style={{ width: '3rem' }}
//                                         >{`${offset / limit + 1}-${Math.ceil(
//                                             count / limit
//                                         )}`}</h3>
//                                         <div
//                                             className="ml-10"
//                                             onClick={() =>
//                                                 offset < count - limit &&
//                                                 setOffset(offset + limit)
//                                             }
//                                         >
//                                             <ChevronRightOutlinedIcon
//                                                 className={classes.root}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//                 <div
//                     className="flex items-center cursor-pointer"
//                     onClick={() => navigate('/account')}
//                 >
//                     <UserLogo color="#434343" />
//                     <h3 className="weight-400 ml-10">{username}</h3>
//                 </div>
//             </div>
//         </div>
