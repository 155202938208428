import React from 'react';

const Lightbulb = ({ width = '42', height = '42' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.4375 38.0625H27.5625"
                    stroke="#429ADA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.9123 27.4004C11.3517 26.1815 10.0876 24.6246 9.21517 22.8469C8.34273 21.0692 7.88459 19.1169 7.8752 17.1366C7.83589 10.0225 13.5712 4.1087 20.6835 3.94131C23.4396 3.87494 26.1465 4.67805 28.4204 6.23672C30.6943 7.79539 32.4198 10.0305 33.3519 12.6249C34.2841 15.2194 34.3757 18.0415 33.6137 20.6909C32.8517 23.3403 31.2748 25.6826 29.1068 27.3854C28.6292 27.7554 28.2421 28.2291 27.9745 28.7707C27.707 29.3123 27.5661 29.9077 27.5625 30.5117L27.5625 31.5001C27.5625 31.8482 27.4242 32.182 27.1781 32.4282C26.932 32.6743 26.5981 32.8126 26.25 32.8126H15.75C15.4019 32.8126 15.068 32.6743 14.8219 32.4282C14.5758 32.182 14.4375 31.8482 14.4375 31.5001L14.4375 30.5106C14.436 29.9104 14.2979 29.3185 14.0336 28.7796C13.7694 28.2407 13.3859 27.7691 12.9123 27.4004V27.4004Z"
                    stroke="#429ADA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.333 9.31641C23.9384 9.58929 25.4194 10.3539 26.5716 11.5046C27.7237 12.6554 28.4902 14.1355 28.765 15.7405"
                    stroke="#429ADA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default Lightbulb;
