import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../Common/Button/Button';
import CloseIcon from '../../../assets/icons/CloseIcon';

import {
    toggleInfoPopup,
    toggleDownloadPopup,
    updateSelectedRows,
} from '../../../redux/Actions/UserListPage';

const PopupCard = ({
    toggleInfoPopup,
    toggleDownloadPopup,
    updateSelectedRows,
    downloadPopup,
    info,
    downloading,
    deactivate,
    deactivating,
    deleteUsers,
    deleting,
}) => {
    const rows = [
            { id: 'avatar', label: 'Avatar'},
            { id: 'full_name', label: 'Name' },
            { id: 'username', label: 'Username' },
            { id: 'email', label: 'Email ID' },
            { id: 'gender', label: 'Gender' },
            { id: 'date_of_birth', label: 'DOB' },
            { id: 'location', label: 'Location' },
            { id: 'profession', label: 'Profession' },
            { id: 'last_active', label: 'Last Active On' },
            { id: 'thought_count', label: 'Total Thoughts' },
            { id: 'report_issue_count', label: 'Total Issues' },
            { id: 'report_thought_count', label: 'Total Requests' },
        ];

        const [checkedState, setCheckedState] = useState(
            new Array(rows.length).fill(false)
    );
    const [disabledState, setDisabledState] = useState(
        new Array(rows.length).fill(false)
    );
    const [totalChecked, setTotalChecked] = useState(0);

    const handleOnChange = async (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        const total = updatedCheckedState.reduce((sum, currentState, index) => {
            if (currentState === true) {
                return sum + 1;
            }
            return sum;
        }, 0);

        if (total === 5) {
            const updatedDisabledState = disabledState.map((item, index) =>
                !item & !checkedState[index] ? true : false
            );
            setDisabledState(updatedDisabledState);
        } else {
            const updatedDisabledState = disabledState.map(
                (item) => (item = false)
            );
            setDisabledState(updatedDisabledState);
        }

        setTotalChecked(total);
    };

    return (
        <div className="popup w-full" id="popup">
            <div className="popupCard b-10">
                {info && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-500 text-18">
                                Users Row Display
                            </h3>
                            <div className="flex w-50">
                                <div>
                                    <p className="mr-10 my-0">
                                        {totalChecked}/5 Selected
                                    </p>
                                    <small style={{ color: '#ff5b5b' }}>
                                        You can only select upto 5 rows
                                    </small>
                                </div>

                                <div
                                    className="popupClose cursor-pointer"
                                    onClick={() => toggleInfoPopup()}
                                >
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                        <div className="weight-400 text-16">
                            {rows.map(({ id, label }, index) => (
                                <div
                                    className="flex mb-18 items-center"
                                    key={index}
                                >
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`row-display-${index}`}
                                            name={id}
                                            value={label}
                                            checked={checkedState[index]}
                                            onChange={() =>
                                                handleOnChange(index)
                                            }
                                            disabled={disabledState[index]}
                                        />
                                        <label
                                            htmlFor={`row-display-${index}`}
                                        />
                                    </div>
                                    <label className="ml-16 text-16 weight-400">
                                        {label}
                                    </label>
                                </div>
                            ))}
                            <div className="mt-38 flex justify-between">
                                <Button
                                    popupBtn
                                    onClick={() => toggleInfoPopup()}
                                >
                                    Discard Selections
                                </Button>
                                <Button
                                    popupBtn
                                    blackOutline
                                    onClick={() => {
                                        totalChecked !== 0 &&
                                            updateSelectedRows({
                                                checkedState,
                                                rows,
                                            });
                                        updateSelectedRows();
                                    }}
                                >
                                    Save Selections
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {downloading && downloadPopup === 'downloading' && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-600 text-18">Downloading</h3>
                            <div
                                className="popupClose cursor-pointer"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between text-14 items-end weight-500">
                                <p className="w-50">
                                    Downloading PDF document of user data for 36
                                    users
                                </p>
                                <p>24/36 users</p>
                            </div>
                            <div className="progressBar">
                                <div></div>
                            </div>
                            <div
                                className="text-center mt-30"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <Button popupBtn blackOutline>
                                    {' '}
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {deactivate && downloadPopup === 'deactivate' && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-600 text-18">
                                Deactivate Userss
                            </h3>
                            <div
                                className="popupClose cursor-pointer"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            <p>
                                Are you sure you want to deactivate 36 users ?
                            </p>
                            <div className="flex justify-between mt-30">
                                <div
                                    onClick={() =>
                                        toggleDownloadPopup({ id: '' })
                                    }
                                >
                                    <Button popupBtn blackOutline>
                                        No, Go Back
                                    </Button>
                                </div>
                                <div
                                    onClick={() =>
                                        toggleDownloadPopup({
                                            id: 'deactivating',
                                        })
                                    }
                                >
                                    <Button popupBtn>Yes, Deactivate</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {deactivating && downloadPopup === 'deactivating' && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-600 text-18">
                                Deactivate Users
                            </h3>
                            <div
                                className="popupClose cursor-pointer"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between text-14 items-end weight-500">
                                <p className="w-50">Deactivating 36 users</p>
                                <p>24/36 deactivated</p>
                            </div>
                            <div className="progressBar">
                                <div></div>
                            </div>
                            <div
                                className="text-center mt-30"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <Button popupBtn blackOutline>
                                    {' '}
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {deleteUsers && downloadPopup === 'deleteUsers' && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-600 text-18">Delete Users</h3>
                            <div
                                className="popupClose cursor-pointer"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            <p>
                                Are you sure you want to deactivate 36 users?
                                This is a permanent action.
                            </p>
                            <div className="flex justify-between mt-30">
                                <div
                                    onClick={() =>
                                        toggleDownloadPopup({ id: '' })
                                    }
                                >
                                    <Button popupBtn blackOutline>
                                        No, Go Back
                                    </Button>
                                </div>
                                <div
                                    onClick={() =>
                                        toggleDownloadPopup({
                                            id: 'deleting',
                                        })
                                    }
                                >
                                    <Button popupBtn>Yes, Deactivate</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {deleting && downloadPopup === 'deleting' && (
                    <div>
                        <div className="flex justify-between">
                            <h3 className="weight-600 text-18">Delete Users</h3>
                            <div
                                className="popupClose cursor-pointer"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between text-14 items-end weight-500">
                                <p className="w-50">Deactivating 36 users</p>
                                <p>24/36 deactivated</p>
                            </div>
                            <div className="progressBar">
                                <div></div>
                            </div>
                            <div
                                className="text-center mt-30"
                                onClick={() => toggleDownloadPopup({ id: '' })}
                            >
                                <Button popupBtn blackOutline>
                                    {' '}
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    downloadPopup: state.userList.downloadPopup,
});

export default connect(mapStateToProps, {
    toggleInfoPopup,
    toggleDownloadPopup,
    updateSelectedRows,
})(PopupCard);
