import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({
    component: Component,
    auth: { isAuthenticated, loading },
    children,
    ...rest
}) => {
    // console.log("PRivate ",userPermissions)
    return(
        isAuthenticated && !loading ? 
        children
        :
        <Navigate to="/" />
    )
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
