import { AnalyticsActors } from "../Actors/Analytics";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    AnalyticsList:{res:{},res2:{},res3:{},isLoaded:false},
};

const AnalyticsListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AnalyticsActors.ANALYTICS_LIST:
            return {
                ...state,
                AnalyticsList:action.payload
            }
        case AnalyticsActors.TOGGLE_ANALYTICS:
            console.log("Reached")
            return {
                ...state,
                AnalyticsList:{...state.CategoryList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default AnalyticsListReducer