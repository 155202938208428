import React, { useState } from "react";
import { connect } from "react-redux";
import "./SideBar.scss";
import TextIcon from "../Common/TextIcon";
import { routes } from "./routes";
import assets from "../../assets";
// import thoughtzLogoIcon from '../../assets/icons/ThoughtzLogoIcon.jpg'
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../redux/Actions/Auth";

function SideBar({ logout, permissions }) {
  const mql = window.matchMedia("(max-width: 1000px)").matches;
  const [collapse, setCollapse] = useState(mql);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isPhone = window.matchMedia("(max-width: 768px)").matches;

  const toggleNav = () => {
    setCollapse(!collapse);
  };

  const activeElem = (elem) => {
    setActive(elem);
    // alert(elem);
  };

  const token = localStorage.getItem("access_token");

  if (token && permissions) {
    return (
      <>
        <div
          className={`sideBar flex flex-col justify-between relative ${
            collapse ? "collapse pl-10" : "pl-28"
          }`}
        >
          <div className="mt-20 flex justify-between items-center sidebarHeader">
            <div
              className={`${!collapse && "ml-0"} ${
                mql && "ml-0"
              } cursor-pointer`}
              onClick={() => navigate("/analytics")}
            >
              {isPhone ? (
                <span className="logo-txt">Collectnea</span>
              ) : (
                <div className="logo flex">
                  <span className="logo-txt">Collectanea</span>
                </div>
              )}
            </div>
            <div>
              <img
                className="cursor-pointer mt-6"
                src={
                  !collapse ? assets.icons.CaretLeft : assets.icons.CaretRight
                }
                onClick={toggleNav}
                alt="arrow"
              />
            </div>
          </div>
          <div
            className="flex flex-col justify-between"
            style={{ height: "60%" }}
          >
            {routes.map((route) => {
              return (
                <TextIcon
                  key={route.text}
                  icon={route.icon}
                  classNames={`${!mql && "ml-20"} ${
                    ("/" + location.pathname.split("/")[1]).includes(
                      route.value
                    ) && "activeClass"
                  } ${
                    route.id < 15 &&
                    permissions[route.id - 1]?.is_enabled === false
                      ? "disabled"
                      : null
                  }`}
                  text={route.text}
                  onClick={() => {
                    if (permissions[route.id - 1].is_enabled === true) {
                      navigate({
                        pathname: route.path,
                        state: { values: route.value },
                      });
                      activeElem(route.text);
                    }
                    // navigate(route.path);
                  }}
                />
              );
            })}
          </div>
          <TextIcon
            icon={assets.icons.Power}
            text={"Logout"}
            onClick={() => logout().then(() => navigate("/"))}
            classNames={`mb-20 ${!mql && "ml-20"}`}
          />
        </div>
      </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => ({
  permissions: state.auth.userPermissions,
});

export default connect(mapStateToProps, { logout })(SideBar);
