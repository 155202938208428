import React, { useEffect, useState } from 'react';
import { Line , CategoryScale} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

import moment from 'moment';

import Header from '../Common/Header/Header';
import InfoGroup from '../Common/InfoGroup/InfoGroup';
// import Map from './Map';
import Api from '../../Api';
import { Slider } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CallMadeIcon from '@mui/icons-material/CallMade';
import 'date-fns';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
// import BlockedPopup from '../Common/BlockedPopup/BlockedPopup';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

import './AnalyticsPage.scss';
import '../../assets/stylesheets/common.scss';
import { useNavigate } from 'react-router-dom';
import { connect ,useDispatch} from 'react-redux';
import Spinner from '../Common/Spinner/Spinner';

const AnalyticsPage = ({ loading,AnalyticsList }) => {
    Chart.register(...registerables);
    console.log(AnalyticsList)
    const mql = window.matchMedia('(max-width: 1000px)').matches;
    const dispatch = useDispatch()
    const [graphDates, setGraphDates] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [statusDates, setStatusDates] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [graphType, setGraphType] = useState('Monthly');
    const [counter, setCounter] = useState({});
    const [trending, setTrending] = useState([]);
    const today = moment(new Date()).format('YYYY-MM-DD');
    const monthly = moment(today).subtract(5, 'months').format('YYYY-MM-DD');
    const [fromDate, setFromDate] = useState(monthly);
    const [toDate, setToDate] = useState(today);
    const navigate = useNavigate();
    const [openGetError, setOpenGetError] = useState(false);
    const [getErrorMessage, setGetErrorMessage] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [dateChanged, setDateChanged] = useState(0);
    const [loadAgain,setLoadAgain] = useState(false)

    const muiTheme = createTheme({
        overrides: {
            MuiSlider: {
                thumb: {
                    color: '#d445ba !important',
                },
                track: {
                    color: '#d445ba !important',
                },
                rail: {
                    color: 'black !important',
                },
            },
        },
    });
    const marks = [
        {
            value: 0,
            label: 'Daily',
        },
        {
            value: 50,
            label: 'Monthly',
        },
        {
            value: 100,
            label: 'Yearly',
        },
    ];

    useEffect(() => {
        const loadPage = async () => {
            const daily = moment(toDate)
                .subtract(15, 'days')
                .format('YYYY-MM-DD');
            const monthly = moment(toDate)
                .subtract(5, 'months')
                .format('YYYY-MM-DD');
            const yearly = moment(toDate)
                .subtract(3, 'years')
                .format('YYYY-MM-DD');

            if (graphType === 'Daily') {
                setFromDate(daily);
            } else if (graphType === 'Monthly') {
                setFromDate(monthly);
            } else if (graphType === 'Yearly') {
                setFromDate(yearly);
            }

            try {
                const res = await Api.getUserGraph({
                    type: graphType,
                    start_date:
                        (graphType === 'Daily' && daily) ||
                        (graphType === 'Monthly' && monthly) ||
                        (graphType === 'Yearly' && yearly),
                    end_date: toDate,
                });
                const res2 = await Api.getQuestionGraph({
                    type: graphType,
                    start_date:
                        (graphType === 'Daily' && daily) ||
                        (graphType === 'Monthly' && monthly) ||
                        (graphType === 'Yearly' && yearly),
                    end_date: toDate,
                });
                
                setGraphDates(
                    res.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][0])
                );
                setGraphData(
                    res.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][1])
                );
                setStatusDates(
                    res2.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][0])
                );
                setStatusData(
                    res2.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][1])
                        );
                        
                        const res3 = await Api.getDashboardCounters();
                        // const res4 = await Api.getTrendingHashtags({ limit: 9 });
                        
                        setCounter(res3.data.body);
                        if(!AnalyticsList.isLoaded&& graphType==='Monthly'&& toDate===today)
                        dispatch({type:'ANALYTICS_LIST',payload:{res:res,res2:res2,isLoaded:true,res3:res3}})
                // setTrending(res4.data.body);
                setPageLoading(false);
            } catch (error) {
                setLoadAgain(!loadAgain)
                setGetErrorMessage(error.response?.data?.detail);
                setOpenGetError(true);
            }
        };
        if((graphType==='Monthly'&&!AnalyticsList.isLoaded)||graphType!=='Monthly'||toDate!==today||fromDate!==monthly)
        loadPage();
        else
        {
            setGraphDates(
                AnalyticsList.res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setGraphData(
                AnalyticsList.res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
            );
            setStatusDates(
                AnalyticsList.res2.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setStatusData(
                AnalyticsList.res2.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
                    );
                    setCounter(AnalyticsList.res3.data.body);
                    setPageLoading(false);
        }
    }, [loadAgain]);

    useEffect(() => {
        const loadPage = async () => {
            const daily = moment(toDate)
                .subtract(15, 'days')
                .format('YYYY-MM-DD');
            const monthly = moment(toDate)
                .subtract(5, 'months')
                .format('YYYY-MM-DD');
            const yearly = moment(toDate)
                .subtract(3, 'years')
                .format('YYYY-MM-DD');

            if (graphType === 'Daily') {
                setFromDate(daily);
            } else if (graphType === 'Monthly') {
                setFromDate(monthly);
            } else if (graphType === 'Yearly') {
                setFromDate(yearly);
            }

            try {
                const res = await Api.getUserGraph({
                    type: graphType,
                    start_date:
                        (graphType === 'Daily' && daily) ||
                        (graphType === 'Monthly' && monthly) ||
                        (graphType === 'Yearly' && yearly),
                    end_date: toDate,
                });
                const res2 = await Api.getQuestionGraph({
                    type: graphType,
                    start_date:
                        (graphType === 'Daily' && daily) ||
                        (graphType === 'Monthly' && monthly) ||
                        (graphType === 'Yearly' && yearly),
                    end_date: toDate,
                });

                setGraphDates(
                    res.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][0])
                );
                setGraphData(
                    res.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][1])
                );
                setStatusDates(
                    res2.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][0])
                );
                setStatusData(
                    res2.data?.body
                        .map((obj) => Object.entries(obj))
                        .map((arr) => arr[0][1])
                );
            } catch (error) {
                setGetErrorMessage(error.response?.data?.detail);
                setOpenGetError(true);
            }
        };
        loadPage();
    }, [graphType]);

    useEffect(() => {
        const apiCall = async () => {
            const res = await Api.getUserGraph({
                type: graphType,
                start_date: moment(fromDate).format('YYYY-MM-DD'),
                end_date: moment(toDate).format('YYYY-MM-DD'),
            });
            const res2 = await Api.getQuestionGraph({
                type: graphType,
                start_date: moment(fromDate).format('YYYY-MM-DD'),
                end_date: moment(toDate).format('YYYY-MM-DD'),
            });

            setGraphDates(
                res && res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setGraphData(
                res && res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
            );
            setStatusDates(
                res && res2.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setStatusData(
                res && res2.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
            );
        };
        try{
            apiCall();
        }catch(err){
            console.log(err)
        }
        
    }, [dateChanged]);

    const data = {
        labels:
            graphType === 'Daily'
                ? graphDates?.map((date) => moment(date).format('DD MMM'))
                : graphDates?.map((date) => moment(date).format('MMM YY')),
        datasets: [
            {
                axis: 'x',
                data: graphData,
                fill: true,
                tension: 0.4,
                backgroundColor: 'rgba(77,194,161,.15)',
                borderColor: '#3abd98',
                borderWidth: 3,
            },
        ],
    };

    const data2 = {
        labels:
            graphType === 'Daily'
                ? statusDates?.map((date) => moment(date).format('DD MMM'))
                : statusDates?.map((date) => moment(date).format('MMM YY')),
        datasets: [
            {
                axis: 'x',
                data: statusData,
                fill: true,
                tension: 0.4,
                backgroundColor: 'rgba(77,194,161,.15)',
                borderColor: '#3abd98',
                borderWidth: 3,
            },
        ],
    };

    const handleChange = (value) => {
        if (value === 0) setGraphType('Daily');
        else if (value === 50) setGraphType('Monthly');
        else if (value === 100) setGraphType('Yearly');
    };

    const startDate = (event) => {
        setFromDate(moment(event).format('YYYY-MM-DD'));
        setDateChanged(dateChanged + 1);
    };

    const endDate = (event) => {
        setToDate(moment(event).format('YYYY-MM-DD'));
        setDateChanged(dateChanged + 1);
    };

    function disabledStartDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrAfter(toDate)
        );
    }
    function disabledEndDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrBefore(fromDate)
        );
    }


    return loading || pageLoading ? (
        <Spinner />
    ) : (
        <div className="analyticsPage w-full">
            <Header name="Analytics" noBack />
            <div className="pickers mb-30 flex">
                <div className="flex">
                    <div className="flex items-center">
                        <DatePicker
                            onChange={startDate}
                            value={moment(fromDate)}
                            placeholder="From Date"
                            disabledDate={disabledStartDate}
                            allowClear={false}
                        />
                        <div className="dash mx-20" />
                        <DatePicker
                            onChange={endDate}
                            value={moment(toDate)}
                            placeholder="From Date"
                            disabledDate={disabledEndDate}
                            allowClear={false}
                        />
                    </div>
                </div>
                <div className="pickers-slider w-20 ml-80">
                    <ThemeProvider theme={muiTheme}>
                        <Slider
                            defaultValue={50}
                            step={50}
                            marks={marks}
                            onChange={(e, value) => handleChange(value)}
                        />
                    </ThemeProvider>
                </div>
            </div>
            <div className={`flex justify-between middleLayer`}>
                <div className="relative graph">
                    <p className="weight-600 mb-30">Active Users</p>
                    <div className="arrows flex">
                        <div
                            className="arrow cursor-pointer mr-10 px-4 pt-2"
                            onClick={() => {
                                setToDate(fromDate);
                                setFromDate(
                                    moment(fromDate)
                                        .subtract(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                                setDateChanged(dateChanged + 1);
                            }}
                        >
                            <NavigateBeforeIcon />
                        </div>
                        <div
                            className="arrow cursor-pointer ml-10 px-4 pt-2"
                            onClick={() => {
                                let d = new Date().toISOString()
                                d = d.substring(0,10)
                                if (toDate >= d){
                                    return
                                }
                                setFromDate(toDate);
                                setToDate(
                                    moment(toDate)
                                        .add(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                                setDateChanged(dateChanged + 1);
                            }}
                        >
                            <NavigateNextIcon />
                        </div>
                    </div>
                    {!mql && (
                        <div
                            className="zoom"
                            onClick={() => navigate('/analytics/users-graph')}
                        >
                            <CallMadeIcon />
                        </div>
                    )}
                    <Line
                        data={data}
                        options={{
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grace: '5%',
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        precision: 0,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        }}
                    />
                </div>
                <div className="countTrending flex">
                    <div className="counts">
                        <p className="weight-600">Total Count</p>
                        <div className="infoGroup1 flex justify-between">
                            <div className="mr-10" style={{ width: '12rem' }}>
                                <InfoGroup
                                    name="Users"
                                    chats
                                    count={counter.users}
                                />
                            </div>
                            <div style={{ width: '12rem' }}>
                                <InfoGroup
                                    name="Questions"
                                    lightbulb
                                    count={counter.questions}
                                />
                            </div>
                        </div>
                        {/* <div className="flex justify-start">
                            <div className="mr-10" style={{ width: '12rem' }}>
                                <InfoGroup
                                    name="Matches"
                                    users
                                    count={counter.matches}
                                />
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="trending mt-10">
                        {trending.length !== 0 && (
                            <p className="weight-600 mb-4">Trendings</p>
                        )}
                        {trending.map((hash) => (
                            <span key={hash.pk} className="b-10">
                                #{hash.name}
                            </span>
                        ))}
                    </div> */}
                </div>
            </div>
            <div className="lowerLayer flex justify-between mt-36">
                {/* <div>
                    <p className="weight-600 mb-20">User Locations</p>
                    <Map />
                </div> */}
                <div className="relative graph" style={{ width: '50%' }}>
                    <p className="weight-600 mb-30">Total Questions</p>
                    <div className="arrows flex">
                        <div
                            className="arrow cursor-pointer mr-10 px-4 pt-2"
                            onClick={() => {
                                setToDate(fromDate);
                                setFromDate(
                                    moment(fromDate)
                                        .subtract(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                            }}
                        >
                            <NavigateBeforeIcon />
                        </div>
                        <div
                            className="arrow cursor-pointer ml-10 px-4 pt-2"
                            onClick={() => {
                                // if(toDate <= new Date().getDay())
                                let d = new Date().toISOString()
                                d = d.substring(0,10)
                                if (toDate >= d){
                                    return
                                }
                                setFromDate(toDate);
                                setToDate(
                                    moment(toDate)
                                        .add(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                            }}
                        >
                            <NavigateNextIcon />
                        </div>
                        {!mql && (
                        <div
                            className="zoom"
                            onClick={() => navigate('/analytics/questions-graph')}
                        >
                            <CallMadeIcon />
                        </div>
                    )}
                    </div>
                    <Line
                        data={data2}
                        options={{
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grace: '5%',
                                    ticks: {
                                        precision: 0,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        }}
                    />
                </div>
            </div>
            {/* <BlockedPopup
                open={openGetError}
                setOpen={setOpenGetError}
                message={getErrorMessage}
            /> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    AnalyticsList:state.analytics.AnalyticsList
});

export default connect(mapStateToProps)(AnalyticsPage);
