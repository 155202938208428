import React from 'react';

import Button from '../Button/Button';
import CloseIcon from '../../../assets/icons/CloseIcon';
import { Dialog } from '@mui/material';
import '../../../assets/stylesheets/common.scss';
import './ConfirmPopup.scss';

const ConfirmPopup = ({
    open,
    setOpen,
    name,
    action,
    num,
    handleChange,
    page,
}) => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
        >
            <div className="confirmPopup px-42 py-24">
                <div className="flex justify-between items-center mb-10">
                    <h3 className="weight-500 text-18">
                        {name} {page}
                        {num > 1 && 's'}
                    </h3>
                    <div className="flex justify-between">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                {num ? (
                    <p>
                        Are you sure you want to {action} {num} {page}
                        {num > 1 && 's'} ?
                    </p>
                ) : (
                    <p>
                        Are you sure you want to {action} this {page} ?
                    </p>
                )}
                <div className="flex justify-between mt-30">
                    <div onClick={() => setOpen(false)} className="btnLeft">
                        <Button popupBtn blackOutline>
                            No, Go Back
                        </Button>
                    </div>
                    <div
                        onClick={() => {
                            handleChange();
                            setOpen(false);
                        }}
                    >
                        <Button style={{color:"#222",borderColor:"#222"}} popupBtn>Yes, {action}</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmPopup;
