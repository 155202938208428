import { SettingsActors } from "../Actors/settings";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    SettingsList:{res:{},res2:{},res3:{},isLoaded:false},
};

const SettingsListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SettingsActors.SETTINGS_LIST:
            return {
                ...state,
                SettingsList:action.payload
            }
        case SettingsActors.TOGGLE_SETTINGS:
            console.log("Reached")
            return {
                ...state,
                SettingsList:{...state.SettingsList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default SettingsListReducer