import Header from '../../Common/Header/Header';
import Spinner from '../../Common/Spinner/Spinner';
import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import {Paper,Avatar,Stack,Chip} from '@mui/material'
import Reviews from '../../Common/Reviews/Reviews'
import './QuestionDetails.scss'

import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const QuestionDetails = ({loading}) => {
    const [pageloading,setPageloading] = useState(false)
    if (loading || pageloading){
        return <Spinner />
    }
    return(<>
    <div className='mb-10'></div>
            <Header name="Question Answer and Replies" />
            <Paper elevation = {3} className='body-paper'>
            <div className="containQuestion">
                <h1>
                    How Are You Doing?
                </h1>
                <div className="containName flex justify-between">
                    <div className='flex'>
                        <Avatar>M</Avatar>
                        <span className='mt-6 ml-4 text-18 mb-20'>Moderator</span>
                    </div>
                    <span className="greyColor mt-10">6 days ago on 1st March, 2022</span>
                </div>
                    <center>
                    <div className="image">
                        <img width={600} src="https://media.istockphoto.com/photos/picking-the-right-paint-paint-sample-color-swatch-picture-id92241441?b=1&k=20&m=92241441&s=170667a&w=0&h=OudUCphkJO9Gx9AdVpYIIypg48ELx72Zd46W818fTa8="></img>
                    </div>
                    </center>
                    <div className="stats flex justify-between mt-20">
                        <span>Total Answers: 11</span>
                        <div className='flex'>
                            <Stack title="Likes" className="stat-icon" direction="row" alignItems="center" gap={0}><FavoriteIcon />10</Stack>
                            <Stack title="Upvotes" className="stat-icon" direction="row" alignItems="center" gap={0}><ArrowUpwardIcon /> 20</Stack>
                            <Stack title="Downvotes" className="stat-icon" direction="row" alignItems="center" gap={0}><ArrowDownwardIcon/> 6</Stack>
                        </div>
                    </div>
                    <div className="tags mt-10">
                        <Chip label="Education" className="chips" /> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/>                     
                        <Chip label="Education" className="chips"/>                     
                        <Chip label="Education" className="chips"/>                     
                        <Chip label="Education" className="chips"/>                     
                    </div>
                    <h2 className='mt-50 text-center'>
                        Responses & Replies
                    </h2>
                    <Reviews fullname="Het Parekh" username = "HP" commentData = "Providing an answer" comment_created_at="" />
                    <Reviews fullname="Het Parekh" username = "HP" commentData = "Providing an answer" comment_created_at="" />
                    <Reviews fullname="Het Parekh" username = "HP" commentData = "Providing an answer" comment_created_at="" />

            </div>
        </Paper>
        </>
    )
}

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
});

export default connect(mapStateToProps)(QuestionDetails);