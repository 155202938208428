import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog } from '@mui/material';
import CloseIcon from '../../../assets/icons/CloseIcon';
// import Api from '../../Api';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from "../../Common/Spinner/Spinner";
import { Box } from "@mui/material"
import CropImg from "../../Common/ImgCrop/crop"
import "./QuestionsManagement.scss"
import {makeStyles} from '@mui/styles'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    paper: {
        width : '30%!important',
        maxWidth : '30%!important',
    },
    '@media (max-width:780px)':{
      paper: {
        width : '90% !important',
        maxWidth : '90% !important',
      },
    }
  }));

const EditQuestionPopup = ({ open, setOpen }) => {
    const classes = useStyles()
    const [formData, setFormData] = useState({
        description: '',
        type: '',
        category: '',
        youtubeLink: '',
        keywords: '',
        file:''
    });
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [openMessage, setOpenMessage] = useState(false);
    const [error, setError] = useState('');
    const [nameMessage, setNameMessage] = useState('');
    const [descriptionMessage, setDescriptionMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState('');
    const [internalurlMessage, setInternalurlMessage] = useState('');
    const [externalurlMessage, setExternalurlMessage] = useState('');
    const [fileMessage, setFileMessage] = useState('');
    const [loading, setloading]= useState(false);
    const [fileCrop, setFileCrop] = useState();
    const [optionData, setoptionData] = useState([]);

const [searchphrase, setsearchphrase] = useState('');


    const { name, description, type, internal_url, file, external_url } = formData;



// useEffect(()=>{
//   getcities();
// },[])



    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value})
    }

    const handleClose = () => {
        setOpenMessage(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        data.append("file", fileCrop);
        data.delete("crop-in");

        
    };

    const clearData = () => {
      setFileCrop()
      setOpen(false)
      
        setFormData({
            description: '',
            type: '',
            category: '',
            youtubeLink: '',
            keywords: '',
            file:''
        });
    };

    
    useEffect(() => {
      setFileCrop()
    },[open])

    const setFileData =(data)=>{
      setFileCrop(data);
    }

    const types = ['normal', 'polling'];
    
    // console.log("open",open)
    return (
         <Dialog classes={{paper: classes.paper}} open={open} onClose={() => setOpen(false)}  disableEnforceFocus>
           <div className="popupForm px-42 py-24">
             <div className="flex justify-between items-center mb-10">
               <h3 className="weight-500 text-18">Edit Question</h3>
               <div
                 className="popupClose cursor-pointer"
                 onClick={() => setOpen(false)}
               >
                 <CloseIcon />
               </div>
             </div>
             <form onSubmit={handleSubmit}>
               <div className=" weight-500 text-16 flex flex-col">

               <Autocomplete
                   disablePortal
                   id="combo-box-demo"
                   name="type"
                   options={types}
                   sx={{
                     marginBottom:2,

                   }}
                   renderInput={(params) => <TextField {...params} label="Question Type" value={params} name="type" />}
                />


                 <TextField
                   id="outlined-basic"
                   label="Description"
                   variant="outlined"
                   name="description"
                   placeholder="Enter Question Description"
                   multiline
                   required
                   rows={5}
                   maxRows = {10}
                   sx={{
                     marginBottom:2
                   }}
                 />

                <Autocomplete
                   disablePortal
                   id="combo-box-demo"
                   name="type"
                   options={types}
                   sx={{
                     marginBottom:2,

                   }}
                   renderInput={(params) => <TextField {...params} label="Select Category" value={params} name="type" />}
                 />
                 

                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={["history","politics","science","geography"]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Keywords Associated"
                        placeholder="Keywords"
                        />
                    )}
                />

                 <Box sx={{
                   marginBottom:2,
                   zIndex:100
                 }}>
                   
                 </Box>

                 <TextField
                   id="outlined-basic"
                   label="Youtube Link"
                   variant="outlined"
                   name="youtubeLink"
                   placeholder="https://www.youtube.com/watch?v=ScMzIvxBSi4"
                   sx={{
                     marginBottom:2
                   }}
                   onChange={handleChange}
                   error = {formData.youtubeLink !== "" && !(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/).test(formData.youtubeLink)}
                   helperText={formData.youtubeLink !== "" && !(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/).test(formData.youtubeLink)?"Invalid Link":""}
                 />

                
                 {/* <input type="file" onChange={(e) => uploadFile(e)} />
                 {fileMessage !== '' && (<p style={{ fontSize: '14px', color: "red" }} >{ fileMessage}</p>)} */}
                  {/* {fileCrop?
                    <div style={{width:"100%",marginBottom:20}}>
                      <img src={URL.createObjectURL(fileCrop)} width="100%" height="250px" alt="Promo img" />
                    </div>
                  :null}
                 <CropImg setFileData={setFileData} aspect={16/9}/> */}
                 <Button
                    variant="contained"
                    component="label"
                    >
                    Upload File
                    <input
                    type="file"
                    hidden
                    />
                </Button>
                 {error && (<p style={{ fontSize: '14px', color: "red" }} >{error}</p>)}
               </div>
               <div className="flex justify-between mt-30 promo-popup-btns">
                 <Button
                   variant = "contained"
                   type="button"
                   style={{ marginRight: '20px',backgroundColor:"#42da93",color:"#fff" }}
                   onClick={() => clearData()}
                 >
                   Discard Details
                 </Button>
                 <Button
                   style={{ backgroundColor:"#42da93",color:"#fff" }}
                   type="submit"
                 >
                   Create Promo
                 </Button>
               </div>
            </form>

            <Snackbar
              open={openMessage}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert onClose={handleClose} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Dialog>
    );
};

export default EditQuestionPopup;
