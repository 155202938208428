import React, { useEffect, useState } from "react";

import "../../assets/stylesheets/common.scss";
import "./AccountPage.scss";

import FormInput from "../Common/FormInput/FormInput";

import Header from "../Common/Header/Header";

import Api from "../../Api";

import { Collapse, Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import Spinner from "../Common/Spinner/Spinner";
import { connect } from "react-redux";
import moment from "moment";
import Button from "../Common/Button/Button";
import { useNavigate } from "react-router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangePassword = ({ loading }) => {
  const [err, setErr] = useState("");
  const [oldPass, setOld] = useState("");
  const [newPass, setNew] = useState("");
  const [confirmPass, setConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(oldPass, newPass, confirmPass);
    if (newPass === oldPass) setErr("New Password and Old Password are same");
    else if (newPass !== confirmPass)
      setErr("New Password and Confirm Password do not match");
    else {
      setErr("");
      const form = new FormData();
      form.append("old_password", oldPass.toString());
      form.append("new_password", newPass.toString());
      form.append("confirm_password", confirmPass.toString());
      Api.updatePassword(form)
        .then((res, err) => {
          console.log(res)
            setMessage("Password Updated");
            setSeverity("success");
            setOpenMessage(true);
        })
        .catch((err) => {
          console.log(err.response)
          setMessage(err.response.data.message[0]);
            setSeverity("error");
            setOpenMessage(true);
          
        });
    }
  };

  return (
    <div>
      <div className="accountPage w-full">
        <Header />
        <div className="accountDetails relative">
          <div className="formDiv">
            <div className="loginText text-center">
              <h1 className="weight-700 text-36">Change Password</h1>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                className="accountPage-form"
              >
                <FormInput
                  type="password"
                  label="Old password"
                  name="Password"
                  letter_spacing
                  handleChange={(e) => setOld(e.target.value)}
                  value={oldPass}
                  required
                />
                <FormInput
                  type="password"
                  label="New password"
                  name="Password"
                  letter_spacing
                  handleChange={(e) => setNew(e.target.value)}
                  value={newPass}
                  required
                />
                <FormInput
                  type="password"
                  label="Confirm password"
                  name="Password"
                  letter_spacing
                  handleChange={(e) => setConfirm(e.target.value)}
                  value={confirmPass}
                  required
                />
              </div>
              <p className="errorMessage">{err}</p>
              <div
                className="signup-btn"
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button>Change Password</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});
export default connect(mapStateToProps)(ChangePassword);
