import React, { useEffect, useState } from "react";

import "../../assets/stylesheets/common.scss";
import "./AccountPage.scss";

import FormInput from "../Common/FormInput/FormInput";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Header from "../Common/Header/Header";

import Api from "../../Api";

import { Collapse, Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import Spinner from "../Common/Spinner/Spinner";
import { connect } from "react-redux";
import moment from "moment";
import Button from "../Common/Button/Button";
import { useLocation, useNavigate } from "react-router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const [err, setErr] = useState("");
  const [newPass, setNew] = useState("");
  const [confirmPass, setConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const handleClose = () => {
    setOpenMessage(false);
  };
  const location = useLocation()
  const navigate = useNavigate()
  const uid64 = location.pathname.split('/')[2]
  const token = location.pathname.split('/')[3]

  const handleSubmit = async(event) => {
    event.preventDefault();
    const form = new FormData()
    form.append("uidb64",uid64)
    form.append("token",token)
    form.append("new_password",newPass)
    form.append("confirm_password",confirmPass)
    try{
    const response=await Api.resetPassword(form)
    console.log(response)
    if(response&&response.status===200)
    {
      setMessage("Password has been Changed")
      setSeverity("success")
      setOpenMessage(true)
      navigate('/')
    } 
    else
    {
      setMessage("Please Check Your Mail")
      setSeverity("error")
      // setOpenMessage(true)
    } }
    catch(error){
      setMessage(error.response.data.message[0])
      setSeverity("error")
      setOpenMessage(true)
      console.log(error.response)
    }
  }

  return (
    <div>
      <div className="accountPage w-full">
        <div className="accountDetails relative">
          <div className="formDiv">
            <div className="loginText text-center">
              <h1 className="weight-700 text-36">Reset Password</h1>
            </div>

            <form onSubmit={(e) => {
              if(confirmPass!==newPass)
              {e.preventDefault()
                setErr("Passwords do not match")}
              else
              {
              setErr("")
              handleSubmit(e)}}}>
              <div className="accountPage-form">
              <FormInput
                  type="password"
                  label="New password"
                  name="Password"
                  letter_spacing
                  handleChange={(e) => setNew(e.target.value)}
                  value={newPass}
                  required
                />
                <FormInput
                  type="password"
                  label="Confirm password"
                  name="Password"
                  letter_spacing
                  handleChange={(e) => setConfirm(e.target.value)}
                  value={confirmPass}
                  required
                />
              </div>
              <p className="errorMessage">{err}</p>
              <div
                className="signup-btn"
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button>Change Password</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ResetPassword;
