import React from 'react';

const XLSIcon = ({ width = '28', height = '28' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.3125 4.8125H5.6875C5.20425 4.8125 4.8125 5.20425 4.8125 5.6875V22.3125C4.8125 22.7957 5.20425 23.1875 5.6875 23.1875H22.3125C22.7957 23.1875 23.1875 22.7957 23.1875 22.3125V5.6875C23.1875 5.20425 22.7957 4.8125 22.3125 4.8125Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 4.8125V23.1875"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.1875 14H4.8125"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default XLSIcon;
