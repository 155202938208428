import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../Button/Button';
import CloseIcon from '../../../assets/icons/CloseIcon';
import { Dialog } from '@mui/material';
import DragHandleIcon from '@mui/icons-material//DragHandle';
import './ListRowPopup.scss';
import '../../../assets/stylesheets/common.scss';

import { updateSelectedRows } from '../../../redux/Actions/UserListPage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PopupCard = ({ open, setOpen, updateSelectedRows, selectedRows }) => {
    const [rows, setRows] = useState([
        // { id: 'avatar', label: 'Avatar', width: '10%' },
        { id: 'full_name', label: 'Name', width: '20%' },
        { id: 'username', label: 'Username', width: '10%' },
        { id: 'email', label: 'Email ID', width: '25%' },
        { id: 'date_of_birth', label: 'DOB', width: '25%' },
        { id: 'location', label: 'Location', width: '15%' },
        { id: 'gender', label: 'Gender', width: '10%' },
        { id: 'profession', label: 'Profession', width: '10%' },
        // { id: 'created_at', label: 'Member Since', width: '20%' },
        { id: 'last_active', label: 'Last Active On', width: '20%' },
        // { id: 'groups', label: 'Total Groups', width: '10%' },
        { id: 'thought_count', label: 'Thought Count', width: '10%' },
        { id: 'report_issue_count', label: 'Issue Count', width: '10%' },
        { id: 'report_thought_count', label: 'Report Count', width: '10%' },
    ]);
    const [hovering, setHovering] = useState(-1);

    const [checkedState, setCheckedState] = useState(
        selectedRows.map((row) => row.checked)
    );
    const [disabledState, setDisabledState] = useState(
        selectedRows.map((row) => !row.checked)
    );
    const [totalChecked, setTotalChecked] = useState(0);

    const handleOnChange = async (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        const total = updatedCheckedState.reduce((sum, currentState, index) => {
            if (currentState === true) {
                return sum + 1;
            }
            return sum;
        }, 0);

        if (total === 5) {
            const updatedDisabledState = disabledState.map((item, index) =>
                !item && !checkedState[index] ? true : false
            );
            setDisabledState(updatedDisabledState);
        } else {
            const updatedDisabledState = disabledState.map(
                (item) => (item = false)
            );
            setDisabledState(updatedDisabledState);
        }

        setTotalChecked(total);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(rows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setRows(items);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <div className="popupCard px-42 py-24">
                <div className="flex justify-between items-center mb-10">
                    <h3 className="weight-500 text-18 ml-8">
                        Users Row Display
                    </h3>
                    <div className="flex justify-between w-50">
                        <div>
                            <p className="my-0">{totalChecked}/5 Selected</p>
                            <small style={{ color: '#ff5b5b' }}>
                                You can only select upto 5 rows
                            </small>
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className="weight-400 text-16">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="columns">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {rows.map(({ id, label }, index) => (
                                        <Draggable
                                            key={index}
                                            draggableId={id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    className="row flex mb-4 items-center justify-between"
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    onMouseEnter={() =>
                                                        setHovering(index)
                                                    }
                                                    onMouseLeave={() =>
                                                        setHovering(-1)
                                                    }
                                                >
                                                    <div className="flex items-center">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                id={`row-display-${index}`}
                                                                name={id}
                                                                value={label}
                                                                checked={
                                                                    checkedState[
                                                                        index
                                                                    ]
                                                                }
                                                                onChange={() =>
                                                                    handleOnChange(
                                                                        index
                                                                    )
                                                                }
                                                                disabled={
                                                                    disabledState[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={`row-display-${index}`}
                                                            />
                                                        </div>
                                                        <label className="ml-16 text-16 weight-400">
                                                            {label}
                                                        </label>
                                                    </div>
                                                    {hovering === index && (
                                                        <DragHandleIcon />
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="mt-20 flex justify-between">
                        <Button popupBtn onClick={() => setOpen(false)}>
                            Discard Selections
                        </Button>
                        <Button
                            popupBtn
                            blackOutline
                            onClick={() => {
                                totalChecked !== 0 &&
                                    updateSelectedRows({
                                        checkedState,
                                        rows,
                                    });
                                setOpen(false);
                            }}
                        >
                            Save Selections
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    downloadPopup: state.userList.downloadPopup,
    selectedRows: state.userList.selectedRows,
});

export default connect(mapStateToProps, {
    updateSelectedRows,
})(PopupCard);
