import React from "react";
import "./TextIcon.scss";
export default function TextIcon(props) {
  const { text, icon, onClick, classNames } = props;
  return (
    <div
      className={`textIcon flex items-center Manrope ${classNames} cursor-pointer`}
      onClick={() => onClick()}
    >
      {typeof icon === 'object'?
        icon
        :
        <img src={icon} alt={text} />
      }
      <span className="ml-12 sideBarText">{text}</span>
    </div>
  );
}

// import React from 'react';
// import './TextIcon.scss';
// export default function TextIcon(props) {
//     const { text, icon, onClick, classNames } = props;
//     return (
//         <div
//             className={`textIcon flex items-center mt-34 Manrope ${classNames} cursor-pointer`}
//             onClick={() => onClick()}
//         >
//             <img src={icon} className={classNames} />
//             <span className="ml-12 sideBarText">{text}</span>
//         </div>
//     );
// }
