import assets from '../../assets';

export const routes = [
    {
        icon: assets.icons.ChartLine,
        text: 'Analytics',
        path: '/analytics',
        value: '/analytics',
        id: 12,
    },
    {
        icon: assets.icons.UserCircle,
        text: 'Users',
        path: '/users/all/?limit=&offset=',
        value: '/user',
        id: 1,
    },
    {
        icon: assets.icons.BiCategoryAlt,
        text: 'Category',
        path: '/category-management/active/?limit=&offset=',
        value: '/category-management',
        id: 7,
    },
    {
        icon: assets.icons.BsQuestionOctagon,
        text: 'Questions',
        path: '/questions-management/open/?limit=&offset=',
        value: '/questions-management',
        id: 13,
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Reported Issues',
        path: '/issues/all/?limit=&offset=',
        value: '/issues',
        id: 4,
    },
    {
        icon: assets.icons.UserCircleGear,
        text: 'Admins',
        path: '/admins/all/?limit=&offset=',
        value: '/admin',
        id: 9,
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Spam Questions',
        path: '/spam-questions/?limit=&offset=',
        value: '/spam-questions',
        id: 14,
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Spam Answers',
        path: '/spam-answers/?limit=&offset=',
        value: '/spam-answers',
        id: 17,
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Spam Replies',
        path: '/spam-replies/?limit=&offset=',
        value: '/spam-replies',
        id: 20,
    },
    {
        icon: assets.icons.AiOutlineSetting,
        text: 'Settings',
        path: '/settings',
        value: '/settings',
        id: 23,
    },

];
let temp={}
routes.forEach(({value,id})=>{
   temp[value]=id
})
console.log(temp)