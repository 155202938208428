import React from 'react';

const CloseIcon = ({ width = '36', height = '32' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.156 28C25.3625 28 31.2045 22.6274 31.2045 16C31.2045 9.37258 25.3625 4 18.156 4C10.9495 4 5.10742 9.37258 5.10742 16C5.10742 22.6274 10.9495 28 18.156 28Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M22.5057 12L13.8066 20"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.5057 20L13.8066 12"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default CloseIcon;
