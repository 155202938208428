import React from 'react';

const PDFIcon = ({ width = '28', height = '28' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.8759 24.5H6.125C5.89294 24.5 5.67038 24.4078 5.50628 24.2437C5.34219 24.0796 5.25 23.8571 5.25 23.625V4.375C5.25 4.14294 5.34219 3.92038 5.50628 3.75628C5.67038 3.59219 5.89294 3.5 6.125 3.5H16.6259L22.7509 9.625V23.625C22.7509 23.7399 22.7282 23.8537 22.6843 23.9598C22.6403 24.066 22.5758 24.1625 22.4946 24.2437C22.4133 24.325 22.3169 24.3894 22.2107 24.4334C22.1045 24.4774 21.9908 24.5 21.8759 24.5Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.625 3.5V9.625H22.7509"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9375 17.0625L14 20.125L17.0625 17.0625"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 13.125V20.125"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default PDFIcon;
