import React from 'react';
import './Table.scss';

function Table(props) {
    const { headerLayout, headers, dataSource, dataKey, columnLayout } = props;

    return (
        <div>
            <div
                className="headers grid py-12 px-30"
                style={{
                    gridTemplateColumns: headerLayout,
                }}
            >
                {headers.map((header) => (
                    <span key={header} className="">
                        <strong>{header}</strong>
                    </span>
                ))}
            </div>
            <div
                className="dataRows grid px-30"
                style={{
                    gridTemplateColumns: columnLayout,
                }}
            >
                {dataSource?.map((data) => {
                    return (
                        <React.Fragment key={data.no}>
                            {dataKey.map((elementKey) => {
                                if (typeof elementKey === 'string') {
                                    return (
                                        <div
                                            key={elementKey?.name}
                                            className="py-12 self-center truncate"
                                        >
                                            {data[elementKey]}
                                        </div>
                                    );
                                } else {
                                    return elementKey.render(data);
                                }
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
            {(dataSource?.length === 0 || !dataSource) && (
                <h2 className="text-center mt-20" style={{ color: 'grey' }}>
                    No Records Available
                </h2>
            )}
        </div>
    );
}

export default Table;
