import axios from 'axios';
import {baseURL} from '../Utils/baseURL'
import {AuthActors} from '../Actors/AuthActors'
import  fetchRequests  from '../../ApiRequests';


export const login = ({email,password}) => async (dispatch) => {

    const checkStatusSuccess = (user) => {

        return {
            type: AuthActors.LOGIN_SUCCESS,
            payload:user
        }
    } 
    const checkStatusFailure = (errMsg) => {
        return {
            type: AuthActors.LOGIN_FAILURE,
            payload:errMsg
        }
    }
    try {
        const res = await fetchRequests.secured(
            '/api/admindashboard/admin/login/',
            'POST',
            {
                email: email,
                password: password
            }
        )
        const data = res.data.body
        axios.defaults.headers['Authorization'] = "Bearer " + data.access_token;
        const user = {id:data.id,email:data.email,status:data.status,role:data.role}
        localStorage.setItem("access_token",data.access_token)
        localStorage.setItem("refresh_token",data.refresh_token)
        dispatch(checkStatusSuccess(user))
        dispatch(loadUser());

    }catch(err){
        console.log(err.response)
        dispatch(checkStatusFailure("Incorrect Password"))
    }
} 

export const logout = () => async (dispatch) => {
    try{
        const response = await fetchRequests.secured(
        '/api/admindashboard/admin/logout/',
        'POST',
        {
            "refresh_token": localStorage.getItem("refresh_token")
        }
        );
        // disconnect();
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        axios.defaults.headers['Authorization'] = null;
        dispatch({type: AuthActors.LOGOUT})

    }
    catch(err) {
        console.log(err);
    }
}

export const permissionsError = (errMsg) => {
    return {
        type: AuthActors.PERMISSIONS_ERROR,
        payload:errMsg
    }
}

export const setApiError = (data) => (dispatch) => {
    dispatch({
        type: AuthActors.API_ERROR,
        payload: data,
    });

    setTimeout(
        () =>
            dispatch({
                type: AuthActors.REMOVE_ERRORS,
            }),
        5000
    );
};

export const setDownloadUrl = (data) => (dispatch) => {
    dispatch({
        type: AuthActors.DOWNLOAD_URL,
        payload: data,
    });
};

export const setNotification = (data) => (dispatch) => {
    dispatch({
        type: AuthActors.SET_NOTIFICATION,
        payload: data,
    });
};

export const loadUser = () => async (dispatch) => {
    
    if (!localStorage.getItem('access_token')) {
        return
    }
    try {
        const res1 = await fetchRequests.secured('/api/admindashboard/admin/my/');
        const res2 = await fetchRequests.secured(
            `/api/admindashboard/admin/${res1.data.body.id}/dashboard_permissions/`
        );
        dispatch({
            type: AuthActors.LOAD_USER,
            payload: res1.data.body,
        });

        dispatch({
            type: AuthActors.LOAD_USER_PERMISSIONS,
            payload: res2.data.body,
        });
    } catch (err) {
        console.log(err)
    }
};





