import React, { useEffect, useState } from "react";

import "../../assets/stylesheets/common.scss";
import "./AccountPage.scss";

import FormInput from "../Common/FormInput/FormInput";

import Header from "../Common/Header/Header";

import Api from "../../Api";

import { Collapse, Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import Spinner from "../Common/Spinner/Spinner";
import { connect } from "react-redux";
import moment from "moment";
import Button from "../Common/Button/Button";
import { useNavigate } from "react-router";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccountPage = ({ loading, user_data }) => {
  const [createdAt, setCreatedAt] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [pageLoading, setPageLaoding] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPageLaoding(true);
    Api.getMyAdminsDetails().then((res) => {
      console.log(res);
      setCreatedAt(res.data.body.createdAt);
      setEmail(res.data.body.email);
      setRole(res.data.body.role);
    });

    setPageLaoding(false);
  }, []);

  return loading || pageLoading ? (
    <Spinner></Spinner>
  ) : (
    <div className="accountPage w-full">
      <Header name="My Account" />
      <div className="accountDetails relative">
        <h2 className="header colored-text">PERSONAL DETAILS</h2>
        <span color="greyColor">
          Joined on {moment(createdAt).format("DD/MM/YYYY")}
        </span>

        <div style={{ width: "50%" }}>
          <FormInput
            accountPage
            type="email"
            name="email"
            label="Email ID"
            value={email}
            disabled
          />
          <FormInput
            accountPage
            type="text"
            name="role"
            label="Role"
            value={role}
            disabled
          />
        </div>

        <div
          className="colored-text my-32 hover"
          onClick={() => navigate("/change-password")}
        >
          Change Your Password
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user_data: state.auth.currentUser,
});
export default connect(mapStateToProps)(AccountPage);
