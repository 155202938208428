import React from 'react';
import millify from 'millify';

function UserCount(props) {
    const { count, name, color } = props;

    return (
        <>
            <div key={name}>
                <h2 style={{ color: color }}>{millify(Number(count))}</h2>
                <p>{name}</p>
            </div>
        </>
    );
}

export default UserCount;
