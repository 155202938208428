import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
    return (
        <div style={{ position: 'fixed', top: '50%', left: '50%',transform: 'translate(-50%, -50%)'    }}>
            <CircularProgress size={75} style={{ color: '#3abd98' }} />
        </div>
    );
};

export default Spinner;
