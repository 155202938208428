import React from "react";
import './Reviews.scss';
import moment from 'moment';

const Reviews = ({fullname,username,profileURL,commentData,comment_created_at}) => {
    return (
        <div className="individualReviews">

          <div className="testimonial-box-container">
              <div className="testimonial-box">
                  <div className="box-top">
                      <div className="profile">
                          <div className="profile-img">
                              <img style={{background:"#fff"}} src={profileURL?profileURL:"https://w7.pngwing.com/pngs/481/915/png-transparent-computer-icons-user-avatar-woman-avatar-computer-business-conversation.png"} />
                          </div>
                          <div className="name-user">
                              <strong>{fullname }</strong>
                              <span>@{username }</span>
                          </div>
                      </div>
                  </div>
                  <div className="client-comment">
                      <p>{commentData}</p>
                  </div>
                  <div className="mb-0 ">
                      <small className=" greyColor mb-0 py-0" style={{float:"right"}}>{moment(comment_created_at).format("DD/MM/YYYY, hh:mm a")}</small>
                  </div>
              </div>
            </div>
        </div>
    )
}
export default Reviews