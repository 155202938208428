import React from 'react';

const PasswordEye = ({ width = '24', height = '14', color = '#C4C4C4' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.04701 1.31717C2.66989 0.738916 1.89541 0.575868 1.31717 0.952987C0.738916 1.33011 0.575868 2.10459 0.952987 2.68283L3.04701 1.31717ZM23.0552 2.67019C23.4253 2.08745 23.2529 1.31498 22.6702 0.944849C22.0874 0.574713 21.315 0.747065 20.9448 1.32981L23.0552 2.67019ZM11.1848 12C11.1848 12.6904 11.7444 13.25 12.4348 13.25C13.1251 13.25 13.6848 12.6904 13.6848 12H11.1848ZM19.7219 10.2838C20.1548 10.8216 20.9417 10.9066 21.4795 10.4737C22.0172 10.0408 22.1023 9.25396 21.6694 8.71619L19.7219 10.2838ZM3.12214 8.82453C2.74908 9.40541 2.91756 10.1787 3.49844 10.5518C4.07932 10.9248 4.85264 10.7564 5.22569 10.1755L3.12214 8.82453ZM0.952987 2.68283C3.5166 6.61371 7.83691 9.44358 12.4489 9.39136L12.4206 6.89152C8.84978 6.93195 5.25544 4.70342 3.04701 1.31717L0.952987 2.68283ZM11.1848 8.14144V12H13.6848V8.14144H11.1848ZM12.4489 9.39136C14.6069 9.36692 16.7853 8.70748 18.8076 7.2806L17.3663 5.23788C15.7553 6.37455 14.0691 6.87285 12.4206 6.89152L12.4489 9.39136ZM18.8076 7.2806C20.354 6.18948 21.7883 4.66479 23.0552 2.67019L20.9448 1.32981C19.8265 3.09055 18.6084 4.36148 17.3663 5.23788L18.8076 7.2806ZM17.1132 7.04306L19.7219 10.2838L21.6694 8.71619L19.0607 5.47543L17.1132 7.04306ZM5.20342 5.58377L3.12214 8.82453L5.22569 10.1755L7.30697 6.93472L5.20342 5.58377Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};

export default PasswordEye;
