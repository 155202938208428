import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Table from "../../Common/Table/Table";
import EditQuestionPopup from "./EditQuestion";
import "./QuestionsManagement.scss";
import { useLocation, withRouter, Redirect } from "react-router-dom";
import StatTile from "../../Common/StatsTile/StatTile";
import Spinner from "../../Common/Spinner/Spinner";
import Api from "../../../Api";
import { useSearchParams, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
// import BlockedPopup from '../../Common/BlockedPopup/BlockedPopup';
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import HeaderWithSearch from "../../Common/HeaderWithSearch/HeaderWithSearch";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import Button from "../../Common/Button/Button";
import PDFIcon from "../../../assets/icons/PDFIcon";
import CSVIcon from "../../../assets/icons/CSVIcon";
import XLSIcon from "../../../assets/icons/XLSIcon";
// import { send } from '../../WebSocket/WebSocket';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import Chip from "@mui/material/Chip";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  IconButton,
  MenuItem,
  makeStyles,
} from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { BsTag } from "react-icons/bs";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DraggableDialog from "../../Common/DialogBox/DialogBox";
import { QuestionListActors } from "../../../redux/Actors/QuestionListActors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function QuestionsManagement({
  loading,
  QuestionList,
  openList,
  deletedList,
  spammedList,
  waitingList,
  pendingList,
}) {
  const navigate = useNavigate();
  const { status } = useParams();
  console.log(status);
  let [searchParams, setSearchParams] = useSearchParams();
  const categoryID = searchParams.get("categoryID") ?? "";
  const { state } = useLocation();
  const [questionStatData, setquestionStatData] = useState();
  const [categoryList, setCategoryList] = useState();
  const [questions, setQuestions] = useState();
  // const [name, setName] = useState(status);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const monthly = moment(today).subtract(5, "months").format("YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(monthly);
  const [toDate, setToDate] = useState(today);
  const [limit, setLimit] = useState("");
  const [offset, setOffset] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [deleteElement, setDeleteElement] = useState([]);
  const [open, setOpen] = useState(false);
  const [questionPagination, setQuestionPagination] = useState();
  const dispatch = useDispatch();
  const [resolvePopup, setResolvePopup] = useState(false);
  const [pendingPopup, setPendingPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [compLoading, setCompLoading] = useState(true);
  const location = useLocation();

  // var popElement = (id) => {
  //     setOpen(true);
  //     setIssueDetailId(id);
  // };

  const handleClose = () => {
    setOpenMessage(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    QuestionStatFunction();
    QuestionDataFunction( searchGroup,
      status,
      fromDate,
      toDate,
      limit,
      offset,
      categoryID);
  }, [
    QuestionList.isLoaded,
    openList.isLoaded,
    deletedList.isLoaded,
    spammedList.isLoaded,
    waitingList.isLoaded,
    pendingList.isLoaded,
  ]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    QuestionDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset,
      categoryID
    );
  }, [location, fromDate, toDate]);

  useEffect(() => {
    if (searchGroup === "") {
      const parsed = queryString.parse(location.search);
      console.log(parsed)
      QuestionDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset,
        categoryID
      );
    }
  }, [searchGroup]);

  const refreshReport = () => {
    QuestionStatFunction();
    QuestionDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
  };

  const QuestionStatFunction = () => {
    try {
      Api.getQuestionsStatTile().then((response) => {
        let temp = response?.data?.body;
        setquestionStatData({
          open: temp?.open,
          pending: temp?.pending,
          waiting: temp?.waiting,
          spammed: temp?.spammed,
          deleted: temp?.deleted,
        });
      });
      Api.getCategoryList({ status: "Active" }).then((res) => {
        let temp = [{ name: "All", id: "1" }];
        temp = temp.concat(res?.data?.body);

        setCategoryList(temp);
      });
    } catch (error) {
      setGetErrorMessage(error.response?.data?.detail);
      setOpenGetError(true);
    }
  };
  // console.log(QuestionList,pageLoading,questions)
  const QuestionDataFunction = (
    searchGroup,
    status,
    fromDate,
    toDate,
    limit,
    offset,
    categoryID
  ) => {
    setSelectedElements([]);

    searchGroup = searchGroup ?? "";
    status = status ?? "open";
    fromDate = fromDate ?? monthly;
    toDate = toDate ?? today;
    limit = limit ?? "";
    offset = offset ?? "";
    categoryID = categoryID ?? "";
    console.log(status);
    const apicall = () => {
      try {
        setCompLoading(true);
        offset = offset ? offset : 0;
        Api.getQuestions({
          searchGroup,
          status,
          fromDate,
          toDate,
          offset,
          limit,
          categoryID,
        })
          .then((response) => {
            console.log(fromDate,monthly,toDate,today,status)
            if (
              categoryID === "" &&
              fromDate === monthly &&
              toDate === today &&
              limit === "" &&
              offset === 0 &&
              searchGroup === ""
            ) {
              if (status === "open")
                dispatch({
                  type: QuestionListActors.OPEN_QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
              if (status === "pending")
                dispatch({
                  type: QuestionListActors.PENDING_QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
              if (status === "waiting")
                dispatch({
                  type: QuestionListActors.WAITING_QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
              if (status === "spammed")
                dispatch({
                  type: QuestionListActors.SPAMMED_QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
              if (status === "deleted")
                dispatch({
                  type: QuestionListActors.DELETED_QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
              if (status === "all")
                dispatch({
                  type: QuestionListActors.QUESTION_LIST,
                  payload: { data: response?.data, isLoaded: true },
                });
            }
            setQuestions(response?.data?.body);
            setQuestionPagination(response?.data);
          })
          .then((res) => {
            setPageLoading(false);
            setCompLoading(false);
          });
      } catch (error) {
        setGetErrorMessage(error.response?.data?.detail);
        setOpenGetError(true);
      }
    };
    if (
      categoryID !== "" ||
      fromDate !== monthly ||
      limit !== "" ||
      toDate !== today ||
      offset !== "" ||
      searchGroup !== "" ||
      (status === "open" && !openList.isLoaded) ||
      (status === "waiting" && !waitingList.isLoaded) ||
      (status === "pending" && !pendingList.isLoaded) ||
      (status === "spammed" && !spammedList.isLoaded) ||
      (status === "deleted" && !deletedList.isLoaded) ||
      (status === "all" && !QuestionList.isLoaded)
    ) {
      apicall();
    } else {
      console.log(status)
      if (status === "all") {
        setQuestions(QuestionList?.data.body);
        setQuestionPagination(QuestionList.data);
      }
      if (status === "open") {
        setQuestions(openList?.data.body);
        setQuestionPagination(openList.data);
      }
      if (status === "waiting") {
        setQuestions(waitingList?.data.body);
        setQuestionPagination(waitingList.data);
      }
      if (status === "spammed") {
        setQuestions(spammedList?.data.body);
        setQuestionPagination(spammedList.data);
      }
      if (status === "deleted") {
        setQuestions(deletedList?.data.body);
        setQuestionPagination(deletedList.data);
      }
      if (status === "pending") {
        setQuestions(pendingList?.data.body);
        setQuestionPagination(pendingList.data);
      }
      setPageLoading(false);
      setCompLoading(false);
    }
  };

  const paginationData = (data) => {
    var parsed = queryString.parse(data);
    const offset = parsed.offset ? parsed.offset : 0;
    QuestionDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset,
      categoryID
    );
    navigate(
      `/questions-management/${status}?limit=${parsed.limit}&offset=${offset}&categoryID=${categoryID}`
    );
  };

  const handleCheck = (key) => {
    selectedElements.includes(key)
      ? setSelectedElements(
          selectedElements.filter(function (item) {
            return item !== key;
          })
        )
      : setSelectedElements([...selectedElements, key]);
  };

  useEffect(() => {
    if (selectedElements?.length === questions?.length)
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
  }, [selectedElements]);

  const colorMap = {
    open: "#42DA93",
    waiting: "#FFDB5B",
    pending: "#FFDB5B",
    spammed: "grey",
    deleted: "#FF5B5B",
  };

  const statNameMap = {
    waiting: "Waiting Questions",
    pending: "Pending Questions",
    spammed: "Spammed Questions",
    open: "Approved Questions",
    deleted: "Deleted Questions",
  };

  const tileClickHandler = (status) => {
    navigate(`/questions-management/${status}?limit=${""}&offset=${""}`);
    QuestionDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
    setSelectedElements([]);
  };

  // const startDate = (date, dateString) => {
  //     setFromDate(dateString);
  // };

  // const endDate = (date, dateString) => {
  //     setToDate(dateString);
  // };

  const search = (event) => {
    setSearchGroup(event.target.value);
  };

  const keyPress = (event) => {
    if (event.key === "Enter") {
      const parsed = queryString.parse(location.search);
      QuestionDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset,
        categoryID
      );
    }
  };

  const download = ({ type, all }) => {
    setSelectedElements([]);
  };

  function disabledStartDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrAfter(toDate)
    );
  }
  function disabledEndDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrBefore(fromDate)
    );
  }

  const changeIssueStatus = (status_name) => {
    var bodyFormData = { ids: [] };
    for (const val of selectedElements) {
      bodyFormData.ids.push(val);
    }
    bodyFormData["status"] = status_name;
  };

  const deleteIssue = () => {
    var bodyFormData = { ids: [] };
    for (const val of selectedElements) {
      bodyFormData.ids.push(val);
    }
  };
  const startDate = (event) => {
    setFromDate(moment(event).format("YYYY-MM-DD"));
  };

  const endDate = (event) => {
    setToDate(moment(event).format("YYYY-MM-DD"));
  };

  function disabledStartDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrAfter(toDate)
    );
  }
  function disabledEndDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrBefore(fromDate)
    );
  }

  const handleStatusChange = (event) => {
    let temp = event.target.value;
    QuestionDataFunction({ status: temp, offset, limit });
    navigate(`/questions-management/${event.target.value}`);
  };

  const handleCategoryChange = (event) => {
    setPageLoading(false);
    let temp = event.target.value;
    // console.log(temp);
    if (temp === "1") temp = "";
    QuestionDataFunction({ status, offset, limit, categoryID: temp });
    if (temp === "")
      navigate(`/questions-management/${status}?limit=${""}&offset=${""}`);
    else
      navigate(
        `/questions-management/${status}?categoryID=${event.target.value}`
      );
    setPageLoading(true);
  };

  return loading || pageLoading || !questions ? (
    <Spinner />
  ) : (
    <div className="QM w-full">
      <HeaderWithSearch
        name="Question Management"
        handleChange={search}
        keyPress={keyPress}
      />
      <StatTile
        statData={questionStatData}
        statNameMap={statNameMap}
        colorMap={colorMap}
        onClick={tileClickHandler}
        total={true}
      />
      <div className="questionFilterWrapper mt-20 mb-20">
        <TextField
          label="Category"
          onChange={handleCategoryChange}
          value={categoryID}
          defaultValue={""}
          select
          variant="standard"
          className="select-status"
          SelectProps={{
            MenuProps: {
              sx: {
                height: 300,
                overFlowY: "auto",
                input: { color: "red" },
              },
            },
          }}
        >
          {categoryList?.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        <div className="ml-20 flex">
          <DatePicker
            onChange={startDate}
            value={moment(fromDate)}
            placeholder="From Date"
            disabledDate={disabledStartDate}
            allowClear={false}
          />
          <div className="dash mx-20" />
          <DatePicker
            onChange={endDate}
            value={moment(toDate)}
            placeholder="From Date"
            disabledDate={disabledEndDate}
            allowClear={false}
          />
        </div>
      </div>
      {questions && questions?.length === 0 ? (
        <h2 className="text-center mt-160" style={{ color: "grey" }}>
          No data available
        </h2>
      ) : (
        <div className="customTable-div">
          <Pagination page={questionPagination} onChange={paginationData} />
          <Grid container spacing={4}>
            {questions?.map((question, id) => (
              <>
                <Grid
                  /* className="ml-30 mt-30" */ key={id}
                  item
                  sm={12}
                  xs={12}
                  lg={6}
                  md={6}
                  className={"main-grid"}
                >
                  <div class="wrapper">
                    <div class="q-card question">
                      <div
                        className="cursor-pointer customizeDropdown"
                        style={{ float: "right" }}
                      >
                        <IconButton
                          style={{ color: "white" }}
                          className="menuIcon"
                        >
                          <MoreVertIcon
                            style={{ color: "Black", marginTop: 20 }}
                          />
                        </IconButton>
                        <div className="dropdownContent b-6">
                          {
                            <button
                              className="flex justify-between items-center text-14 w-full"
                              onClick={() => setOpen(true)}
                            >
                              Edit
                            </button>
                          }

                          <button
                            style={{ color: "red" }}
                            className="flex justify-between items-center text-14 w-full"
                            //   onClick={() => changeStatus("Inactive",question.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                      <div class="q-card__unit-name">
                        <div
                          style={{ padding: "4px 0px 0px 0px" }}
                          className="flex items-center"
                        >
                          {question.content.substring(0, 55)}
                          {question.content.length > 55 ? "..." : ""}
                          {question.content.length > 55 && (
                            <DraggableDialog
                              title="Question"
                              content={question.content}
                              pad={true}
                            />
                          )}
                        </div>
                        &nbsp;
                        <Chip
                          label="History"
                          variant="outlined"
                          className="mainChip"
                          icon={<BsTag style={{ color: "#222" }} />}
                        />{" "}
                      </div>

                      <div className="other_tags">Other Associated Tags</div>
                      <div class="q-card__level">
                        {question.keywords_associated?.length > 0 ? (
                          question.keywords_associated?.map((keyword) => (
                            <Chip label={keyword} className="chips" />
                          ))
                        ) : (
                          <Chip label="No Tags" className="chips" />
                        )}
                      </div>

                      <div className="other_tags">Posted by</div>
                      <div class="q-card__unit-description flex">
                        <Avatar>{question.author}</Avatar>
                        <span className="data"> {question.author}</span>
                      </div>

                      <div class="q-card__unit-stats q-card__unit-stats--question clearfix">
                        <div class="one-third">
                          <div class="stat">{question.like_count} </div>
                          <div class="stat-value">
                            <FavoriteIcon fontSize="medium" />
                          </div>
                        </div>

                        <div class="one-third">
                          <div class="stat">{question.upvote_count}</div>
                          <div class="stat-value ">
                            <ArrowUpwardIcon fontSize="medium" />
                          </div>
                        </div>

                        <div class="one-third">
                          <div class="stat">{question.downvote_count}</div>
                          <div class="stat-value">
                            <ArrowDownwardIcon fontSize="medium" />
                          </div>
                        </div>

                        <div class="one-third no-border">
                          <div class="stat">{question.answer_count}</div>
                          <div class="stat-value">
                            <QuestionAnswerIcon fontSize="medium" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      )}

      {/* <ConfirmPopup
        open={todeletePromo}
        setOpen={settodeletePromo}
        name="Delete"
        page="category"
        action="Delete"
        handleChange={() => handleDelete()}
      />

      <ConfirmPopup
        open={toggelpromo}
        setOpen={settoggelpromo}
        name={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        page="category"
        action={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        handleChange={() => handleToggle()}
      /> */}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      {/* <ConfirmPopup
                open={pendingPopup}
                setOpen={setPendingPopup}
                name="Set Issue Pending"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => changeIssueStatus("pending")}
            />
            <ConfirmPopup
                open={resolvePopup}
                setOpen={setResolvePopup}
                name="Resolve Issue"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => changeIssueStatus("resolved")}
            />
            <ConfirmPopup
                open={discardPopup}
                setOpen={setDiscardPopup}
                name="Discard Issue"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => deleteIssue()}
            /> */}
      <EditQuestionPopup open={open} setOpen={setOpen} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    QuestionList: state.question.QuestionList,
    openList: state.question.OpenQuestionList,
    deletedList: state.question.DeletedQuestionList,
    spammedList: state.question.SpammedQuestionList,
    waitingList: state.question.WaitingQuestionList,
    pendingList: state.question.PendingQuestionList,
  };
};

export default connect(mapStateToProps)(QuestionsManagement);
