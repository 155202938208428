import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { colors, Dialog } from '@mui/material';
import CloseIcon from '../../../assets/icons/CloseIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import Api from '../../../Api';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from "../../Common/Spinner/Spinner";
import { Box } from "@mui/material"
import CropImg from "../../Common/ImgCrop/crop"
import {ChromePicker } from 'react-color'
import {makeStyles} from '@mui/styles'
import {useParams,useNavigate} from 'react-router-dom'
import{ useDispatch} from "react-redux"
import { CategoryActors } from '../../../redux/Actors/CategoryActors';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(theme => ({
    paper: {
        width : '30%!important',
        maxWidth : '30%!important',
    },
    '@media (max-width:780px)':{
      paper: {
        width : '90% !important',
        maxWidth : '90% !important',
      },
    }
  }));

const AddCategoryPopup = ({ open, setOpen , CategoryDataFunction , status }) => {
    const classes = useStyles()
    const [formData, setFormData] = useState({
        name:'',
        description: '',
        status: '',
        color:''
    });
    const dispatch = useDispatch()
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [openMessage, setOpenMessage] = useState(false);
    const [error, setError] = useState('');
    const [fileCrop, setFileCrop] = useState();
    const [optionData, setoptionData] = useState([]);
    const [colorDrop, setColorDrop] = useState(false);

    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value})
    }

    const handleClose = () => {
        setOpenMessage(false);
    };

    const handleSubmit = async (e) => {
        const { name, description, color } = formData;

        e.preventDefault();

        const data = new FormData();

        data.append("name", name);
        data.append("details", description);
        data.append("color", color.hex);
        data.append("category_svg", fileCrop);
        console.log([...data],'add category')

        Api.createCategory(data).then((res) => {
          console.log(res)
          if (res?.status === 200){
            setMessage('Category Added');
            setSeverity('success');
            setOpenMessage(true);
            setOpen(false)
            CategoryDataFunction({status,offset:'',limit:''})
            dispatch({ type: "TOGGLE_CATEGORY" });
            dispatch({type:CategoryActors.TOGGLE_INACTIVE_LIST})
          }
        })
        .catch((err) => {
          if(err.response.status === 400 && err.response.data?.message[0] === 'Category already exists.'){
            setMessage('Category already exists');
            setSeverity('error');
            setOpenMessage(true);
          }
          else{
            setMessage('Some error occured');
            setSeverity('error');
            setOpenMessage(true);
          }
        })
    };

    const clearData = () => {
      clearImg()
      setColorDrop(false)
        setFormData({
          name:'',
          description: '',
          color:''
        });
    };

    
    useEffect(() => {
      setFileCrop()
    },[open])

    const setFileData =(data)=>{
      setFileCrop(data);
    }

    const clearImg = () => {
      document.querySelector('input[name="crop-in"]').value='';
      setFileCrop()
    }

    const handleColorChange = (color,event) => {
      // console.log("color",color)
      setFormData({...formData,color:color})
    }

    const types = ['Active', 'Inactive'];
    
    // console.log("open",open)
    return (
         <Dialog onBackdropClick={() => setOpen(true)}  classes={{paper: classes.paper}} open={open} onClose={() => setOpen(false)}  disableEnforceFocus>
           <div className="popupForm px-42 py-24">
             <div className="flex justify-between items-center mb-10">
               <b><h3 className="weight-500 text-18">Add Category</h3></b>
               <div
                 className="popupClose cursor-pointer"
                 onClick={() => setOpen(false)}
               >
                 <CloseIcon />
               </div>
             </div>
             <form onSubmit={handleSubmit}>
               <div className=" weight-500 text-16 flex flex-col">

               <TextField
                   id="outlined-basic"
                   label="Category Name"
                   variant="outlined"
                   name="name"
                   value={formData.name}
                   onChange={handleChange}
                   placeholder="Enter Category Name"
                   required
                   sx={{
                     marginBottom:2
                   }}
                 />


                 <TextField
                   id="outlined-basic"
                   label="Category Description"
                   variant="outlined"
                   name="description"
                   placeholder="Enter Category Description"
                   onChange={handleChange}
                   value={formData.description}
                   multiline
                   required
                   rows={5}
                   maxRows = {10}
                   sx={{
                     marginBottom:2
                   }}
                 />

                 <Box sx={{
                   marginBottom:2,
                   zIndex:100
                 }}>
                   
                 </Box>

                 <label className="">Pick Category Color</label>
                 <div style={{display:colorDrop?"":"none",position:"absolute",top:114,left:95,zIndex:1,width:300}} >
                  <ChromePicker   width = "70%" color={formData.color??"#cccccc"} onChange={handleColorChange} />
                 </div>
                 <div className="flex" >
                    <div style={{height:"20px",width:"70%",background:formData.color.hex??"#cccccc",cursor:"pointer",border:"1px solid #737373",borderRadius:"5px"}} 
                      onClick={() => setColorDrop(!colorDrop)}
                    >
                    </div>

                 </div>

                 <label className="mb-10 mt-30 flex">Upload Category Image { fileCrop? <CancelIcon onClick={clearImg} fontSize="small" className="mt-4 ml-10 cursor-pointer"/>:null}</label>
                 {fileCrop?
                    <div style={{width:"100%",marginBottom:20}}>
                      <img src={URL.createObjectURL(fileCrop)} width="150px" height="150px" alt="Promo img" />
                    </div>
                  :null}
                  <CropImg setFileData={setFileData} aspect={16/9}/>

                
                
                 {/* <input type="file" onChange={(e) => uploadFile(e)} />
                 {fileMessage !== '' && (<p style={{ fontSize: '14px', color: "red" }} >{ fileMessage}</p>)} */}
                  

                 {error && (<p style={{ fontSize: '14px', color: "red" }} >{error}</p>)}
               </div>
               <div className="flex justify-between mt-30 promo-popup-btns">
                 <Button
                   variant = "contained"
                   type="button"
                   style={{ marginRight: '20px',backgroundColor:"#42da93",color:"#fff" }}
                   onClick={() => clearData()}
                 >
                   Discard Details
                 </Button>
                 <Button
                   style={{ backgroundColor:"#42da93",color:"#fff" }}
                   type="submit"
                 >
                   Create Category
                 </Button>
               </div>
            </form>

            <Snackbar
              open={openMessage}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert onClose={handleClose} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </Dialog>
    );
};

export default AddCategoryPopup;
