import React, { useEffect, useState } from 'react';
import moment from 'moment';
import millify from 'millify';

import './UserDetails.scss';
import '../../../assets/stylesheets/common.scss';
import Button from '../../Common/Button/Button';
import Header from '../../Common/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../../Api';
import { updateSelectedUser } from '../../../redux/Actions/UserListPage';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import BlockedPopup from '../../Common/BlockedPopup/BlockedPopup';
import Spinner from '../../Common/Spinner/Spinner';
import ConfirmPopup from '../../Common/ConfirmPopup/ConfirmPopup';
import {useDispatch} from "react-redux"

import { UserListPageActors } from "../../../redux/Actors/UserListPageActors";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const ProfilePage = ({ updateSelectedUser,permissionIds }) => {
    const [userDetails, setUserDetails] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const permission = permissionIds.permissionIds['user']
    console.log(permission)
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [openMessage, setOpenMessage] = useState(false);
    const [openGetError, setOpenGetError] = useState(false);
    const [getErrorMessage, setGetErrorMessage] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    // const [deactivatePopup, setDeactivatePopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [activatePopup, setActivatePopup] = useState(false);
    const [blockPopup, setBlockPopup] = useState(false);

    const headerLayoutStatus = '1fr 1.5fr 5fr 2fr 2fr 1fr';
    const columnLayoutStatus = '1fr 1.5fr 5fr 2fr 2fr 1fr';
    const headerStatus = [
        '#',
        'Background',
        'Content',
        'Group',
        'Posted On',
        'Status',
    ];

    const headerLayoutGroup = '1fr 1.5fr 4fr 2fr 2fr 2fr';
    const columnLayoutGroup = '1fr 1.5fr 4fr 2fr 2fr 2fr';
    const headerGroup = [
        '#',
        'Avatar',
        'Group Name',
        'Role',
        'Number Of Members',
        'Last Activity',
    ];
    const headerLayoutIssue = '1fr 4fr 3fr 2fr 4fr';
    const columnLayoutIssue = '1fr 4fr 3fr 2fr 4fr';
    const headerIssue = ['#', 'Issue', 'Raised On', 'Status', 'Comments'];

    const headerLayoutReport = '1fr 1.5fr 4fr 3fr 2fr';
    const columnLayoutReport = '1fr 1.5fr 4fr 3fr 2fr';
    const headerReport = [
        '#',
        'Avatar',
        'User Reported',
        'Reported On',
        'Status',
    ];

    const loadPage = async () => {
        try {
            const res = await Api.getUserDetails(id);

            setUserDetails(res.data.body);
            // setUserData(res.data.body);
            updateSelectedUser({ user: res.data.body });

            // res.data.body.status &&
            //     setStatusData(
            //         res.data.body.status.map((data, index) => ({
            //             no: index + 1,
            //             ...data,
            //         }))
            //     );
            // res.data.body.group &&
            //     setGroupData(
            //         res.data.body.group.map((data, index) => ({
            //             no: index + 1,
            //             ...data,
            //         }))
            //     );
            // res.data.body.issues &&
            //     setIssueData(
            //         res.data.body.issues.map((data, index) => ({
            //             no: index + 1,
            //             ...data,
            //         }))
            //     );
            // res.data.body.reports &&
            //     setReportedData(
            //         res.data.body.reports.map((data, index) => ({
            //             no: index + 1,
            //             ...data,
            //         }))
            //     );

            setPageLoading(false);
        } catch (error) {
            setGetErrorMessage(error.response?.data?.detail);
            setOpenGetError(true);
        }
    };
    useEffect(() => {
        loadPage();
    }, [id]);

    const handleClose = () => {
        setOpenMessage(false);
    };

    const deleteUser = async () => {
        var data = {};
        data['ids'] = [userDetails.user_id];
        const res = await Api.deleteUsers(data);

        if (res?.status === 200) {
            navigate('/users/all');
            setMessage('User Deleted');
            setSeverity('success');
            setOpenMessage(true);
            dispatch({type:'TOGGLE_USER_LIST'})
             dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({type:UserListPageActors.TOGGLE_BLOCKED_USER_LIST})
      dispatch({type:UserListPageActors.TOGGLE_DELETED_USER_LIST})
        }
    };
    const deactivateUser = async () => {
        var data = {}
        data["status"] = "Deactivated"
        data["ids"] = [userDetails.user_id]

        const res = await Api.changeUserStatus(data);

        if (res?.status === 200) {
            loadPage();
            setMessage('User Deactivated');
            setSeverity('success');
            setOpenMessage(true);
            dispatch({type:'TOGGLE_USER_LIST'})
             dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({type:UserListPageActors.TOGGLE_BLOCKED_USER_LIST})
      dispatch({type:UserListPageActors.TOGGLE_DELETED_USER_LIST})
        }
    };
    const activateUser = async () => {
        var data = {}
        data["status"] = "Activated"
        data["ids"] = [userDetails.user_id]
  

        const res = await Api.changeUserStatus(data);

        if (res?.status === 200) {
            loadPage();
            setMessage('User Activated');
            setSeverity('success');
            setOpenMessage(true);
            dispatch({type:'TOGGLE_USER_LIST'})
             dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({type:UserListPageActors.TOGGLE_BLOCKED_USER_LIST})
      dispatch({type:UserListPageActors.TOGGLE_DELETED_USER_LIST})
        }
    };
    const blockUser = async () => {
        var data = {}
        data["status"] = "Blocked"
        data["ids"] = [userDetails.user_id]
  

        const res = await Api.changeUserStatus(data);

        if (res?.status === 200) {
            loadPage();
            setMessage('User Blocked');
            setSeverity('success');
            setOpenMessage(true);
            dispatch({type:'TOGGLE_USER_LIST'})
             dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({type:UserListPageActors.TOGGLE_BLOCKED_USER_LIST})
      dispatch({type:UserListPageActors.TOGGLE_DELETED_USER_LIST})
        }
    };

    const arrays = [
        'name',
        'email',
        'gender',
        'location',
        'profession',
        'created_at',
        'last_active',
        'groups',
        'thoughts',
        'issues',
        'reports',
    ];

    // useEffect(() => {
    //     const apiCall = async () => {
    //         var body = new FormData();
    //         body.append('user_id', 'True');
    //         arrays.map((name) => {
    //             body.append(name, '');
    //             return 0;
    //         });

    //         const res = await Api.getFriendUsers({
    //             id,
    //             searchField: '',
    //             limit: 10,
    //             offset: 0,
    //             body,
    //         });

    //         const res2 = await Api.getBlockedUsers({
    //             id,
    //             searchField: '',
    //             limit: 10,
    //             offset: 0,
    //             body,
    //         });

    //         setFriendsCount(res.data);
    //         setBlockedCount(res2.data);
    //     };
    //     apiCall();
    // }, [id]);

    return pageLoading ? (
        <Spinner />
    ) : (
        <div className="profilePage w-full">
            <Header name={userDetails.fullname} />
            <div className="profileTop pb-50 flex justify-between">
                <div className="flex profileTop-left">
                    <div className="mr-40">
                        <img
                            src={
                                userDetails.avatar
                                    ? userDetails.avatar
                                    : 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                            }
                            alt="profile"
                        />
                        {/* <p
                            className="cursor-pointer mt-20"
                            onClick={() =>
                                friendsCount.count &&
                                navigate(`/user-friends/${userDetails.id}`)
                            }
                        >
                            {friendsCount.count
                                ? millify(friendsCount.count)
                                : 0}{' '}
                            Friends
                        </p>
                        <p
                            className="orange cursor-pointer"
                            onClick={() =>
                                blockedCount.count &&
                                navigate(`/user-blocked/${userDetails.id}`)
                            }
                        >
                            {blockedCount.count
                                ? millify(blockedCount.count)
                                : 0}{' '}
                            Blocked
                        </p> */}
                    </div>
                    <div className="userInfo">
                        {userDetails.status === 'Activated' && (
                            <div className="tag">{userDetails.status}</div>
                        )}
                        {userDetails.status === 'Deactivated' && (
                            <div className="tag inactive">
                                {userDetails.status}
                            </div>
                        )}
                        {userDetails.status === 'Deleted' && (
                            <div className="tag deleted">
                                {userDetails.status}
                            </div>
                        )}
                        {userDetails.status === 'Blocked' && (
                            <div className="tag blocked">
                                {userDetails.status}
                            </div>
                        )}
                        <p>
                            <span>Name:</span> {userDetails.fullname}
                        </p>
                        <p>
                            <span>User Id:</span> {userDetails.user_id}
                        </p>
                        <p>
                            <span>Profession:</span>{' '}
                            {`${
                                userDetails.profession !== null
                                    ? userDetails.profession
                                    : ' '
                            }`}
                        </p>
                        <p>
                            <span>Bio:</span>{' '}
                            {`${
                                userDetails.bio !== null
                                    ? userDetails.bio
                                    : ' '
                            }`}
                        </p>
                        <p>
                            <span>Joined Since:</span>{' '}
                            {moment(userDetails.created_at).format(
                                'DD/MM/YYYY, hh:mm a'
                            )}
                        </p>
                    </div>
                </div>
                <div className="infoGroups flex flex-col justify-between">
                    {/* <div className="flex flex-col">
                        <div className="infoGroups-in flex justify-between">
                            <InfoGroup
                                name="Groups"
                                users
                                count = {3}
                                // count={userData.group_count}
                            />
                            <InfoGroup
                                name="Thoughts"
                                lightbulb
                                count = {3}
                                // count={userData.thoughts_count}
                            />
                        </div>
                        <div className="infoGroups-in flex justify-between">
                            <InfoGroup
                                name="Issues"
                                warning
                                count = {3}
                                // count={userData.issues_count}
                            />
                            <InfoGroup
                                name="Requests"
                                chats
                                count = {3}
                                // count={userData.reported_count}
                            />
                        </div>
                    </div> */}
                    <div className="btns">
                        {/* {userDetails.status !== 'Deactivated' &&
                            userDetails.status !== 'Deleted' && (
                                <Button
                                    profilePage
                                    onClick={() => setDeactivatePopup(true)}
                                >
                                    Deactivate User
                                </Button>
                            )} */}
                        {userDetails.status !== 'Activated' &&
                            userDetails.status !== 'Deleted' && (
                                <Button
                                    profilePage
                                    onClick={() => {if(permissionIds.userPermissions[permission]?.is_enabled===true)setActivatePopup(true)}}
                                    style={{color:"#00cc7a",borderColor:"#00cc7a"}}
                                >
                                    Activate User
                                </Button>
                            )}
                        {userDetails.status !== 'Blocked' &&
                            userDetails.status !== 'Deleted' && (
                                <Button
                                    profilePage
                                    onClick={() =>{if(permissionIds.userPermissions[permission]?.is_enabled===true) setBlockPopup(true)}}
                                >
                                    Block User
                                </Button>
                            )}
                        {userDetails.status !== 'Deleted' && (
                            <Button
                                profilePage
                                style={{color:"red",borderColor:"red"}}
                                onClick={() => {if(permissionIds.userPermissions[permission]?.is_enabled===true)setDeletePopup(true)}}
                            >
                                Delete User
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-50">
                {/* <div>
                    <div className="flex justify-between">
                        <p className="weight-600 ">Statuses</p>
                        <p
                            className="orange cursor-pointer"
                            onClick={() => navigate(`/user-thoughts/${id}`)}
                        >
                            {statusData.length < 5 ? '' : 'View All'}
                        </p>
                    </div>
                    <div className="customTable">
                        <div className="customTable-div">
                            <Table
                                headerLayout={headerLayoutStatus}
                                columnLayout={columnLayoutStatus}
                                headers={headerStatus}
                                dataSource={statusData}
                                dataKey={[
                                    'no',
                                    {
                                        name: 'background_image',
                                        render: (record) => {
                                            return (
                                                <div className="py-4 flex items-center">
                                                    <img
                                                        src={`${
                                                            record.background_image
                                                                ? record.background_image
                                                                : '../../defaultLogo.png'
                                                        }`}
                                                        alt="logo"
                                                        className="profileImg"
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                    'content',
                                    'group_name',
                                    {
                                        name: 'created_at',
                                        render: (record) => {
                                            return (
                                                <div className="self-center">
                                                    {moment(
                                                        record.created_at
                                                    ).format(
                                                        'DD/MM/YYYY, hh:mm a'
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: 'current_status',
                                        render: (record) => {
                                            return (
                                                <>
                                                    {record.current_status ===
                                                        'active' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8 "
                                                            style={{
                                                                color: '#42da93',
                                                                border: '1px solid #42da93',
                                                            }}
                                                        >
                                                            {
                                                                record.current_status
                                                            }
                                                        </button>
                                                    )}

                                                    {record.current_status ===
                                                        'deleted' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FF5B5B',
                                                                border: '1px solid #FF5B5B',
                                                            }}
                                                        >
                                                            {
                                                                record.current_status
                                                            }
                                                        </button>
                                                    )}

                                                    {record.current_status ===
                                                        'inactive' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FFDB5B',
                                                                border: '1px solid #FFDB5B',
                                                            }}
                                                        >
                                                            {
                                                                record.current_status
                                                            }
                                                        </button>
                                                    )}
                                                </>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-80">
                <div>
                    <div className="flex justify-between">
                        <p className="weight-600 ">Groups</p>
                        <p
                            className="orange cursor-pointer"
                            onClick={() => navigate(`/user-groups/${id}`)}
                        >
                            {groupData.length < 5 ? '' : 'View All'}
                        </p>
                    </div>
                    <div className="customTable">
                        <div className="customTable-div">
                            <Table
                                headerLayout={headerLayoutGroup}
                                columnLayout={columnLayoutGroup}
                                headers={headerGroup}
                                dataSource={groupData}
                                dataKey={[
                                    'no',
                                    {
                                        name: 'group_avatar',
                                        render: (record) => {
                                            return (
                                                <div className="py-4 flex items-center">
                                                    <img
                                                        src={`${
                                                            record.group_avatar
                                                                ? record.group_avatar
                                                                : '../../defaultLogo.png'
                                                        }`}
                                                        alt="logo"
                                                        className="profileImg"
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                    'name',
                                    'role',
                                    'no_of_members',
                                    {
                                        name: 'created_at',
                                        render: (record) => {
                                            return (
                                                <div className="self-center">
                                                    {moment(
                                                        record.created_at
                                                    ).format(
                                                        'DD/MM/YYYY, hh:mm a'
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-80">
                <div>
                    <div className="flex justify-between">
                        <p className="weight-600 ">Issues</p>
                        <p
                            className="orange cursor-pointer"
                            onClick={() => navigate(`/user-issues/${id}`)}
                        >
                            {issueData.length < 5 ? '' : 'View All'}
                        </p>
                    </div>
                    <div className="customTable">
                        <div className="customTable-div">
                            <Table
                                headerLayout={headerLayoutIssue}
                                columnLayout={columnLayoutIssue}
                                headers={headerIssue}
                                dataSource={issueData}
                                dataKey={[
                                    'no',
                                    'title',
                                    {
                                        name: 'reported_on',
                                        render: (record) => {
                                            return (
                                                <div className="self-center">
                                                    {moment(
                                                        record.reported_on
                                                    ).format(
                                                        'DD/MM/YYYY, hh:mm a'
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: 'status',
                                        render: (record) => {
                                            return (
                                                <>
                                                    {record.status ===
                                                        'resolved' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8 "
                                                            style={{
                                                                color: '#42da93',
                                                                border: '1px solid #42da93',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}

                                                    {record.status ===
                                                        'discarded' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FF5B5B',
                                                                border: '1px solid #FF5B5B',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}

                                                    {record.status ===
                                                        'pending' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FFDB5B',
                                                                border: '1px solid #FFDB5B',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}
                                                </>
                                            );
                                        },
                                    },
                                    'description',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-80">
                <div>
                    <div className="flex justify-between">
                        <p className="weight-600 ">Reported</p>
                        <p
                            className="orange cursor-pointer"
                            onClick={() => navigate(`/user-reports/${id}`)}
                        >
                            {reportedData.length < 5 ? '' : 'View All'}
                        </p>
                    </div>
                    <div className="customTable">
                        <div className="customTable-div">
                            <Table
                                headerLayout={headerLayoutReport}
                                columnLayout={columnLayoutReport}
                                headers={headerReport}
                                dataSource={reportedData}
                                dataKey={[
                                    'no',
                                    {
                                        name: 'avatar',
                                        render: (record) => {
                                            return (
                                                <div className="py-4 flex items-center">
                                                    <img
                                                        src={`${
                                                            record.avatar
                                                                ? record.avatar
                                                                : '../../defaultLogo.png'
                                                        }`}
                                                        alt="logo"
                                                        className="profileImg"
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                    'full_name',
                                    {
                                        name: 'reported_on',
                                        render: (record) => {
                                            return (
                                                <div className="self-center">
                                                    {moment(
                                                        record.reported_on
                                                    ).format(
                                                        'DD/MM/YYYY, hh:mm a'
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: 'status',
                                        render: (record) => {
                                            return (
                                                <>
                                                    {record.status ===
                                                        'resolved' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8 "
                                                            style={{
                                                                color: '#42da93',
                                                                border: '1px solid #42da93',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}

                                                    {record.status ===
                                                        'discarded' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FF5B5B',
                                                                border: '1px solid #FF5B5B',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}

                                                    {record.status ===
                                                        'pending' && (
                                                        <button
                                                            className="self-center normalButton height-30 my-8"
                                                            style={{
                                                                color: '#FFDB5B',
                                                                border: '1px solid #FFDB5B',
                                                            }}
                                                        >
                                                            {record.status}
                                                        </button>
                                                    )}
                                                </>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
            <Snackbar
                open={openMessage}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <BlockedPopup
                open={openGetError}
                setOpen={setOpenGetError}
                message={getErrorMessage}
            />
            {/* <ConfirmPopup
                open={deactivatePopup}
                setOpen={setDeactivatePopup}
                name="Deactivate"
                page="user"
                action="deactivate"
                handleChange={() => deactivateUser()}
            /> */}
            <ConfirmPopup
                open={deletePopup}
                setOpen={setDeletePopup}
                name="Delete"
                page="user"
                action="delete"
                handleChange={() => deleteUser()}
            />
            <ConfirmPopup
                open={activatePopup}
                setOpen={setActivatePopup}
                name="Activate"
                page="user"
                action="activate"
                handleChange={() => activateUser()}
            />
            <ConfirmPopup
                open={blockPopup}
                setOpen={setBlockPopup}
                name="Block"
                page="user"
                action="block"
                handleChange={() => blockUser()}
            />
        </div>
    );
};

export default connect(null, { updateSelectedUser })(ProfilePage);
