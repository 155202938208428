import React, { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

import '../../../assets/stylesheets/common.scss';
import './ListRowPopup.scss';
import { userChecked } from '../../../redux/Actions/UserListPage';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PopupCard from './PopupCard';
import UsersIcon from '../../../assets/icons/UsersIcon'
import userlogo from '../../../assets/icons/defaultLogo.png'
import MoreVertIcon from "@mui/icons-material/MoreVert";


const ListRow = ({
    num = '#',
    topBar,
    selectedRows,
    user,
    userChecked,
    selectedUsers,
    allUsers,
    limit,
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <div
            className={`flex justify-between items-center text-14 px-30 py-8 ${
                topBar && 'topBar'
            }`}
        >
            <div
                className="flex justify-between items-center"
                style={{ width: '5%' }}
            >
                {topBar ? (
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            id="header"
                            checked={selectedUsers.length === limit}
                            onChange={() =>
                                allUsers.map((u) => {
                                    if (
                                        selectedUsers.length !== limit &&
                                        !selectedUsers.includes(u.user_id)
                                    )
                                        userChecked({ id: u.user_id });
                                    if (selectedUsers.length === limit)
                                        userChecked({ id: u.user_id });
                                })
                            }
                        />
                        <label htmlFor="header" />
                    </div>
                ) : (
                    <div className="self-center checkbox">
                        <input
                            type="checkbox"
                            id={user.user_id}
                            checked={selectedUsers.includes(user.user_id)}
                            onChange={() => userChecked({ id: user.user_id })}
                        />
                        <label htmlFor={user.user_id} />
                    </div>
                )}
                <span>{num}</span>
            </div>
            <div
                className="flex justify-between items-center cursor-pointer"
                style={{ width: '90%' }}
                onClick={() =>
                    !topBar && navigate(`/user-detail/${user.user_id}`)
                }
            >
                {selectedRows.map((row,index) => {
                    return (
                        row.checked &&
                        (row.name === 'avatar' ? (
                            topBar ? (
                                <span
                                    key={row.name}
                                    style={{
                                        width: row.width,
                                    }}
                                >
                                    {row.label}
                                </span>
                            ) : (
                                <div
                                    key={row.name}
                                    style={{ width: row.width }}
                                >   
                                    {user[row.name]?
                                    <img
                                        src={`${user[row.name]}`}
                                        alt="profile"
                                        className="profileImg"
                                    />
                                    :
                                    <img
                                        src={userlogo}
                                        alt="profile"
                                        className="profileImg"
                                    />
                                    }
                                </div>
                            )
                        ) 
                        : 
                        row.name === 'Name' ?(
                            <span
                                key={row.name}
                                style={{
                                    width: row.width,
                                }}
                            >
                            </span>
                        )
                        :
                        row.name === 'status' && user[row.name]!=="Deleted"?
                            <div className="cursor-pointer customizeDropdown mt-10">
                                <MoreVertIcon />
                                <div className={`dropdownContent b-6 ${index > (user.length-2) ?"bottom-dropdown":""}`}>
                                    <button
                                        className="flex justify-between items-center text-14 w-full"
                                        onClick={() => setOpen(true)}
                                    >
                                        Edit
                                    </button>
                                        <button style={{color:"red"}}
                                        className="flex justify-between items-center text-14 w-full">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        :
                        (
                            <span
                                key={row.name}
                                style={{
                                    width: row.width,
                                }}
                            >
                                {topBar
                                    ? row.label
                                    : `${
                                          user &&
                                          (row.name === 'created_at' ||
                                              row.name === 'last_active')
                                              ? moment(user[row.name]).format(
                                                    'DD/MM/YYYY, hh:mm a'
                                                )
                                              : user[row.name]
                                                    ?user[row.name].toString().slice(0, 27):""
                                      }`}
                            </span>
                        ))
                    );
                })}
                
            </div>
            {/* <div>
                {topBar ? (
                    <div
                        className="cursor-pointer"
                        onClick={() => setOpen(true)}
                    >
                        <FilterListIcon />
                    </div>
                ) : (
                    <div className="px-12 py-12"></div>
                )}
            </div>
            <PopupCard open={open} setOpen={setOpen} /> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedRows: state.userList.selectedRows,
    selectedUsers: state.userList.selectedUsers,
});

export default connect(mapStateToProps, { userChecked })(ListRow);

// {`${
//     user[row.name] === null
//         ? '../../defaultLogo.png'
//         : user[row.name]
// }`}
