import logo from './logo.svg';
import './assets/stylesheets/common.scss';
import './assets/stylesheets/commonComponent.scss';
import './App.css';
import React,{ useEffect, useState, Fragment } from 'react';
import { Route, Routes, Navigate, Outlet  } from 'react-router-dom';
import { loadUser } from './redux/Actions/Auth';
import { connect } from 'react-redux';
import BlockedPopup from './Components/Common/BlockedPopup/BlockedPopup';
import MuiAlert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import UserListPage from './Components/ListPages/UserListPage/UserListPage';
import Issues from './Components/ListPages/Issues/Issues';
import QuestionsManagement from './Components/ListPages/QuestionsManagement/QuestionsManagement';
import CategoryManagement from './Components/ListPages/CategoryManagement/CategoryManagement';
import LoginPage from './Components/LoginPage/LoginPage';
import PrivateRoute from './Routes'
import UserDetail from './Components/DetailPages/UserDetails/UserDetails'
import AdminDetails from './Components/DetailPages/AdminDetails/AdminDetails'
import AdminsPage from './Components/ListPages/AdminListPage/AdminsPage'
import SpamQuestions from './Components/ListPages/Spam/SpamQuestions'
import QuestionDetails from './Components/DetailPages/QuestionDetails/QuestionDetails'
import AnalyticsPage from './Components/Analytics/AnalyticsPage'
import OnlyGraph from './Components/Analytics/OnlyGraph'
import QuestionsGraph from './Components/Analytics/QuestionsGraph'
import Sidebar from './Components/Sidebar/SideBar'

import ModeratorQM from './Components/ListPages/ModeratorQuestionsManagement/QuestionsManagement';

import Grid from '@mui/material/Grid'
import SpamAnswers from './Components/ListPages/Spam/SpamAnswers';
import SpamReplies from './Components/ListPages/Spam/Spam Replies';
import Settings from './Components/Settings/Settings';
import AccountPage from './Components/AccountPage/AccountPage';
import ChangePassword from './Components/AccountPage/ChangePassword';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import ResetPassword from './Components/ResetPassword/ResetPassword';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App({loadUser,permError,apiError,permissionIds}) {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [notiOpen, setNotiOpen] = useState(false);
  const [permOpen, setPermOpen] = useState(false);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    if (apiError !== '') {
        setMessage(apiError);
        setSeverity('error');
        setOpen(true);
    }
  }, [apiError]); 

  useEffect(() => {
    if (permError !== ''){
        setPermOpen(true);
    } 
  }, [permError]);

  const PageLayout = () => (
      <div className="App Manrope flex">
        <PrivateRoute>< Sidebar /></PrivateRoute>
        <div className="container">
          <Outlet /> 
        </div>
      </div>    
  );
  


  return (


      <Fragment style={{ width: '100%' }}>
        <Routes>
        <Route index  element = {<LoginPage />}/>
          <Route exact path="/forgotPassword" element={< ForgotPassword />}/>
          <Route exact path="/admin-reset-password/:uidb64/:token" element={< ResetPassword />}/>
          
          <Route path="/" element={<PageLayout />}>
              <Route exact path="/users/:status" element={<PrivateRoute>< UserListPage permissionIds={permissionIds} /></PrivateRoute>}/>
              <Route exact path="/user-detail/:id" element={<PrivateRoute>< UserDetail permissionIds={permissionIds} /></PrivateRoute>}/>
              <Route exact path="/account" element={<PrivateRoute>< AccountPage /></PrivateRoute>}/>
              <Route exact path="/change-password" element={<PrivateRoute>< ChangePassword /></PrivateRoute>}/>
              <Route exact path="/admins/:status" element={<PrivateRoute>< AdminsPage /></PrivateRoute>}/>
              <Route exact path="/admin-detail/:id" element={<PrivateRoute>< AdminDetails /></PrivateRoute>}/>
              
              <Route exact path="/issues/:status" element={<PrivateRoute>< Issues /></PrivateRoute>}/>

              <Route exact path="/questions-management/:status" element={<PrivateRoute>< QuestionsManagement /></PrivateRoute>}/>
              <Route exact path="/question-details" element={<PrivateRoute>< QuestionDetails /></PrivateRoute>}/>
              <Route exact path="/moderator-questions-management" element={<PrivateRoute>< ModeratorQM /></PrivateRoute>}/>

              <Route exact path="/category-management/:status" element={<PrivateRoute>< CategoryManagement permissionIds={permissionIds} /></PrivateRoute>}/>

              <Route exact path="/spam-questions" element={<PrivateRoute>< SpamQuestions permissionIds={permissionIds} /></PrivateRoute>}/>
              <Route exact path="/spam-answers" element={<PrivateRoute>< SpamAnswers permissionIds={permissionIds}/></PrivateRoute>}/>
              <Route exact path="/spam-replies" element={<PrivateRoute>< SpamReplies permissionIds={permissionIds}/></PrivateRoute>}/>

              <Route exact path="/analytics" element={<PrivateRoute>< AnalyticsPage /></PrivateRoute>}/>
              <Route exact path="/analytics/users-graph" element={<PrivateRoute>< OnlyGraph /></PrivateRoute>}/>
              <Route exact path="/analytics/questions-graph" element={<PrivateRoute>< QuestionsGraph /></PrivateRoute>}/>
              <Route exact path="/settings" element={<PrivateRoute>< Settings permissionIds={permissionIds} /></PrivateRoute>}/>
          </Route>
        </Routes>
        
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
          >
          <Alert onClose={() => setOpen(false)} severity={severity}>
              {message}
          </Alert>
        </Snackbar>

        <BlockedPopup
            open={permOpen}
            setOpen={setPermOpen}
            message={permError}
        />
      </Fragment>
      
      
      
  );


}

const mapStateToProps = (state) => ({
  // notification: state.auth.notification,
  permError: state.auth.permissionsError,
  // downloadUrl: state.auth.downloadUrl.
  apiError: state.auth.apiError,

  permissionIds:state.auth

});

export default connect(mapStateToProps,{ loadUser })(App);
