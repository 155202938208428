import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import Header from '../Common/Header/Header';
import Api from '../../Api';
import { Slider } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import 'date-fns';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

import './AnalyticsPage.scss';
import '../../assets/stylesheets/common.scss';

const QuestionsGraph = () => {
    const [graphDates, setGraphDates] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphType, setGraphType] = useState('Monthly');
    const today = moment(new Date()).format('YYYY-MM-DD');
    const monthly = moment(today).subtract(5, 'months').format('YYYY-MM-DD');
    const [fromDate, setFromDate] = useState(monthly);
    const [toDate, setToDate] = useState(today);
    const [dateChanged, setDateChanged] = useState(0);

    const muiTheme = createTheme({
        overrides: {
            MuiSlider: {
                thumb: {
                    color: '#d445ba',
                },
                track: {
                    color: '#d445ba',
                },
                rail: {
                    color: 'black',
                },
            },
        },
    });
    const marks = [
        {
            value: 0,
            label: 'Daily',
        },
        {
            value: 50,
            label: 'Monthly',
        },
        {
            value: 100,
            label: 'Yearly',
        },
    ];

    useEffect(() => {
        const apiCall = async () => {
            const daily = moment(toDate)
                .subtract(15, 'days')
                .format('YYYY-MM-DD');
            const monthly = moment(toDate)
                .subtract(5, 'months')
                .format('YYYY-MM-DD');
            const yearly = moment(toDate)
                .subtract(3, 'years')
                .format('YYYY-MM-DD');

            if (graphType === 'Daily') {
                setFromDate(daily);
            } else if (graphType === 'Monthly') {
                setFromDate(monthly);
            } else if (graphType === 'Yearly') {
                setFromDate(yearly);
            }

            const res = await Api.getQuestionGraph({
                type: graphType,
                start_date:
                    (graphType === 'Daily' && daily) ||
                    (graphType === 'Monthly' && monthly) ||
                    (graphType === 'Yearly' && yearly),
                end_date: toDate,
            });

            setGraphDates(
                res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setGraphData(
                res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
            );
        };
        apiCall();
    }, [graphType]);

    useEffect(() => {
        const apiCall = async () => {
            const res = await Api.getQuestionGraph({
                type: graphType,
                start_date: moment(fromDate).format('YYYY-MM-DD'),
                end_date: moment(toDate).format('YYYY-MM-DD'),
            });

            setGraphDates(
                res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][0])
            );
            setGraphData(
                res.data?.body
                    .map((obj) => Object.entries(obj))
                    .map((arr) => arr[0][1])
            );
        };

        apiCall();
    }, [dateChanged]);

    const data = {
        labels:
            graphType === 'Daily'
                ? graphDates?.map((date) => moment(date).format('DD MMM'))
                : graphDates?.map((date) => moment(date).format('MMM YY')),
        datasets: [
            {
                axis: 'x',
                data: graphData,
                fill: true,
                tension: 0.4,
                backgroundColor: '#e798d8',
                borderColor: '#d445ba',
                borderWidth: 3,
            },
        ],
    };

    const handleChange = (value) => {
        if (value === 0) setGraphType('Daily');
        else if (value === 50) setGraphType('Monthly');
        else if (value === 100) setGraphType('Yearly');
    };

    const startDate = (event) => {
        setFromDate(moment(event).format('YYYY-MM-DD'));
        setDateChanged(dateChanged + 1);
    };

    const endDate = (event) => {
        setToDate(moment(event).format('YYYY-MM-DD'));
        setDateChanged(dateChanged + 1);
    };

    function disabledStartDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrAfter(toDate)
        );
    }
    function disabledEndDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrBefore(fromDate)
        );
    }

    return (
        <div className="analyticsPage graphPage w-full">
            <Header name="Total Questions" />
            <div className="pickers mb-30 flex">
                <div className="flex">
                    <div className="flex items-center">
                        <DatePicker
                            onChange={startDate}
                            value={moment(fromDate)}
                            placeholder="From Date"
                            disabledDate={disabledStartDate}
                            allowClear={false}
                        />
                        <div className="dash mx-20" />
                        <DatePicker
                            onChange={endDate}
                            value={moment(toDate)}
                            placeholder="From Date"
                            disabledDate={disabledEndDate}
                            allowClear={false}
                        />
                    </div>
                </div>
                <div className="pickers-slider w-20 ml-80">
                    <ThemeProvider theme={muiTheme}>
                        <Slider
                            defaultValue={50}
                            step={50}
                            marks={marks}
                            onChange={(e, value) => handleChange(value)}
                        />
                    </ThemeProvider>
                </div>
            </div>
            <div className={`flex justify-center middleLayer`}>
                <div className="relative graph">
                    <div className="arrows flex">
                        <div
                            className="arrow cursor-pointer mr-10 px-4 pt-2"
                            onClick={() => {
                                setToDate(fromDate);
                                setFromDate(
                                    moment(fromDate)
                                        .subtract(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                                setDateChanged(dateChanged + 1);
                            }}
                        >
                            <NavigateBeforeIcon />
                        </div>
                        <div
                            className="arrow cursor-pointer ml-10 px-4 pt-2"
                            onClick={() => {
                                let d = new Date().toISOString()
                                d = d.substring(0,10)
                                if (toDate >= d){
                                    return
                                }
                                setFromDate(toDate);
                                setToDate(
                                    moment(toDate)
                                        .add(
                                            graphType === 'Yearly'
                                                ? 3
                                                : graphType === 'Monthly'
                                                ? 5
                                                : 15,
                                            graphType === 'Yearly'
                                                ? 'years'
                                                : graphType === 'Monthly'
                                                ? 'months'
                                                : 'days'
                                        )
                                        .format('YYYY-MM-DD')
                                );
                                setDateChanged(dateChanged + 1);
                            }}
                        >
                            <NavigateNextIcon />
                        </div>
                    </div>
                    <Line
                        width={"400px"}
                        height={"300px"}
                        data={data}
                        options={{
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grace: '5%',
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        precision: 0,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default QuestionsGraph;
