import React from 'react';
import '../../../assets/stylesheets/common.scss';
import './Button.scss';

const Button = ({
    children,
    loginPage,
    accountPage,
    changePasswordPage,
    blackOutline,
    profilePage,
    orangeFill,
    displayNone,
    popupBtn,
    adminsPage,
    ...otherProps
}) => (
    <button
        className={`btn w-full ${loginPage && 'loginBtn'} ${
            accountPage && 'accountBtn'
        } ${changePasswordPage && 'changePasswordPageBtn'}
        ${blackOutline && 'btnBlackOutline'}
        ${profilePage && 'profilePageBtn'}
        ${adminsPage && 'adminsPageBtn'}
        ${orangeFill && 'orangeFill'}
        ${displayNone && 'displayNone'}
        ${popupBtn && 'popupBtn'}`}
        {...otherProps}
    >
        {children}
    </button>
);

export default Button;
