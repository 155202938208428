import { AuthActors } from '../Actors/AuthActors';
// import {disconnect} from '../../WebSocket/WebSocket'

const INITIAL_STATE = {
    isAuthenticated: localStorage.getItem("access_token")?true:false,
    currentUser: null,
    loading: localStorage.getItem("access_token")?false:true,
    apiError: '',
    errorMessage: null,
    userPermissions: {},
    permissionsError: '',
    downloadUrl: '',
    permissionIds:{
    'admins': 9,
    'analytics': 12,
    'category-management': 7,
    'issues': 4,
    'questions-management': 13,
    'settings': 23,
    'spam-answers': 17,
    'spam-questions': 14,
    'spam-replies': 20,
    'user': 1}
    // notification: {},
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActors.LOGIN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: true,
                loading: false,
            };
        case AuthActors.LOAD_USER:
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: true,
                loading: false,
            };
        case AuthActors.LOAD_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.payload,
            };
        case AuthActors.DOWNLOAD_URL:
            return {
                ...state,
                downloadUrl: action.payload.url,
            };
        case AuthActors.SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
            };
        case AuthActors.PERMISSIONS_ERROR:
            return {
                ...state,
                permissionsError: action.payload,
            };
        case AuthActors.API_ERROR:
            return {
                ...state,
                apiError: action.payload,
            };
        case AuthActors.LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                errorMessage:action.payload
            };
        case AuthActors.LOGOUT:
            // disconnect()
            return{
                ...state,
                isAuthenticated: false,
                loading: false,
                currentUser: null,
            }
        // case AuthActors.AUTH_ERROR:
        //     localStorage.removeItem('token');
        //     return {
        //         ...state,
        //         token: null,
        //         currentUser: null,
        //         isAuthenticated: false,
        //         loading: false,
        //         apiError: '',
        //         errorMessage: action.payload?.message,
        //     };
        case AuthActors.REMOVE_ERRORS:
            return {
                ...state,
                permissionsError: '',
                apiError: '',
            };
        default:
            return state;
    }
};

export default AuthReducer;
