import React from 'react';

import Button from '../Button/Button';
import CloseIcon from '../../../assets/icons/CloseIcon';
import { Dialog } from '@mui/material';
import '../../../assets/stylesheets/common.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const BlockedPopup = ({ open, setOpen, message, permissions }) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (location.pathname.includes('analytics'))
                    navigate('/account');
                else
                    permissions[18]?.is_enabled === true
                        ? navigate('/analytics')
                        : navigate('/account');
                setOpen(false);
            }}
        >
            <div className="px-42 py-24" style={{ width: '400px' }}>
                <div className="flex justify-between items-center mb-10">
                    <h3 className="weight-500 text-18">Unauthorized Access</h3>
                    <div className="flex justify-between">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                if (location.pathname.includes('analytics'))
                                    navigate('/account');
                                else
                                    permissions[18]?.is_enabled === true
                                        ? navigate('/analytics')
                                        : navigate('/account');
                                setOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <p className="mt-20">{message}</p>
                <div className="weight-400 text-16">
                    <div className="mt-20 flex justify-center">
                        <Button
                            popupBtn
                            onClick={() => {
                                if (location.pathname.includes('analytics'))
                                    navigate('/account');
                                else
                                    permissions && permissions[18]?.is_enabled === true
                                        ? navigate('/analytics')
                                        : navigate('/account');
                                setOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    permissions: state.auth.userPermissions,
});

export default connect(mapStateToProps)(BlockedPopup);
