import { ReportIssueListActors } from "../Actors/ReportIssueActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    ReportIssueList:{data:{body:[]},count:{},isLoaded:false},
    PendingReportIssueList:{data:{body:[]},count:{},isLoaded:false},
    ResolvedReportIssueList:{data:{body:[]},count:{},isLoaded:false},
    DiscardedReportIssueList:{data:{body:[]},count:{},isLoaded:false},
};

const ReportIssueListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReportIssueListActors.REPORT_ISSUE_LIST:
            return {
                ...state,
                ReportIssueList:action.payload
            }
        case ReportIssueListActors.TOGGLE_REPORT_ISSUE:
            console.log("Reached")
            return {
                ...state,
                ReportIssueList:{...state.ReportIssueList,isLoaded:false}
            }
        case ReportIssueListActors.RESOLVED_REPORT_ISSUE_LIST:
            return {
                ...state,
                ResolvedReportIssueList:action.payload
            }
        case ReportIssueListActors.TOGGLE_RESOLVED_REPORT_ISSUE:
            return {
                ...state,
                ResolvedReportIssueList:{...state.ResolvedReportIssueList,isLoaded:false}
            }
        case ReportIssueListActors.PENDING_REPORT_ISSUE_LIST:
            return {
                ...state,
                PendingReportIssueList:action.payload
            }
        case ReportIssueListActors.TOGGLE_PENDING_REPORT_ISSUE:
            return {
                ...state,
                PendingReportIssueList:{...state.PendingReportIssueList,isLoaded:false}
            }
        case ReportIssueListActors.DISCARDED_REPORT_ISSUE_LIST:
            return {
                ...state,
                DiscardedReportIssueList:action.payload
            }
        case ReportIssueListActors.TOGGLE_DISCARDED_REPORT_ISSUE:
            return {
                ...state,
                DiscardedReportIssueList:{...state.DiscardedReportIssueList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default ReportIssueListReducer