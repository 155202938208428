import { UserListPageActors } from '../Actors/UserListPageActors';

export const toggleInfoPopup = () => (dispatch) => {
    dispatch({
        type: UserListPageActors.TOGGLE_INFO_POPUP,
    });
};

export const toggleDownloadPopup =
    ({ id }) =>
    (dispatch) => {
        dispatch({
            type: UserListPageActors.TOGGLE_DOWNLOAD_POPUP,
            payload: id,
        });
    };

export const updateSelectedRows =
    ({ checkedState, rows }) =>
    (dispatch) => {
        dispatch({
            type: UserListPageActors.SELECTED_ROWS,
            payload: { checkedState, rows },
        });
    };

export const userChecked =
    ({ id }) =>
    (dispatch) => {
        dispatch({
            type: UserListPageActors.SELECTED_USERS,
            payload: id,
        });
    };

export const updateSelectedUser =
    ({ user }) =>
    (dispatch) => {
        dispatch({
            type: UserListPageActors.SELECTED_USER,
            payload: user,
        });
    };

export const emptySelectedUsers = () => (dispatch) => {
    dispatch({
        type: UserListPageActors.EMPTY_SELECTED_USERS,
    });
};

export const storeUserList = (data) => (dispatch) =>{
    dispatch({type:UserListPageActors.USER_LIST,payload:data})
}
