import React from "react";

const Warning = ({ width = "42", height = "42" }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7441 23.625L5.25 28.875V7.875C5.25 7.5269 5.38828 7.19306 5.63442 6.94692C5.88056 6.70078 6.2144 6.5625 6.5625 6.5625H27.5625C27.9106 6.5625 28.2444 6.70078 28.4906 6.94692C28.7367 7.19306 28.875 7.5269 28.875 7.875V22.3125C28.875 22.6606 28.7367 22.9944 28.4906 23.2406C28.2444 23.4867 27.9106 23.625 27.5625 23.625H11.7441Z"
          stroke="#9A9A9A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.125 23.625V30.1875C13.125 30.5356 13.2633 30.8694 13.5094 31.1156C13.7556 31.3617 14.0894 31.5 14.4375 31.5H30.2559L36.75 36.75V15.75C36.75 15.4019 36.6117 15.0681 36.3656 14.8219C36.1194 14.5758 35.7856 14.4375 35.4375 14.4375H28.875"
          stroke="#9A9A9A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Warning;
