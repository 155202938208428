import React, { useEffect, useState } from "react";
import { Line, CategoryScale } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

import Table from "../Common/Table/Table"
import moment from "moment";
import "./Settings.scss"
import Header from "../Common/Header/Header";
import InfoGroup from "../Common/InfoGroup/InfoGroup";
// import Map from './Map';
import Api from "../../Api";
import { Slider, Snackbar, TextField } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CallMadeIcon from "@mui/icons-material/CallMade";
import "date-fns";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
// import BlockedPopup from '../Common/BlockedPopup/BlockedPopup';
import "antd/dist/antd.css";
import { Autocomplete } from "@mui/material";
import { Collapse } from "@mui/material";
// import './AnalyticsPage.scss';
// import '../../assets/stylesheets/common.scss';
import MuiAlert from "@mui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Spinner from "../Common/Spinner/Spinner";
import Button from "../Common/Button/Button";
import { SettingsActors } from "../../redux/Actors/settings";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Settings = ({ loading, AnalyticsList ,permissionIds,settings }) => {
  const dispatch = useDispatch()
  const [val,setVal]=useState([0,0,0])
  const [Edit, setEdit] = useState(false);
  const permission = permissionIds.permissionIds['settings']
  console.log(permission)
  const [comploading, setCompLoading] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [DataSource,setDataSource] = useState([])
  const handleClose = () => setOpenMessage(false);
  const loadData = () => {
    setCompLoading(true);
    if(settings.isLoaded)
    {setDataSource([
      {no:1,name:'Open Questions Limit',value:settings.data.results[0].value},
      {no:2,name:'Open Answer Limit',value:settings.data.results[1].value},
      {no:3,name:'Open Replies Limit',value:settings.data.results[2].value}
    ])
    setVal([settings.data.results[0].value,settings.data.results[1].value,settings.data.results[2].value])

}
    else
  {  Api.getSettings().then((response) => {
      setVal(response.data.results.map(item=>item.value))
      setDataSource([
          {no:1,name:'Open Questions Limit',value:response.data.results[0].value},
          {no:2,name:'Open Answer Limit',value:response.data.results[1].value},
          {no:3,name:'Open Replies Limit',value:response.data.results[2].value}
      ])
      setVal([response.data.results[0].value,response.data.results[1].value,response.data.results[2].value])
      dispatch({type:SettingsActors.SETTINGS_LIST,payload:{data:response.data,isLoaded:true}})
    });
  }
  setCompLoading(false);
  };
  useEffect(() => loadData(), [settings.isLoaded]);

  const Update = () => {
    const data = {
      QUESTION_LIMIT: Number(val[0]),
      ANSWER_LIMIT: Number(val[1]),
      REPLY_LIMIT: Number(val[2]),
    };
    Api.updateSettings(data)
      .then((response) => {
        console.log(response);
        setMessage("Updated Successfully");
        setSeverity("success");
        setOpenMessage(true);
        loadData();
        setEdit(false);
        dispatch({type:SettingsActors.TOGGLE_SETTINGS})
      })
      .catch((err) => {
        setMessage("Some Error Occured");
        setSeverity("error");
        setOpenMessage(true);
        loadData();
        setEdit(false);
      });
  };

  const handleChange=(record,e)=>{
    let temp = val
    temp[record.no-1]=e.target.value  
    setVal(temp)
  }
  const headerLayout = "2fr 4fr 4fr";
  const columnLayout = "2fr 4fr 4fr";
  const header = [
    // <input type="checkbox" />,
    "#",
    "Permission Name",
    "Granted Permission for",
  ];
  console.log(val)
  return comploading ? (
    <Spinner></Spinner>
  ) : (
    <div className="analyticsPage w-full">
      <Header name="Settings" noBack />
      <div
        style={{ display: "flex", justifyContent: "flex-end", }}
      >
        <div style={{ width: "10%", marginBottom: "20px" }}>
          <Button
            style={{ backgroundColor: "white",padding:"0.5rem" }}
            blackOutline
            onClick={() => {if(permissionIds.userPermissions[permission]?.is_enabled===true)setEdit(!Edit)}}
          >
            {Edit?"Cancel":"Edit"}
          </Button>
        </div>
        <Collapse in={Edit} >
          <div style={{ marginLeft: "20px", marginBottom: "20px" ,}}>
            <Button
              style={{ backgroundColor: "white",padding:"0.5rem",color:"#52c41a",borderColor:"#52c41a" }}
              blackOutline
              onClick={() => {
                
                Update();
              }}
            >
              Save
            </Button>
          </div>
        </Collapse>
      </div>
      <div className="customTable">
          <div className="customTable-div">
            <Table
              headerLayout={headerLayout}
              columnLayout={columnLayout}
              headers={header}
              dataSource={DataSource}
              dataKey={[
               
                {
                  name: "no",
                  render: (record) => {
                    return (
                        
                      <div className="self-center truncate py-4"  style={{height:"31.08px",fontSize:"17px"}}>
                        {record.no}
                      </div>
                    );
                  },
                },
                
                {
                  name: "Name",
                  render: (record) => {
                    return (
                      <div className="self-center truncate py-4" style={{height:"31.08px",fontSize:"17px"}}>
                        {record.name}
                      </div>
                    );
                  },
                },
                {
                  name: "Value",
                  render: (record) => {
                    return (
                        <div className="self-center truncate py-4" >
                        <Collapse in={!Edit} >
                          <div style={{height:"31.08px",fontSize:"17px"}} className="my-8 height-30">
                            {record.value}
                          </div>
                        </Collapse>
                        <Collapse in>
                          <Collapse in={Edit}>
                          
                            {/* <TextField
                              variant="outlined"
                              label={record.name}
                              defaultValue={record.value}
                              onChange={(e) => 
                               handleChange(record,e)}
                              inputProps={{padding:"0px"}}
                              style={{ margin: "10px"}}
                            /> */}
                            <div className="form weight-500 text-16 flex flex-col ">
                    {/* <label>Admin Name</label>
                    <input
                        type="text"
                        name="full_name"
                        value={full_name}
                        placeholder="Full Name"
                        onChange={(e) => handleChange(e)}
                        required
                    />
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        placeholder="username"
                        onChange={(e) => handleChange(e)}
                        required
                    /> */}
                {/* <label>{record.name}</label> */}
                    <input
                        type="text"
                        name="email"
                        defaultValue={record.value}
                        onChange={(e) => handleChange(record,e)}
                        required
                        autoComplete="off"
                        className="my-8 height-30 noOutline"
                    />
                </div>
                          </Collapse>
                        </Collapse>
                      </div>
                    );
                  },
                },

               
              ]}
            />
          </div>
        </div>
     
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  settings: state.settings.SettingsList,
});

export default connect(mapStateToProps)(Settings);
