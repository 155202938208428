import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import "../../../assets/stylesheets/common.scss";
import "./UserListPage.scss";

import HeaderWithSearch from "../../Common/HeaderWithSearch/HeaderWithSearch";
// import Dropdown from '../Common/Dropdown/Dropdown';
import ListRow from "../../Common/ListRowPopup/ListRow";
import PopupCard from "./PopupCard";
import Button from "../../Common/Button/Button";
import PDFIcon from "../../../assets/icons/PDFIcon";
import CSVIcon from "../../../assets/icons/CSVIcon";
import XLSIcon from "../../../assets/icons/XLSIcon";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
// import BlockedPopup from '../Common/BlockedPopup/BlockedPopup';
import StatTile from "../../Common/StatsTile/StatTile";
import Pagination from "../../Common/Pagination/Pagination";
import queryString from "query-string";
// import { send } from '../../WebSocket/WebSocket';

import {
  toggleDownloadPopup,
  userChecked,
  emptySelectedUsers,
  storeUserList,
} from "../../../redux/Actions/UserListPage";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../Api";
import Spinner from "../../Common/Spinner/Spinner";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import DownloadPopup from "../../Common/DownloadPopup/DownloadPopup";
import { UserListPageActors } from "../../../redux/Actors/UserListPageActors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserListPage = ({
  // infoPopup,
  selectedRows,
  selectedUsers,
  userChecked,
  emptySelectedUsers,
  loading,
  userList,
  ActiveUserList,
  DeletedUserList,
  BlockedUserList,
  permissionIds,
}) => {
  console.log(DeletedUserList, BlockedUserList);
  const navigate = useNavigate();
  const { status } = useParams();
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [countData, setCountData] = useState({
    active: "",
    blocked: "",
    deleted: "",
    // blocked: '',
  });
  const permission = permissionIds.permissionIds["user"];
  console.log(permissionIds.userPermissions[permission - 1]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [compLoading, setCompLoading] = useState(true);
  // const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);

  const [downloadPopup, setDownloadPopup] = useState(false);
  const [downloadType, setDownloadType] = useState("");
  const [downloadAll, setDownloadAll] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setSearchField(e.target.value);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const getStatus = (stat) => {
    if (stat === "all") return "All";
    else if (stat === "active") return "Activated";
    else if (stat === "blocked") return "Blocked";
    else if (stat === "deleted") return "Deleted";
  };

  const loadPage = ({
    start_date,
    end_date,
    search,
    status,
    limit,
    offset,
  }) => {
    emptySelectedUsers();
    const apiCall = async () => {
      setCompLoading(true);
      const data = {
        username: true,
        fullname: true,
        email: false,
        bio: false,
        avatar: true,
      };
      const status_name = getStatus(status ? status : "all");
      let params = {
        from_date: start_date ?? "",
        to_date: end_date ?? "",
        search: searchField ?? "",
        status: status_name ?? "All",
        limit: limit,
        offset: offset,
      };
      try {
        const res = await Api.getUserList(params, data);
        console.log(res);
        setUserData(res.data);
        const list = res.data?.body;
        setListData(
          list?.map((data, index) => ({
            no: Number(offset) + index + 1,
            no: index + 1,
            ...data,
          }))
        );

        const count = await Api.getUserCount();
        setCountData({
          active: count.data?.body.Activated,
          blocked: count.data?.body.Blocked,
          deleted: count.data?.body.Deleted,
        });
        if (
          end_date === "" &&
          limit === "" &&
          offset === "" &&
          searchField === "" &&
          start_date === ""
        ) {
          if (status === "all")
            dispatch(
              storeUserList({ data: res.data, count: count, isLoaded: true })
            );
          if (status === "active")
            dispatch({
              type: UserListPageActors.ACTIVE_USER_LIST,
              payload: { data: res.data, count: count, isLoaded: true },
            });
          if (status === "blocked")
            dispatch({
              type: UserListPageActors.BLOCKED_USER_LIST,
              payload: { data: res.data, count: count, isLoaded: true },
            });
          if (status === "deleted")
            dispatch({
              type: UserListPageActors.DELETED_USER_LIST,
              payload: { data: res.data, count: count, isLoaded: true },
            });
        }
        setPageLoading(false);
        setCompLoading(false);
      } catch (error) {
        console.log("in error");
        setGetErrorMessage(error.response?.data?.detail);
        setOpenGetError(true);
      }
    };

    if (
      end_date !== "" ||
      limit !== "" ||
      offset !== "" ||
      searchField !== "" ||
      start_date !== "" ||
      (status === "all" && !userList.isLoaded) ||
      (status === "active" && !ActiveUserList.isLoaded) ||
      (status === "blocked" && !BlockedUserList.isLoaded) ||
      (status === "deleted" && !DeletedUserList.isLoaded)
    )
      apiCall();
    else {
      if (status === "all") {
        setUserData(userList.data);
        const list = userList?.data?.body;
        setListData(
          list?.map((data, index) => ({
            no: Number(offset) + index + 1,
            no: index + 1,
            ...data,
          }))
        );
        setCountData({
          active: userList.count.data?.body.Activated,
          blocked: userList.count.data?.body.Blocked,
          deleted: userList.count.data?.body.Deleted,
        });
      } else if (status === "active") {
        setUserData(ActiveUserList.data);
        const list = ActiveUserList?.data?.body;
        setListData(
          list?.map((data, index) => ({
            no: Number(offset) + index + 1,
            no: index + 1,
            ...data,
          }))
        );
        setCountData({
          active: ActiveUserList.count.data?.body.Activated,
          blocked: ActiveUserList.count.data?.body.Blocked,
          deleted: ActiveUserList.count.data?.body.Deleted,
        });
      } else if (status === "blocked") {
        setUserData(BlockedUserList.data);
        const list = BlockedUserList?.data?.body;
        setListData(
          list?.map((data, index) => ({
            no: Number(offset) + index + 1,
            no: index + 1,
            ...data,
          }))
        );
        setCountData({
          active: BlockedUserList.count.data?.body.Activated,
          blocked: BlockedUserList.count.data?.body.Blocked,
          deleted: BlockedUserList.count.data?.body.Deleted,
        });
      }
      if (status === "deleted") {
        setUserData(DeletedUserList.data);
        const list = DeletedUserList?.data?.body;
        setListData(
          list?.map((data, index) => ({
            no: Number(offset) + index + 1,
            no: index + 1,
            ...data,
          }))
        );
        setCountData({
          active: DeletedUserList.count.data?.body.Activated,
          blocked: DeletedUserList.count.data?.body.Blocked,
          deleted: DeletedUserList.count.data?.body.Deleted,
        });
      }
      setPageLoading(false);
      setCompLoading(false);
    }
  };

  useEffect(() => {
    loadPage({
      start_date: "",
      end_date: "",
      status: status,
      offset: "",
      limit: "",
    });
  }, [
    status,
    selectedRows,
    userList.isLoaded,
    ActiveUserList.isLoaded,
    DeletedUserList.isLoaded,
    BlockedUserList.isLoaded,
  ]);

  const keyPress = (event) => {
    if (event.key === "Enter") {
      loadPage({ limit: "", offset: "", status: status });
    }
  };

  const colorMap = {
    // inactive: '#FFDB5B',
    active: "#42DA93",
    blocked: "grey",
    deleted: "#FF5B5B",
    all: "#000000",
  };

  const statNameMap = {
    active: "Active Users",
    blocked: "Blocked Users",
    deleted: "Deleted Users",
    all: "Total Users",
  };

  const tileClickHandler = (name) => {
    navigate(`/users/${name}`);
  };

  const deleteUsers = async () => {
    var data = {};
    data["ids"] = [];
    for (const val of selectedUsers) {
      data.ids.push(val);
    }
    const res = await Api.deleteUsers(data);
    if (res?.status === 204) {
      loadPage({ limit: "", offset: "", status: status });
      setMessage("User Deleted");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_USER_LIST" });
      dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({ type: UserListPageActors.TOGGLE_BLOCKED_USER_LIST });
      dispatch({ type: UserListPageActors.TOGGLE_DELETED_USER_LIST });
    }

    if (res?.status === 400) {
      loadPage({ limit: "", offset: "", status: status });
      setMessage("User already Deleted");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const activateUser = async () => {
    var data = {};
    data["ids"] = [];
    for (const val of selectedUsers) {
      data.ids.push(val);
    }
    data["status"] = "Activated";

    const res = await Api.changeUserStatus(data);
    if (res?.status === 200) {
      loadPage({ limit: "", offset: "", status: status });

      setMessage("User(s) Activated");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_USER_LIST" });
      dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({ type: UserListPageActors.TOGGLE_BLOCKED_USER_LIST });
      dispatch({ type: UserListPageActors.TOGGLE_DELETED_USER_LIST });
    }
  };
  const blockUser = async () => {
    var data = {};
    data["ids"] = [];
    for (const val of selectedUsers) {
      data.ids.push(val);
    }
    data["status"] = "Blocked";
    console.log("Doing");
    const res = await Api.changeUserStatus(data);
    if (res?.status === 200) {
      loadPage({ limit: "", offset: "", status: status });

      setMessage("User(s) Blocked");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_USER_LIST" });
      dispatch({ type: "TOGGLE_ACTIVE_USER_LIST" });
      dispatch({ type: UserListPageActors.TOGGLE_BLOCKED_USER_LIST });
      dispatch({ type: UserListPageActors.TOGGLE_DELETED_USER_LIST });
    }
  };

  const download = ({ type, all }) => {
    emptySelectedUsers();
  };

  const paginationData = (data) => {
    var start = data.indexOf("?");
    var parsed = queryString.parse(data.substr(start));
    console.log(parsed);
    const offset = parsed.offset ? parsed.offset : 0;
    loadPage({ limit: parsed.limit, offset: offset, status: status });
    navigate(`/users/${status}/?limit=${parsed.limit}&offset=${offset}`);
  };

  // console.log(userList)
  return loading || pageLoading ? (
    <Spinner />
  ) : (
    <div className="userListPage w-full">
      <HeaderWithSearch
        name="Users"
        handleChange={handleChange}
        keyPress={keyPress}
      />
      <StatTile
        statData={countData}
        statNameMap={statNameMap}
        colorMap={colorMap}
        onClick={tileClickHandler}
      />
      <div
        className="mid dropdownButtons my-16"
        style={{
          justifyContent: `${
            selectedUsers.length !== 0 ? "space-between" : "end"
          }`,
        }}
      >
        {selectedUsers.length !== 0 && (
          <div className="btns">
            <div className="downloadBtnDropdown">
              <Button popupBtn blackOutline>
                Download All
              </Button>
              <div className="dropdownContent b-6">
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "pdf", all: true })}
                >
                  <PDFIcon />
                  Download as PDF
                </button>
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "csv", all: true })}
                >
                  <CSVIcon />
                  Download as CSV
                </button>
                <button
                  className="flex justify-between items-center w-full"
                  onClick={() => download({ type: "xlsx", all: true })}
                >
                  <XLSIcon />
                  Download as XLSX
                </button>
              </div>
            </div>
            <div className="downloadBtnDropdown">
              <Button popupBtn blackOutline style={{ padding: 6 }}>
                {`Download ${selectedUsers.length} ${
                  selectedUsers.length === 1 ? "user" : "users"
                }`}
              </Button>
              <div className="dropdownContent b-6" style={{ width: "12.3rem" }}>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "pdf" })}
                >
                  <PDFIcon />
                  Download as PDF
                </button>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "csv" })}
                >
                  <CSVIcon />
                  Download as CSV
                </button>
                <button
                  className="flex justify-between items-center text-14 w-full px-16"
                  onClick={() => download({ type: "xlsx" })}
                >
                  <XLSIcon />
                  Download as XLSX
                </button>
              </div>
            </div>
            {/* {status !== 'inactive' &&
                            status !== 'deleted' &&
                            status !== 'all' && (
                                <Button
                                    profilePage
                                    onClick={() => setDeactivatePopup(true)}
                                >
                                    {selectedUsers.length === 1
                                        ? 'Deactivate User'
                                        : 'Deactivate Users'}
                                </Button>
                            )} */}
            {status !== "active" && status !== "deleted" && status !== "all" && (
              <Button
                profilePage
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission]?.is_enabled ===
                    true
                  )
                    setActivatePopup(true);
                }}
                style={{ color: "#00cc7a", borderColor: "#00cc7a", padding: 1 }}
              >
                {selectedUsers.length === 1
                  ? "Activate User"
                  : "Activate Users"}
              </Button>
            )}
            {status !== "blocked" && status !== "deleted" && status !== "all" && (
              <Button
                profilePage
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission]?.is_enabled ===
                    true
                  )
                    setBlockPopup(true);
                }}
              >
                {selectedUsers.length === 1 ? "Block User" : "Block Users"}
              </Button>
            )}
            {status !== "deleted" && status !== "all" && (
              <Button
                popupBtn
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    permissionIds.userPermissions[permission + 1]
                      ?.is_enabled === true
                  )
                    setDeletePopup(true);
                }}
              >
                {selectedUsers.length === 1 ? "Delete User" : "Delete Users"}
              </Button>
            )}
          </div>
        )}
        <Pagination page={userData} onChange={paginationData} />
      </div>
      {compLoading ? (
        <Spinner top="50%" left="50%" />
      ) : (
        <div className="customTable">
          <div className="customTable-div">
            <ListRow topBar allUsers={listData} limit={listData?.length} />
            {listData?.map((user, index) => {
              return <ListRow key={index} user={user} num={user.no} />;
            })}
            {(listData?.length === 0 || !listData) && (
              <h2 className="text-center mt-20" style={{ color: "grey" }}>
                No Records Available
              </h2>
            )}
          </div>
        </div>
      )}
      {/* {infoPopup && <PopupCard info />}
            {downloadPopup === 'downloading' && <PopupCard downloading />}
            {downloadPopup === 'deactivate' && <PopupCard deactivate />}
            {downloadPopup === 'deactivating' && <PopupCard deactivating />}
            {downloadPopup === 'deleteUsers' && <PopupCard deleteUsers />}
            {downloadPopup === 'deleting' && <PopupCard deleting />}*/}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {/* <BlockedPopup
                open={openGetError}
                setOpen={setOpenGetError}
                message={getErrorMessage}
            /> */}
      {/* <ConfirmPopup
                // open={deactivatePopup}
                // setOpen={setDeactivatePopup}
                // name="Deactivate"
                // page="user"
                // num={selectedUsers.length}
                // action="deactivate"
                // handleChange={() => deactivateUsers()}
            /> */}
      <ConfirmPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        name="Delete"
        page="user"
        num={selectedUsers.length}
        action="delete"
        handleChange={() => deleteUsers()}
      />
      <ConfirmPopup
        open={activatePopup}
        setOpen={setActivatePopup}
        name="Activate"
        page="user"
        num={selectedUsers.length}
        action="activate"
        handleChange={() => activateUser()}
      />
      <ConfirmPopup
        open={blockPopup}
        setOpen={setBlockPopup}
        name="Block"
        page="user"
        num={selectedUsers.length}
        action="block"
        handleChange={() => blockUser()}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  // infoPopup: state.userList.infoPopup,
  selectedRows: state.userList?.selectedRows,
  username: state.auth.currentUser?.username,
  loading: state.auth.loading,
  permissionIds: state.auth,
  selectedUsers: state.userList.selectedUsers,
  userList: state.userList.userList,
  ActiveUserList: state.userList.ActiveUserList,
  BlockedUserList: state.userList.BlockedUserList,
  DeletedUserList: state.userList.DeletedUserList,
});

export default connect(mapStateToProps, {
  toggleDownloadPopup,
  userChecked,
  emptySelectedUsers,
})(UserListPage);

// <div className="relative dropdownDiv" style={{ width: '100%' }}>
//     <div>
//         <Dropdown label="New Users First" widthDropDown="100%" />
//     </div>
// </div>
// <DownloadPopup
//     open={downloadPopup}
//     setOpen={setDownloadPopup}
//     page="user"
//     type={downloadType}
//     all={downloadAll}
//     list={selectedUsers}
//     module="download_users"
// />

// setDownloadType('pdf');
// setDownloadAll(true);
// setDownloadPopup(true);
