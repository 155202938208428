export const AuthActors = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',
    LOAD_USER: 'LOAD_USER',
    LOAD_USER_PERMISSIONS: 'LOAD_USER_PERMISSIONS',
    PERMISSIONS_ERROR: 'PERMISSIONS_ERROR',
    // AUTH_ERROR: 'AUTH_ERROR',
    API_ERROR: 'API_ERROR',
    DOWNLOAD_URL: 'DOWNLOAD_URL',
    // SET_NOTIFICATION: 'SET_NOTIFICATION',
    REMOVE_ERRORS: 'REMOVE_ERRORS',
};
