import UserCircle from "./svgIcons/UserCircle.svg";
import Power from "./svgIcons/Power.svg";
import CaretLeft from "./svgIcons/CaretLeft.svg";
import CaretRight from "./svgIcons/CaretRight.svg";
import ChartLine from "./svgIcons/ChartLine.svg";
import Chats from "./svgIcons/Chats.svg";
import Lightbulb from "./svgIcons/Lightbulb.svg";
import SuitcaseSimple from "./svgIcons/SuitcaseSimple.svg";
import UserCircleGear from "./svgIcons/UserCircleGear.svg";
import Users from "./svgIcons/Users.svg";
import WarningOctagon from "./svgIcons/WarningOctagon.svg";
import {VscReport,VscVerified} from 'react-icons/vsc'
import {AiOutlineUserSwitch,AiOutlineSetting} from 'react-icons/ai'
import {BiCategoryAlt} from 'react-icons/bi'
import {BsQuestionOctagon} from 'react-icons/bs'


export default {
  icons: {
    UserCircle,
    Power,
    CaretLeft,
    CaretRight,
    ChartLine,
    Chats,
    Lightbulb,
    SuitcaseSimple,
    UserCircleGear,
    Users,
    WarningOctagon,
    AiOutlineUserSwitch:<AiOutlineUserSwitch fontSize={32} />,
    VscReport:<VscReport fontSize={30} />,
    BsQuestionOctagon:<BsQuestionOctagon fontSize={28} style={{color:"rgb(0 0 0 / 85%)"}}/>,
    VscVerified:<VscVerified fontSize={32} />,
    BiCategoryAlt:<BiCategoryAlt fontSize={28} style={{color:"rgb(0 0 0 / 85%)"}}/>,
    AiOutlineSetting:<AiOutlineSetting fontSize={32} style={{color:"rgb(0 0 0 / 85%)"}}/>
  },
};
