import React, { useEffect, useState } from "react";

import "../../assets/stylesheets/common.scss";
import "./AccountPage.scss";

import FormInput from "../Common/FormInput/FormInput";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Header from "../Common/Header/Header";

import Api from "../../Api";

import { Collapse, Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import Spinner from "../Common/Spinner/Spinner";
import { connect } from "react-redux";
import moment from "moment";
import Button from "../Common/Button/Button";
import { useNavigate } from "react-router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ForgotPassword = () => {
  const [err, setErr] = useState("");
  const [oldPass, setOld] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const handleClose = () => {
    setOpenMessage(false);
  };
  const navigate = useNavigate()

  const handleSubmit = async(event) => {
    event.preventDefault();
    const form = new FormData()
    form.append("email",oldPass.toString)
    const data={email:oldPass}
    console.log(oldPass,data)
    try{
    const response=await Api.sendEmail(data)
    console.log(response)
    if(response&&response.status===200)
    {
      setMessage("Reset Link Sent to Your Mail")
      setSeverity("success")
      setOpenMessage(true)
    } 
    else
    {
      setMessage("Please Check Your Mail")
      setSeverity("error")
      // setOpenMessage(true)
    } }
    catch(error){
      setMessage(error.response.data.message[0])
      setSeverity("error")
      setOpenMessage(true)
      // console.log(error.response)
    }
  }

  return (
    <div>
      <div className="accountPage w-full">
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowBackIosOutlinedIcon />
        </div>
        <div className="accountDetails relative">
          <div className="formDiv">
            <div className="loginText text-center">
              <h1 className="weight-700 text-36">Forgot Password</h1>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="accountPage-form">
                <FormInput
                  type="email"
                  label="Email"
                  placeholder="Enter Your Email"
                  handleChange={(e) => setOld(e.target.value)}
                  value={oldPass}
                  required
                />
              </div>
              <p className="errorMessage">{err}</p>
              <div
                className="signup-btn"
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button>Send Email</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ForgotPassword;
