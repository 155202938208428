import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Table from "../../Common/Table/Table";
import "./Issues.scss";
import { useLocation, withRouter, Redirect } from "react-router-dom";
import StatTile from "../../Common/StatsTile/StatTile";
import Spinner from "../../Common/Spinner/Spinner";
// import Api from '../../Api';
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
// import BlockedPopup from '../../Common/BlockedPopup/BlockedPopup';
import { connect, useDispatch } from "react-redux";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import HeaderWithSearch from "../../Common/HeaderWithSearch/HeaderWithSearch";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import Button from "../../Common/Button/Button";
import PDFIcon from "../../../assets/icons/PDFIcon";
import CSVIcon from "../../../assets/icons/CSVIcon";
import XLSIcon from "../../../assets/icons/XLSIcon";
// import { send } from '../../WebSocket/WebSocket';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import Api from "../../../Api";
import { GoIssueReopened } from "react-icons/go";
import { ReportIssueListActors } from "../../../redux/Actors/ReportIssueActors";

function Issues({
  loading,
  reportIssue,
  pendingList,
  resolvedList,
  discardedList,
  permissionIds
}) {
  console.log({ reportIssue, pendingList, resolvedList, discardedList });
  const navigate = useNavigate();
  let { status } = useParams();
  const { state } = useLocation();
  const [issueStatData, setIssueStatData] = useState();
  const [issueDataSource, setIssueDataSource] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const [name, setName] = useState(status);
  const location = useLocation();
  const permission=permissionIds.permissionIds[location.pathname.split("/")[1]]
  console.log(permission,permissionIds?.userPermissions[permission-1]?.is_enabled===true)
  const [limit, setLimit] = useState("");
  const [offset, setOffset] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [deleteElement, setDeleteElement] = useState([]);
  const [open, setOpen] = useState(false);
  const [issueDetailId, setIssueDetailId] = useState();
  const [issuePagination, setIssuePagination] = useState();
  const dispatch = useDispatch();
  const [resolvePopup, setResolvePopup] = useState(false);
  const [pendingPopup, setPendingPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [compLoading, setCompLoading] = useState(true);
  const headerLayout = "40px 30px 120px 1.5fr 2.5fr 2fr 1fr 1fr";
  const columnLayout = "40px 30px 120px 1.5fr 2.5fr 2fr 1fr 1fr";
  // console.log("seel all", selectAllChecked);
  
  const header = [
    <div className="checkbox">
      <input
        type="checkbox"
        id="header"
        checked={selectAllChecked}
        onChange={() => {
          setSelectAllChecked(!selectAllChecked);
          !selectAllChecked && issueDataSource
            ? setSelectedElements(issueDataSource.map((data) => data.id))
            : setSelectedElements([]);
        }}
      />
      <label htmlFor="header" />
    </div>,
    "#",
    "Image",
    "Issue",
    "Issue Description",
    "Raised on",
    "Raised by",
    "Status",
  ];

  var popElement = (id) => {
    setOpen(true);
    setIssueDetailId(id);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (!isNaN(state)) {
      setOpen(true);
      setIssueDetailId(state);
    }
  }, [state]);

  useEffect(() => {
    issueStatFunction();
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    issueDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset
    );
  }, [location, fromDate, toDate, reportIssue,
    pendingList.isLoaded,
    resolvedList.isLoaded,
    discardedList.isLoaded,]);

  useEffect(() => {
    if (searchGroup === "") {
      const parsed = queryString.parse(location.search);
      issueDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset
      );
    }
  }, [searchGroup]);

  const refreshReport = () => {
    issueStatFunction();
    issueDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
  };

  const issueStatFunction = () => {
    try {
      Api.getIssueStatTile().then((response) => {
        setIssueStatData(response?.data.body);
      });
    } catch (error) {
      setGetErrorMessage(error.response?.data?.detail);
      setOpenGetError(true);
    }
  };

  const issueDataFunction = (
    searchGroup,
    status,
    fromDate,
    toDate,
    limit,
    offset
  ) => {
    setSelectedElements([]);
    setCompLoading(true);
    offset = offset ? offset : 0;
    searchGroup = searchGroup ?? "";
    status = status ?? "all";
    fromDate = fromDate ?? "";
    toDate = toDate ?? "";
    limit = limit ?? "";
    offset = offset ?? "";
    const apiCall = () => {
      try {
        Api.getIssueData(searchGroup, status, fromDate, toDate, limit, offset)
          .then((response) => {
            setIssuePagination(response?.data);
            setIssueDataSource(
              response?.data?.body?.map((data, index) => ({
                no: Number(offset) + (index + 1),
                ...data,
              }))
            );

            if (
              fromDate === "" &&
              limit === "" &&
              offset === 0 &&
              searchGroup === "" &&
              toDate === ""
            ) {
              if (status === "all")
                dispatch({
                  type: "REPORT_ISSUE_LIST",
                  payload: { data: response.data, isLoaded: true },
                });
              if (status === "pending")
                dispatch({
                  type: ReportIssueListActors.PENDING_REPORT_ISSUE_LIST,
                  payload: { data: response.data, isLoaded: true },
                });
              if (status === "resolved")
                dispatch({
                  type: ReportIssueListActors.RESOLVED_REPORT_ISSUE_LIST,
                  payload: { data: response.data, isLoaded: true },
                });
              if (status === "discarded")
                dispatch({
                  type: ReportIssueListActors.DISCARDED_REPORT_ISSUE_LIST,
                  payload: { data: response.data, isLoaded: true },
                });
            }
          })
          .then((res) => {
            setPageLoading(false);
            setCompLoading(false);
          });
      } catch (error) {
        setGetErrorMessage(error.response?.data?.detail);
        setOpenGetError(true);
      }
    };
    console.log({ searchGroup, status, fromDate, toDate, limit, offset });
    if (
      fromDate !== "" ||
      limit !== "" ||
      offset !== 0 ||
      searchGroup !== "" ||
      (status === "all" && !reportIssue.isLoaded) ||
      (status === "pending" && !pendingList.isLoaded) ||
      (status === "discarded" && !discardedList.isLoaded) ||
      (status === "resolved" && !pendingList.isLoaded) ||
      toDate !== ""
    )
      apiCall();
    else {
      // console.log("Reached",reportIssue)
      if(status==="all")
      {
      setIssuePagination(reportIssue.data);
      setIssueDataSource(
        reportIssue.data.body?.map((data, index) => ({
          no: Number(offset) + (index + 1),
          ...data,
        }))
      );}
      if(status==='pending')
      {
        setIssuePagination(pendingList.data);
      setIssueDataSource(
        pendingList.data.body?.map((data, index) => ({
          no: Number(offset) + (index + 1),
          ...data,
        }))
      );
      }
      if(status==='discarded')
      {
        setIssuePagination(discardedList.data);
      setIssueDataSource(
        discardedList.data.body?.map((data, index) => ({
          no: Number(offset) + (index + 1),
          ...data,
        }))
      );
      }
      if(status==='resolved')
      {
        setIssuePagination(resolvedList.data);
      setIssueDataSource(
        resolvedList.data.body?.map((data, index) => ({
          no: Number(offset) + (index + 1),
          ...data,
        }))
      );
      }
      setPageLoading(false);
      setCompLoading(false);
    }
  };

  const paginationData = (data) => {
    var parsed = queryString.parse(data);
    // setLimit(parsed.limit);
    // setOffset(parsed.offset);
    const offset = parsed.offset ? parsed.offset : 0;
    issueDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset
    );
    navigate(`/issues/${status}?limit=${parsed.limit}&offset=${offset}`);
  };

  const handleCheck = (key) => {
    selectedElements.includes(key)
      ? setSelectedElements(
          selectedElements.filter(function (item) {
            return item !== key;
          })
        )
      : setSelectedElements([...selectedElements, key]);
  };

  useEffect(() => {
    if (selectedElements?.length === issueDataSource?.length)
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
  }, [selectedElements]);

  const colorMap = {
    resolved: "#42DA93",
    pending: "#FFDB5B",
    discarded: "#FF5B5B",
    all: "#000000",
  };

  const statNameMap = {
    resolved: "Resolved issues",
    pending: "Pending issues",
    discarded: "Discarded issues",
    all: "Total issues",
  };

  const tileClickHandler = (status) => {
    navigate(`/issues/${status}?limit=${""}&offset=${""}`);
    issueDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
    setSelectedElements([]);
  };

  const startDate = (date, dateString) => {
    setFromDate(dateString);
  };

  const endDate = (date, dateString) => {
    setToDate(dateString);
  };

  const search = (event) => {
    setSearchGroup(event.target.value);
  };

  const keyPress = (event) => {
    if (event.key === "Enter") {
      const parsed = queryString.parse(location.search);
      issueDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset
      );
    }
  };

  const download = ({ type, all }) => {
    // send({
    //     ids: all ? 'all' : selectedElements[0]?selectedElements:'all',
    //     module: 'download_issues',
    //     download_type: type,
    // });
    setSelectedElements([]);
  };

  function disabledStartDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrAfter(toDate)
    );
  }
  function disabledEndDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrBefore(fromDate)
    );
  }

  const changeIssueStatus = (status_name) => {
    var bodyFormData = { ids: [] };
    for (const val of selectedElements) {
      bodyFormData.ids.push(val);
    }
    console.log(bodyFormData)
    bodyFormData["status"] = status_name;
    Api.editIssueStatus(bodyFormData).then((response) => {
      if (response?.status === 200) {
        issueStatFunction();
        issueDataFunction(searchGroup, status, fromDate, toDate);
        setSelectedElements([]);
        setMessage(`Issues ${status_name}`);
        setSeverity("success");
        setOpenMessage(true);
      }
    });
  };

  const deleteIssue = () => {
    var bodyFormData = { ids: [] };
    for (const val of selectedElements) {
      bodyFormData.ids.push(val);
    }
    Api.deleteIssue(bodyFormData).then((response) => {
      if (response?.status === 204) {
        issueStatFunction();
        issueDataFunction(searchGroup, status, fromDate, toDate);
        setSelectedElements([]);
        setMessage(`Issues Discarded`);
        setSeverity("success");
        setOpenMessage(true);
      } else if (response?.status === 404) {
        issueStatFunction();
        issueDataFunction(searchGroup, status, fromDate, toDate);
        setSelectedElements([]);
        setMessage(`IDs not found`);
        setSeverity("error");
        setOpenMessage(true);
      }
    });
  };

  return loading || pageLoading ? (
    <Spinner />
  ) : (
    <div className="Issue w-full">
      <HeaderWithSearch
        name="Report Issues"
        handleChange={search}
        keyPress={keyPress}
      />
      <StatTile
        statData={issueStatData}
        statNameMap={statNameMap}
        colorMap={colorMap}
        onClick={tileClickHandler}
      />
      {selectedElements.length > 0 ? (
        <div className="flex flex-col">
          <div className="mid flex justify-start my-16">
            <div
              className="btns flex items-center justify-between"
              style={{ width: "25rem" }}
            >
              <div className="downloadBtnDropdown">
                <Button popupBtn blackOutline>
                  Download All
                </Button>
                <div className="dropdownContent b-6">
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() => download({ type: "pdf", all: true })}
                  >
                    <PDFIcon />
                    Download as PDF
                  </button>
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() => download({ type: "csv", all: true })}
                  >
                    <CSVIcon />
                    Download as CSV
                  </button>
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() =>
                      download({
                        type: "xlsx",
                        all: true,
                      })
                    }
                  >
                    <XLSIcon />
                    Download as XLSX
                  </button>
                </div>
              </div>
              <div className="downloadBtnDropdown">
                <Button popupBtn blackOutline>
                  {`Download ${selectedElements.length} ${
                    selectedElements.length === 1 ? "issue" : "issues"
                  }`}
                </Button>
                <div
                  className="dropdownContent b-6"
                  style={{ width: "13.1rem" }}
                >
                  <button
                    className="flex justify-between items-center text-14 w-full px-20"
                    onClick={() => download({ type: "pdf" })}
                  >
                    <PDFIcon />
                    Download as PDF
                  </button>
                  <button
                    className="flex justify-between items-center text-14 w-full px-20"
                    onClick={() => download({ type: "csv" })}
                  >
                    <CSVIcon />
                    Download as CSV
                  </button>
                  <button
                    className="flex justify-between items-center text-14 w-full px-20"
                    onClick={() => download({ type: "xlsx" })}
                  >
                    <XLSIcon />
                    Download as XLSX
                  </button>
                </div>
              </div>
            </div>
            <div className="flex">
              <DatePicker
                className="datePickerStyle"
                onChange={startDate}
                disabledDate={disabledStartDate}
              />
              <div className="dash mx-14 mt-14"></div>
              <DatePicker
                className="datePickerStyle"
                onChange={endDate}
                disabledDate={disabledEndDate}
              />
            </div>
          </div>
          <div className="flex justify-between btnsAndPagination">
            <div className="flex start statusBtns">
              {status === "pending" ? (
                <Button
                  popupBtn
                  blackOutline
                  onClick={() => {
                    if(permissionIds.userPermissions[permission+1]?.is_enabled===true)
                    setResolvePopup(true)}}
                >
                  {selectedElements.length === 1
                    ? "Resolve Issue"
                    : "Resolve Issue(s)"}
                </Button>
              ) : null}

              {status === "resolved" ? (
                <Button
                  popupBtn
                  blackOutline
                  onClick={() =>{if(permissionIds.userPermissions[permission+1]?.is_enabled===true)
                     setPendingPopup(true)}}
                >
                  {selectedElements.length === 1
                    ? "Pending Issue"
                    : "Pending Issue(s)"}
                </Button>
              ) : null}

              {status !== "discarded" && status !== "all" ? (
                <Button popupBtn onClick={() => {
                  if(permissionIds.userPermissions[permission]?.is_enabled===true)
                  setDiscardPopup(true)}}>
                  {selectedElements.length === 1
                    ? "Discard Issue"
                    : "Discard Issue(s)"}
                </Button>
              ) : null}
            </div>
            <Pagination page={issuePagination} onChange={paginationData} />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="mid flex justify-between my-16">
            <div className="flex">
              <DatePicker
                className="datePickerStyle"
                onChange={startDate}
                disabledDate={disabledStartDate}
              />
              <div className="dash mx-14 mt-14"></div>
              <DatePicker
                className="datePickerStyle"
                onChange={endDate}
                disabledDate={disabledEndDate}
              />
            </div>
            <Pagination page={issuePagination} onChange={paginationData} />
          </div>{" "}
        </>
      )}
      {compLoading ? (
        <Spinner top="50%" left="50%" />
      ) : (
        <div className="customTable">
          <div className="customTable-div">
            <Table
              headerLayout={headerLayout}
              columnLayout={columnLayout}
              headers={header}
              dataSource={issueDataSource}
              dataKey={[
                {
                  name: "SelectBox",
                  render: (record) => {
                    return (
                      <div className="self-center checkbox">
                        <input
                          type="checkbox"
                          className="py-6"
                          id={record.id}
                          checked={selectedElements.includes(record.id)}
                          onChange={() => handleCheck(record.id)}
                        />
                        <label htmlFor={record.id} />
                      </div>
                    );
                  },
                },
                "no",
                {
                  name: "image",
                  render: (record) => {
                    return (
                      <div className="py-6 truncate self-center cursor-pointer">
                        <div onClick={() => popElement(record.id)}>
                          {record.image ? (
                            <img width="100px" alt="Image" src={record.image} />
                          ) : (
                            <GoIssueReopened style={{ fontSize: 50 }} />
                          )}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  name: "title",
                  render: (record) => {
                    return (
                      <div className="py-6 truncate self-center cursor-pointer">
                        <div onClick={() => popElement(record.id)}>
                          {record.title}
                        </div>
                      </div>
                    );
                  },
                },

                {
                  name: "description",
                  render: (record) => {
                    return (
                      <div className="py-6 truncate self-center cursor-pointer">
                        <div onClick={() => popElement(record.id)}>
                          {record.description}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  name: "reported_on",
                  render: (record) => {
                    return (
                      <div className="py-6 self-center">
                        {moment(record.created_at).format(
                          "DD/MM/YYYY, hh:mm a"
                        )}
                      </div>
                    );
                  },
                },
                {
                  name: "reported_by",
                  render: (record) => {
                    return (
                      <div className="py-6 self-center">
                        {record.profile.full_name}
                      </div>
                    );
                  },
                },
                {
                  name: "status",
                  render: (record) => {
                    return (
                      <>
                        {record.status === "resolved" && (
                          <button
                            className="self-center normalButton height-30 my-8 "
                            style={{
                              color: "#42da93",
                              border: "1px solid #42da93",
                            }}
                          >
                            {record.status}
                          </button>
                        )}

                        {record.status === "discarded" && (
                          <button
                            className="self-center normalButton height-30 my-8"
                            style={{
                              color: "#FF5B5B",
                              border: "1px solid #FF5B5B",
                            }}
                          >
                            {record.status}
                          </button>
                        )}
                        {record.status === "pending" && (
                          <button
                            className="self-center normalButton height-30 my-8"
                            style={{
                              color: "#FFDB5B",
                              border: "1px solid #FFDB5B",
                            }}
                          >
                            {record.status}
                          </button>
                        )}

                        {record.status === "('pending', 'pending')" && (
                          <button
                            className="self-center normalButton height-30 my-8"
                            style={{
                              color: "#FFDB5B",
                              border: "1px solid #FFDB5B",
                            }}
                          >
                            {record.status}
                          </button>
                        )}
                      </>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
      {/* <Popup
                open={open}
                handleClose={() => setOpen(false)}
                width="sm"
                title="Manage Issues"
            >
                <IssueDetail
                    id={issueDetailId}
                    handleClose={() => setOpen(false)}
                    updatePage={refreshReport}
                />
            </Popup> */}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      {/* <BlockedPopup
                open={openGetError}
                setOpen={setOpenGetError}
                message={getErrorMessage}
            /> */}

      <ConfirmPopup
        open={pendingPopup}
        setOpen={setPendingPopup}
        name="Set Issue Pending"
        page=""
        num={selectedElements.length}
        action="close"
        handleChange={() => changeIssueStatus("pending")}
      />
      <ConfirmPopup
        open={resolvePopup}
        setOpen={setResolvePopup}
        name="Resolve Issue"
        page=""
        num={selectedElements.length}
        action="close"
        handleChange={() => changeIssueStatus("resolved")}
      />
      <ConfirmPopup
        open={discardPopup}
        setOpen={setDiscardPopup}
        name="Discard Issue"
        page=""
        num={selectedElements.length}
        action="close"
        handleChange={() => deleteIssue()}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  loading: false,
  permissionIds:state.auth,
  reportIssue: state.reportIssue.ReportIssueList,
  pendingList: state.reportIssue.PendingReportIssueList,
  resolvedList: state.reportIssue.ResolvedReportIssueList,
  discardedList: state.reportIssue.DiscardedReportIssueList,
});

export default connect(mapStateToProps)(Issues);
