import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "./icons";
import TextIcon from "../../Common/TextIcon/TextIcon";
import Button from "../../Common/Button/Button";
import { Dialog } from "@mui/material";
import CloseIcon from "../../../assets/icons/CloseIcon";
import Api from "../../../Api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PopupForm = ({ open, setOpen }) => {
  const [permissions, setPermissions] = useState([]);
  const [formData, setFormData] = useState({
    // full_name: '',
    email: "",
    // username: '',
    role: "subadmin",
  });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const navigate = useNavigate();

  const { email } = formData;
  // const { full_name, email, username } = formData;
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleOnChange = async (id) => {
    console.log("IDD", id);
    if (
      id === 1 ||
      id === 4 ||
      id === 7 ||
      id === 9 ||
      id === 13 ||
      id === 14 ||
      id === 17 ||
      id === 20 ||
      id === 23
    ) {
      if (selectedPermissions.indexOf(id) === -1) {
        setSelectedPermissions([...selectedPermissions, id]);
      } else {
        let updatedPermissions = selectedPermissions.filter(
          (permId) => permId !== id
        );
        updatedPermissions = updatedPermissions.filter(
          (permId) => permId !== id + 1
        );
        updatedPermissions = updatedPermissions.filter(
          (permId) => permId !== id + 2
        );
        setSelectedPermissions(updatedPermissions);
      }
    } else if (
      id === 2 ||
      id === 5 ||
      id===8||
      id === 10 ||
      id === 15 ||
      id === 18 ||
      id === 21 ||
      id === 24
    ) {
      if (selectedPermissions.indexOf(id) === -1) {
        setSelectedPermissions([...selectedPermissions, id, id - 1]);
      } else {
        let updatedPermissions = selectedPermissions.filter(
          (permId) => permId !== id
        );
        updatedPermissions = updatedPermissions.filter(
          (permId) => permId !== id + 1
        );
        setSelectedPermissions(updatedPermissions);
      }
    } else if (
      id === 3 ||
      id === 6 ||
      id === 11 ||
      id === 16 ||
      id === 19 ||
      id === 22
    ) {
      if (selectedPermissions.indexOf(id) === -1) {
        setSelectedPermissions([...selectedPermissions, id, id - 1, id - 2]);
      } else {
        setSelectedPermissions(
          selectedPermissions.filter((permId) => permId !== id)
        );
      }
    } else if (id === 12 || id === 13) {
      if (selectedPermissions.indexOf(id) === -1) {
        setSelectedPermissions([...selectedPermissions, id]);
      } else {
        setSelectedPermissions(
          selectedPermissions.filter((permId) => permId !== id)
        );
      }
    }
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    Api.getPermissions().then((res) => {
      let temp = res?.data?.body;
      setPermissions(temp);
    });
  }, []);

  const handleSubmit = async () => {
    // const newPermissions = JSON.stringify([
    //     ...new Set(selectedPermissions),
    // ]);
    // const body = JSON.stringify({
    //     ...formData,
    //     permissions: newPermissions,
    // });

    var data = formData;

    data["permissions"] = selectedPermissions;

    console.log(data);
    try {
      const res = await Api.createAdmin(data);
      console.log(res);

      if (res?.status === 200) {
        setMessage("New Admin Created");
        setSeverity("success");
        setOpenMessage(true);
        navigate(0);
      }
    } catch (res) {
      if (res?.response.status === 500) {
        setMessage("Admin with this email already exists");
        setSeverity("error");
        setOpenMessage(true);
      } else if (res?.response.status === 400) {
        setMessage("Select atleast one permission & a valid email");
        setSeverity("error");
        setOpenMessage(true);
      }
    }
  };

  const clearData = () => {
    setFormData({
      // full_name: '',
      email: "",
      // username: '',
      role: "subadmin",
    });
    setSelectedPermissions([]);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className="popupForm px-42 py-24">
        <div className="flex justify-between items-center mb-10">
          <h3 className="weight-500 text-18">Create New Admin</h3>

          <div
            className="popupClose cursor-pointer"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="form weight-500 text-16 flex flex-col">
          {/* <label>Admin Name</label>
                    <input
                        type="text"
                        name="full_name"
                        value={full_name}
                        placeholder="Full Name"
                        onChange={(e) => handleChange(e)}
                        required
                    />
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        placeholder="username"
                        onChange={(e) => handleChange(e)}
                        required
                    /> */}
          <label>E-mail ID</label>
          <input
            type="text"
            name="email"
            value={email}
            placeholder="Enter your official email-ID"
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        <div className="customTable">
          <div className="customTable-div">
            <div className="iconSwitches flex">
              <div
                className="mr-40 mt-66 flex flex-col justify-between"
                style={{ height: "25rem" }}
              >
                {icons.map((route) => {
                  return <TextIcon icon={route.icon} text={route.text} />;
                })}
              </div>
              <div className="switches">
                <p className="text-center" style={{ width: "60px" }}>
                  Read
                </p>
                <p className="text-center" style={{ width: "60px" }}>
                  Update
                </p>
                <p className="text-center" style={{ width: "60px" }}>
                  Delete
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={selectedPermissions.includes(12)}
                    onChange={() => handleOnChange(12)}
                  />
                  <span className="slider round"></span>
                </label>
                <empty />
                <empty />
                {permissions?.map((perm) => {
                  if (perm[0] === 12) {
                    return <></>;
                  }

                  return (
                    <>
                      {14 === perm[0] ? (
                        <>
                          <label className="switch">
                            <br />
                          </label>
                          <label className="switch">
                            <br />
                          </label>
                        </>
                      ) : [9].includes(perm[0]) ? (
                        <label className="switch">
                          <br />
                        </label>
                      ) : null}
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={selectedPermissions.includes(perm[0])}
                          onChange={() => handleOnChange(perm[0])}
                        />
                        <span className="slider round"></span>
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-50">
          <Button
            profilePage
            blackOutline
            onClick={() => {
              clearData();
              setOpen(false);
            }}
          >
            Discard Details
          </Button>
          <Button
            profilePage
            onClick={() => {
              handleSubmit();
              message === "success" &&
                setTimeout(function () {
                  setOpen(false);
                }, 2000);
            }}
          >
            Create Admin
          </Button>
        </div>
        <Snackbar
          open={openMessage}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </Dialog>
  );
};

export default PopupForm;
