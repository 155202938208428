import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Table from '../../Common/Table/Table';
import EditQuestionPopup from './EditQuestion'
import AddQuestionPopup from './AddQuestion'
import './QuestionsManagement.scss';
import { useLocation, withRouter, Redirect } from 'react-router-dom';
import StatTile from '../../Common/StatsTile/StatTile';
import Spinner from '../../Common/Spinner/Spinner';
// import Api from '../../Api';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '../../Common/Pagination/Pagination';
// import BlockedPopup from '../../Common/BlockedPopup/BlockedPopup';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import HeaderWithSearch from '../../Common/HeaderWithSearch/HeaderWithSearch';
import ConfirmPopup from '../../Common/ConfirmPopup/ConfirmPopup';
import PDFIcon from '../../../assets/icons/PDFIcon';
import CSVIcon from '../../../assets/icons/CSVIcon';
import XLSIcon from '../../../assets/icons/XLSIcon';
// import { send } from '../../WebSocket/WebSocket';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import Chip from '@mui/material/Chip';
import {Grid,Paper,Avatar,Typography,IconButton,MenuItem,Button} from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {BsTag} from 'react-icons/bs'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function ModeratorQM({ loading }) {
    const navigate = useNavigate();
    let { status } = useParams();
    const { state } = useLocation();
    const [questionStatData, setquestionStatData] = useState();
    const [questions, setQuestions] = useState();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    // const [name, setName] = useState(status);
    const [limit, setLimit] = useState('');
    const [offset, setOffset] = useState('');
    const [searchGroup, setSearchGroup] = useState('');
    const [checked, setChecked] = useState(false);
    const [selectedElements, setSelectedElements] = useState([]);
    const [deleteElement, setDeleteElement] = useState([]);
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [issueDetailId, setIssueDetailId] = useState();
    const [questionPagination, setQuestionPagination] = useState();

    const [resolvePopup,setResolvePopup] = useState(false)
    const [pendingPopup,setPendingPopup] = useState(false)
    const [discardPopup,setDiscardPopup] = useState(false)
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [openMessage, setOpenMessage] = useState(false);

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [openGetError, setOpenGetError] = useState(false);
    const [getErrorMessage, setGetErrorMessage] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [compLoading, setCompLoading] = useState(true);
    const location = useLocation();

    // var popElement = (id) => {
    //     setOpen(true);
    //     setIssueDetailId(id);
    // };

    const handleClose = () => {
        setOpenMessage(false);
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        questionStatFunction();
    }, []);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        QuestionDataFunction(
            searchGroup,
            status,
            fromDate,
            toDate,
            parsed.limit,
            parsed.offset
        );
    }, [location, fromDate, toDate]);

    useEffect(() => {
        if (searchGroup === '') {
            const parsed = queryString.parse(location.search);
            QuestionDataFunction(
                searchGroup,
                status,
                fromDate,
                toDate,
                parsed.limit,
                parsed.offset
            );
        }
    }, [searchGroup]);

    const refreshReport = () => {
        questionStatFunction();
        QuestionDataFunction(searchGroup, status, fromDate, toDate, limit,offset);
    };

    const questionStatFunction = () => {
        try {
            // Api.getIssueStatTile().then((response) => {
            //     setquestionStatData(response?.data.body);
            // });
            setquestionStatData({
            "active": 1,
            "deleted": 0
            })
        } catch (error) {
            setGetErrorMessage(error.response?.data?.detail);
            setOpenGetError(true);
        }
    };

    const openEditPopup = (question) => {
        setEditOpen(true)
    }

    const QuestionDataFunction = (
        searchGroup,
        status,
        fromDate,
        toDate,
        limit,
        offset
    ) => {
        setSelectedElements([]);
        setCompLoading(true);
        const response = {data:{
            "count": 1,
            "next": null,
            "previous": null,
            "body": [
                {
                    "color":"blue",
                    "id": 1,
                    "profile": {
                        "user_id": 2,
                        "username": "deleted_1",
                        "full_name": "Deleted User",
                        "avatar": ""
                    },
                    "name": "John",
                    "email": "abc@abc.com",
                    "title": "About Message Feature",
                    "description": "Not working properly",
                    "status": "deleted",
                    "reported_on": "2022-02-10T03:48:18.664400Z"
                }
            ],
            "message": "ReportIssues list fetched successfully"
        }}
        setQuestions(response?.data.body)
        setCompLoading(false);
        setPageLoading(false);
        try {
            offset = offset ?    offset : 0;

            // Api.getIssueData(
            //     searchGroup,
            //     status,
            //     fromDate,
            //     toDate,
            //     limit,
            //     offset
            // )
            //     .then((response) => {
            //         setIssuePagination(response?.data);
            //         setIssueDataSource(
            //             response?.data.result?.map((data, index) => ({
            //                 no: Number(offset) + (index + 1),
            //                 ...data,
            //             }))
            //         );
            //     })
            //     .then((res) => {
            //         setPageLoading(false);
            //         setCompLoading(false);
            //     });
        } catch (error) {
            setGetErrorMessage(error.response?.data?.detail);
            setOpenGetError(true);
        }
    };

    const paginationData = (data) => {
        var parsed = queryString.parse(data);
        // setLimit(parsed.limit);
        // setOffset(parsed.offset);
        const offset = parsed.offset ? parsed.offset : 0;
        QuestionDataFunction(
            searchGroup,
            status,
            fromDate,
            toDate,
            parsed.limit,
            parsed.offset
        );
        navigate(`/issue/${status}?limit=${parsed.limit}&offset=${offset}`);
    };

    const handleCheck = (key) => {
        selectedElements.includes(key)
            ? setSelectedElements(
                  selectedElements.filter(function (item) {
                      return item !== key;
                  })
              )
            : setSelectedElements([...selectedElements, key]);
    };

    useEffect(() => {
        if (selectedElements?.length === questions?.length)
            setSelectAllChecked(true);
        else setSelectAllChecked(false);
    }, [selectedElements]);

    // const colorMap = {
    //     active: '#42DA93',
    //     // inactive: '#FFDB5B',
    //     deleted: '#FF5B5B',
    //     all: '#000000',
    // };

    // const statNameMap = {
    //     active: 'Approved Questions',
    //     // pending: 'Pending Questions',
    //     deleted: 'Deleted Questions',
    //     // all: 'Total Questions',
    // };

    const tileClickHandler = (status) => {
        navigate(`/issue/${status}?limit=${''}&offset=${''}`);
        QuestionDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
        setSelectedElements([]);
    };

    // const startDate = (date, dateString) => {
    //     setFromDate(dateString);
    // };

    // const endDate = (date, dateString) => {
    //     setToDate(dateString);
    // };

    const search = (event) => {
        setSearchGroup(event.target.value);
    };

    const keyPress = (event) => {
        if (event.key === 'Enter') {
            const parsed = queryString.parse(location.search);
            QuestionDataFunction(
                searchGroup,
                status,
                fromDate,
                toDate,
                parsed.limit,
                parsed.offset
            );
        }
    };

    const download = ({ type, all }) => {
        // send({
        //     ids: all ? 'all' : selectedElements[0]?selectedElements:'all',
        //     module: 'download_issues',
        //     download_type: type,
        // });
        setSelectedElements([]);
    };

    function disabledStartDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrAfter(toDate)
        );
    }
    function disabledEndDate(current) {
        return (
            (current && current.valueOf() > Date.now()) ||
            current.isSameOrBefore(fromDate)
        );
    }

    const changeIssueStatus = (status_name) =>{
        var bodyFormData = {ids:[]}
        for (const val of selectedElements) {
            bodyFormData.ids.push(val);
        }
        bodyFormData["status"] = status_name
        // Api.editIssueStatus(bodyFormData).then((response) => {
        //     if (response?.status === 200) {
        //         questionStatFunction();
        //         QuestionDataFunction(searchGroup, status, fromDate, toDate);
        //         setSelectedElements([]);
        //         setMessage(`Issues ${status_name}`);
        //         setSeverity('success');
        //         setOpenMessage(true);
        //     }
        // });
    }

    const deleteIssue = () =>{

        var bodyFormData = {ids:[]}
        for (const val of selectedElements) {
            bodyFormData.ids.push(val);
        }
        // Api.deleteIssue(bodyFormData).then((response) => {
        //     if (response?.status === 204) {
        //         questionStatFunction();
        //         QuestionDataFunction(searchGroup, status, fromDate, toDate);
        //         setSelectedElements([]);
        //         setMessage(`Issues Discarded`);
        //         setSeverity('success');
        //         setOpenMessage(true);
        //     }
        //     else if(response?.status === 404){
        //         questionStatFunction();
        //         QuestionDataFunction(searchGroup, status, fromDate, toDate);
        //         setSelectedElements([]);
        //         setMessage(`IDs not found`);
        //         setSeverity('error');
        //         setOpenMessage(true);
        //     }
        // });
    }

    const handleStatusChange = (event) => {
        let temp = event.target.value
        QuestionDataFunction({status:temp,offset,limit});
        navigate(`/moderator-questions-management/${event.target.value}`)
      }

    const handleCategoryChange = (event) => {
        let temp = event.target.value
        QuestionDataFunction();
        navigate(`/moderator-questions-management/${status}/?category=${event.target.value}`)
      }

    return loading || pageLoading ? (
        <Spinner />
    ) : (
        <div className="QM w-full">
            <HeaderWithSearch
                name="Question Management"
                handleChange={search}
                keyPress={keyPress}
            />
            {/* <StatTile
                statData={questionStatData}
                statNameMap={statNameMap}
                colorMap={colorMap}
                onClick={tileClickHandler}
            /> */}

      { questions && questions[0].length ===0  ? (
        <h2
          className="text-center mt-160"
          style={{ color: 'grey' }}
        >
          No data available
        </h2>
      ) : (
        <div className="customTable-div">
            <div style={{float:'left'}}>
                <Button variant="contained" onClick={() => setAddOpen(true)}>
                    Create New Question &nbsp; <AddIcon />
                </Button>
            
                <TextField
                    label="Status"
                    onChange={handleStatusChange}
                    value={status}
                    defaultValue={status}
                    select 
                    variant="standard"
                    className="select-status"
                >
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'waiting'}>Waiting</MenuItem>
                    <MenuItem value={'deleted'}>Deleted</MenuItem>
                </TextField>

                <TextField
                    label="Category"
                    onChange={handleCategoryChange}
                    value={status}
                    defaultValue={status}
                    select 
                    variant="standard"
                    className="select-status"
                >
                    <MenuItem value={'active'}>History</MenuItem>
                    <MenuItem value={'education'}>Education</MenuItem>
                    <MenuItem value={'sciecne'}>Science</MenuItem>
                    <MenuItem value={'politics'}>Politics</MenuItem>
                </TextField>
            </div>
          <Pagination
            page={questionPagination}
            onChange={paginationData}
          />
          <Grid container spacing={4}>
            { questions?.map((question, id) => (
                <>
              <Grid
                /* className="ml-30 mt-30" */ key={id}
                item
                sm={12}
                xs={12}
                lg={3}
                md={6}
                className={"main-grid"}
              >
                   <div class="wrapper">
                    <div class="q-card question">
                        <div
                            className="cursor-pointer customizeDropdown"
                            style={{ float: "right" }}
                        >
                            <IconButton style={{ color: "white" }} className = "menuIcon">
                            <MoreVertIcon style={{ color: "Black" , marginTop:20}} />
                            </IconButton>
                            <div className="dropdownContent b-6">
                            <button
                                className="flex justify-between items-center text-14 w-full"
                                onClick={() => openEditPopup(question)}
                            >
                                Edit
                            </button>
                            
                            <button
                                style={{color:"red"}}
                                className="flex justify-between items-center text-14 w-full"
                                //   onClick={() => changeStatus("Inactive",question.id)}
                                >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="q-card__unit-name">Question straight from the history books down to the science&nbsp;<Chip variant="outlined"  label="History" className="mainChip" icon={<BsTag style={{color:"#222"}} />} /> </div>

                    <div className='other_tags'>Other Associated Tags</div>
                    <div class="q-card__level ">
                        <Chip label="Education" className="chips" /> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                    </div>
                    
                    <div className='other_tags'>Posted by</div>
                    <div class="q-card__unit-description flex">
                        <Avatar >M</Avatar><span className= 'data'> Moderator</span>
                    </div>

                    <div class="q-card__unit-stats q-card__unit-stats--question clearfix">
                        <div class="one-third">
                        <div class="stat">25</div>
                        <div class="stat-value"><FavoriteIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">24</div>
                        <div class="stat-value "><ArrowUpwardIcon fontSize="medium" /></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">300</div>
                        <div class="stat-value"  ><ArrowDownwardIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third no-border">
                        <div class="stat">411</div>
                        <div class="stat-value"  ><QuestionAnswerIcon fontSize="medium"/></div>
                        </div>

                    </div>

                    </div> 
                </div> 
              </Grid>
              <Grid
                /* className="ml-30 mt-30" */ key={id}
                item
                sm={12}
                xs={12}
                lg={3}
                md={6}
                className={"main-grid"}
              >
                   <div class="wrapper">
                    <div class="q-card question">
                        <div
                            className="cursor-pointer customizeDropdown"
                            style={{ float: "right" }}
                        >
                            <IconButton style={{ color: "white" }} className = "menuIcon">
                            <MoreVertIcon style={{ color: "Black" , marginTop:20,zIndex:2}} />
                            </IconButton>
                            <div className="dropdownContent b-6">
                            <button
                                className="flex justify-between items-center text-14 w-full"
                                onClick={() => openEditPopup(question)}
                            >
                                Edit
                            </button>
                            
                            <button
                                style={{color:"red"}}
                                className="flex justify-between items-center text-14 w-full"
                                //   onClick={() => changeStatus("Inactive",question.id)}
                                >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="q-card__unit-name">Question straight from the history books down to the science&nbsp;<Chip label="History" className="mainChip" icon={<BsTag style={{color:"#222"}} />} /> </div>

                    <div className='other_tags'>Other Associated Tags</div>
                    <div class="q-card__level ">
                        <Chip label="Education" className="chips" /> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                    </div>
                    
                    <div className='other_tags'>Posted by</div>
                    <div class="q-card__unit-description flex">
                        <Avatar >M</Avatar><span className= 'data'> Moderator</span>
                    </div>

                    <div class="q-card__unit-stats q-card__unit-stats--question clearfix">
                        <div class="one-third">
                        <div class="stat">25</div>
                        <div class="stat-value"><FavoriteIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">24</div>
                        <div class="stat-value "><ArrowUpwardIcon fontSize="medium" /></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">300</div>
                        <div class="stat-value"  ><ArrowDownwardIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third no-border">
                        <div class="stat">411</div>
                        <div class="stat-value"  ><QuestionAnswerIcon fontSize="medium"/></div>
                        </div>

                    </div>

                    </div> 
                </div> 
              </Grid>
              <Grid
                /* className="ml-30 mt-30" */ key={id}
                item
                sm={12}
                xs={12}
                lg={3}
                md={6}
                className={"main-grid"}
              >
                   <div class="wrapper">
                    <div class="q-card question">
                        <div
                            className="cursor-pointer customizeDropdown"
                            style={{ float: "right" }}
                        >
                            <IconButton style={{ color: "white" }} className = "menuIcon">
                            <MoreVertIcon style={{ color: "Black" , marginTop:20}} />
                            </IconButton>
                            <div className="dropdownContent b-6">
                            <button
                                className="flex justify-between items-center text-14 w-full"
                                onClick={() => openEditPopup(question)}
                            >
                                Edit
                            </button>
                            
                            <button
                                style={{color:"red"}}
                                className="flex justify-between items-center text-14 w-full"
                                //   onClick={() => changeStatus("Inactive",question.id)}
                                >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="q-card__unit-name">Question straight from the history books down to the science&nbsp;<Chip label="History" className="mainChip" icon={<BsTag style={{color:"#222"}} />} /> </div>

                    <div className='other_tags'>Other Associated Tags</div>
                    <div class="q-card__level ">
                        <Chip label="Education" className="chips" /> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                    </div>
                    
                    <div className='other_tags'>Posted by</div>
                    <div class="q-card__unit-description flex">
                        <Avatar >M</Avatar><span className= 'data'> Moderator</span>
                    </div>

                    <div class="q-card__unit-stats q-card__unit-stats--question clearfix">
                        <div class="one-third">
                        <div class="stat">25</div>
                        <div class="stat-value"><FavoriteIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">24</div>
                        <div class="stat-value "><ArrowUpwardIcon fontSize="medium" /></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">300</div>
                        <div class="stat-value"  ><ArrowDownwardIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third no-border">
                        <div class="stat">411</div>
                        <div class="stat-value"  ><QuestionAnswerIcon fontSize="medium"/></div>
                        </div>

                    </div>

                    </div> 
                </div> 
              </Grid>
              <Grid
                /* className="ml-30 mt-30" */ key={id}
                item
                sm={12}
                xs={12}
                lg={3}
                md={6}
                className={"main-grid"}
              >
                   <div class="wrapper">
                    <div class="q-card question">
                        <div
                            className="cursor-pointer customizeDropdown"
                            style={{ float: "right" }}
                        >
                            <IconButton style={{ color: "white" }} className = "menuIcon">
                            <MoreVertIcon style={{ color: "Black" , marginTop:20}} />
                            </IconButton>
                            <div className="dropdownContent b-6">
                            <button
                                className="flex justify-between items-center text-14 w-full"
                                onClick={() => openEditPopup(question)}
                            >
                                Edit
                            </button>
                            
                            <button
                                style={{color:"red"}}
                                className="flex justify-between items-center text-14 w-full"
                                //   onClick={() => changeStatus("Inactive",question.id)}
                                >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="q-card__unit-name">Question straight from the history books down to the science&nbsp;<Chip label="History" className="mainChip" icon={<BsTag style={{color:"#222"}} />} /> </div>

                    <div className='other_tags'>Other Associated Tags</div>
                    <div class="q-card__level ">
                        <Chip label="Education" className="chips" /> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                        <Chip label="Education" className="chips"/> 
                    </div>
                    
                    <div className='other_tags'>Posted by</div>
                    <div class="q-card__unit-description flex">
                        <Avatar >M</Avatar><span className= 'data'> Moderator</span>
                    </div>

                    <div class="q-card__unit-stats q-card__unit-stats--question clearfix">
                        <div class="one-third">
                        <div class="stat">25</div>
                        <div class="stat-value"><FavoriteIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">24</div>
                        <div class="stat-value "><ArrowUpwardIcon fontSize="medium" /></div>
                        </div>

                        <div class="one-third">
                        <div class="stat">300</div>
                        <div class="stat-value"  ><ArrowDownwardIcon fontSize="medium"/></div>
                        </div>

                        <div class="one-third no-border">
                        <div class="stat">411</div>
                        <div class="stat-value"  ><QuestionAnswerIcon fontSize="medium"/></div>
                        </div>

                    </div>

                    </div> 
                </div> 
              </Grid>
            </>
            ))}
          </Grid>
        </div>
      )}

      {/* <ConfirmPopup
        open={todeletePromo}
        setOpen={settodeletePromo}
        name="Delete"
        page="category"
        action="Delete"
        handleChange={() => handleDelete()}
      />

      <ConfirmPopup
        open={toggelpromo}
        setOpen={settoggelpromo}
        name={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        page="category"
        action={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        handleChange={() => handleToggle()}
      /> */}
      <Snackbar 
          open={openMessage}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
      >
          <Alert onClose={handleClose} severity={severity}>
              {message}
          </Alert>
      </Snackbar>

            {/* <ConfirmPopup
                open={pendingPopup}
                setOpen={setPendingPopup}
                name="Set Issue Pending"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => changeIssueStatus("pending")}
            />
            <ConfirmPopup
                open={resolvePopup}
                setOpen={setResolvePopup}
                name="Resolve Issue"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => changeIssueStatus("resolved")}
            />
            <ConfirmPopup
                open={discardPopup}
                setOpen={setDiscardPopup}
                name="Discard Issue"
                page=""
                num={selectedElements.length}
                action="close"
                handleChange={() => deleteIssue()}
            /> */}
            <EditQuestionPopup open={editOpen} setOpen={setEditOpen} />
            <AddQuestionPopup open={addOpen} setOpen={setAddOpen} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
});

export default connect(mapStateToProps)(ModeratorQM);
