import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Box , Typography} from '@mui/material';
import Button from "../Button/Button"

const Crop =({setFileData,aspect })=>{
  const [upImg, setUpImg] = useState();
  const [filename, setfilename]= useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: aspect });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [open, setOpen] = useState(false);


  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob((theBlob)=>{
        const file= new File([theBlob], filename, { lastModified: new Date().getTime(), type: theBlob.type })
        setFileData(file);
    }, 'image/png' , 1);

    setOpen(false);
  }

  const onSelectFile = (e) => {

    if (e.target.files && e.target.files.length > 0) {
      setfilename(e.target.files[0].name.substring(0,e.target.files[0].name.lastIndexOf(".")));
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpen(true);
    }
  };

  const handleClose= ()=>{
    document.querySelector('input[name="crop-in"]').value='';
    setOpen(false);
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // maxWidth:600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

  return (
    <div>
      <div>
        <input type="file" accept="image/*" name="crop-in" onChange={onSelectFile} style={{width:"100%"}} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <Box  sx={style}>
          <Box>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />

            <div hidden>
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0)
                }}
              />
            </div>

          </Box>
          <div className="flex justify-start mt-30">
            <Button
              profilePage
              blackOutline
              onClick={handleClose}
              style={{ marginRight: '20px' }}
            >
              Cancel
            </Button>
            <Button
              profilePage
              blackOutline
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() =>
                generateDownload(previewCanvasRef.current, completedCrop)
              }
            >
              Crop
            </Button>
          </div>
        </Box>


      </Modal>


    </div>
  );
}


export default Crop;
