import assets from '../../../assets';

export const icons = [
    {
        icon: assets.icons.ChartLine,
        text: 'Analytics',
    },
    {
        icon: assets.icons.UserCircle,
        text: 'Users',
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Reported Issues',
    },
    {
        icon: assets.icons.BiCategoryAlt,
        text: 'Category',
    },
    {
        icon: assets.icons.UserCircleGear,
        text: 'Admins',
    },
    {
        icon: assets.icons.BsQuestionOctagon,
        text: 'Questions',
    },

    {
        icon: assets.icons.WarningOctagon,
        text: 'Spammed Questions',
    },{
        icon: assets.icons.WarningOctagon,
        text: 'Spam Answers',
      
    },
    {
        icon: assets.icons.WarningOctagon,
        text: 'Spam Replies',
       
    },
    {
        icon: assets.icons.AiOutlineSetting,
        text: 'Settings',
       
    },


];
