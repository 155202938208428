import React, { useState } from 'react';
import PasswordEye from '../../../assets/icons/PasswordEye';
import '../../../assets/stylesheets/common.scss';
import './FormInput.scss';
import { IconButton } from '@mui/material';

const FormInput = ({
    handleChange,
    label,
    letter_spacing,
    type,
    accountPage,
    message,
    ...otherProps
}) => {
    const [togglePassword, setTogglePassword] = useState(false);

    return (
        <div
            className={`formInput ${type === 'password' && 'relative'} ${
                accountPage && 'accountPageInput'
            }`}
        >
            <label>{label}</label>
            <input
                className={`${
                    letter_spacing && 'letterSpacing'
                } w-full b-6 text-14`}
                onChange={handleChange}
                type={type === 'password' && togglePassword ? 'text' : type}
                style={{ borderColor: `${message && 'red'}` }}
                {...otherProps}
            />
            <p className="text-14">{message}</p>
            {type === 'password' && (
                <div className="eye absolute">
                    <IconButton
                        onClick={() => setTogglePassword(!togglePassword)}
                    >
                        <PasswordEye />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default FormInput;
