import axios from 'axios'
import baseURL from './redux/Utils/baseURL'
import './redux/Utils/intercepter'

var fetchRequests = {
  secured: (endpoints, method, data,contentType) => {
    return axios
      .request({
        url: baseURL + endpoints,
        method,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": contentType?contentType:"application/json",
        },
      })
      .catch((err) => {
        
        return Promise.reject(err)
      });
  },
  delete: (endpoints,data) => { //SUCCESS AS 202
    return axios.delete(baseURL + endpoints,
      {
        headers:{
          Authorization: `Bearer ${localStorage.getItem("token")}` 
        },
        data: data
      }
    )
    .catch(err => {
      return Promise.reject(err)
    })
  },
  wToken: (endpoints, method, data,contentType) => {
    return axios
      .request({
        url: baseURL + endpoints,
        method,
        data,
        headers: {
          "Content-Type": contentType?contentType:"application/json",
        },
      })
      .catch((err) => {
        
        return Promise.reject(err)
      });
  },
}

export default fetchRequests;
