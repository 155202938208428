import fetchRequests from "./ApiRequests";

const Api = {
  //USER MODULE
  getUserList: (params, data) => {
    return fetchRequests.secured(
      `/api/admindashboard/user/?status=${params.status}&search=${params.search}&from_date=${params.from_date}&to_date=${params.to_date}&limit=${params.limit}&offset=${params.offset}`,
      'POST',
      data
    );
  },
  getUserDetails: (id) => {
    return fetchRequests.secured(`/api/admindashboard/user/${id}/`);
  },
  getUserCount: () => {
    // console.log("jeee")
    return fetchRequests.secured("/api/admindashboard/user/count/");
  },
  deleteUsers: (data) => {
    return fetchRequests.delete(`/api/admindashboard/user/delete/`, data);
  },
  changeUserStatus: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/user/edit/`,
      "PATCH",
      data
    );
  },

  //ISSUE MODULE
  getIssueStatTile: () => {
    return fetchRequests.secured("/api/admindashboard/report-issue/count/");
  },
  getIssueData: (searchGroup, status, fromDate, toDate, limit, offset) => {
    return fetchRequests.secured(
      `/api/admindashboard/report-issue/?status=${status}&search=${searchGroup}&from_date=${fromDate}&to_date=${toDate}&limit=${limit}&offset=${offset}`
    );
  },
  editIssueStatus: (data) => {
    console.log({...data})
    return fetchRequests.secured(
      `/api/admindashboard/report-issue/edit/`,
      "PATCH",
      data
    );
  },
  deleteIssue: (data) => {
    return fetchRequests.delete(
      `/api/admindashboard/report-issue/delete/`,
      data
    );
  },

  //CATEGORY MANAGEMENT
  getCategoryList: (params) => {
    return fetchRequests.secured(
      `/api/admindashboard/category/?status=${params.status}&offset=${
        params.offset
      }&limit=${20}`
    );
  },
  getCategoryStatTile: () => {
    return fetchRequests.secured(`/api/admindashboard/category/count/`);
  },
  createCategory: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/category/create/`,
      "POST",
      data,
      "multipart/form-data"
    );
  },
  categoryStatusChange: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/category/status-toggle/`,
      "PATCH",
      data
    );
  },
  updateCategory: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/category/edit/`,
      "PATCH",
      data,
      "multipart/form-data"
    );
  },
  updatePassword: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/password-change/`,
      "PATCH",
      data,
      "multipart/form-data"
    );
  },
  sendEmail:(data)=>{
    return fetchRequests.wToken(
      `/api/admindashboard/admin/password-reset-mail-send/`,
      "POST",
      data,
    );
  },
  resetPassword:(data)=>{
    return fetchRequests.wToken(
      '/api/admindashboard/admin/password-reset-update/',
      "PATCH",
      data,
      "multipart/form-data"

    )
  },

  //Questions Module
  getQuestions: (params) => {
    console.log( `/api/admindashboard/question/?search=${
      params.searchField ?? ""
    }&from_date=${params.fromDate ?? ""}&to_date=${
      params.toDate ?? ""
    }&status=${params.status ?? ""}&category_id=${
      params.categoryID ?? ""
    }&limit=${params.limit}&offset=${params.offset}`)
    return fetchRequests.secured(
      `/api/admindashboard/question/?search=${
        params.searchField ?? ""
      }&from_date=${params.fromDate ?? ""}&to_date=${
        params.toDate ?? ""
      }&status=${params.status ?? ""}&category_id=${
        params.categoryID ?? ""
      }&limit=${params.limit}&offset=${params.offset}`
    );
  },
  getQuestionsStatTile: () => {
    return fetchRequests.secured(`/api/admindashboard/question/count/`);
  },

  // Spam-Questions Module
  getSpamQuestions: (params) => {
    console.log("params",params,`/api/admindashboard/spammed-questions/?search=${
      params.searchField ?? ""
    }&from_date=${params.fromDate ?? ""}&to_date=${
      params.toDate ?? ""
    }&status=${params.status ?? ""}&category_id=${
      params.categoryID ?? ""
    }&limit=${params.limit??""}&offset=${params.offset??""}`)
    return fetchRequests.secured(`/api/admindashboard/spammed-questions/?search=${
      params.searchField ?? ""
    }&from_date=${params.fromDate ?? ""}&to_date=${
      params.toDate ?? ""
    }&status=${params.status ?? ""}&category_id=${
      params.categoryID ?? ""
    }&limit=${params.limit??""}&offset=${params.offset??""}`);
  },

  closeSpamQuestion:(data) =>{
    // console.log(data)
    return fetchRequests.secured(`/api/admindashboard/spammed-questions/close/`,"PATCH",data)
  },
  deleteSpamQuestion:(data) =>{
    console.log(data)
    return fetchRequests.delete(`/api/admindashboard/spammed-questions/delete/`,data)
  },
  getSpamAnswers: (params) => {
    console.log(params)
    return fetchRequests.secured(`/api/admindashboard/spammed-answers/?search=${
      params.searchField ?? ""
    }&from_date=${params.fromDate ?? ""}&to_date=${
      params.toDate ?? ""
    }&status=${params.status ?? ""}&category_id=${
      params.categoryID ?? ""
    }&limit=${params.limit??""}&offset=${params.offset??""}`);
  },

  closeSpamAnswer:(data) =>{
    // console.log(data)
    return fetchRequests.secured(`/api/admindashboard/spammed-answers/close/`,"PATCH",data)
  },
  deleteSpamAnswer:(data) =>{
    console.log(data)
    return fetchRequests.delete(`/api/admindashboard/spammed-answers/delete/`,data)
  },
  getSpamReplies: (params) => {
    // console.log(params)
    return fetchRequests.secured(`/api/admindashboard/spammed-replies/?search=${
      params.searchField ?? ""
    }&from_date=${params.fromDate ?? ""}&to_date=${
      params.toDate ?? ""
    }&status=${params.status ?? ""}&category_id=${
      params.categoryID ?? ""
    }&limit=${params.limit}&offset=${params.offset}`);
  },

  closeSpamReply:(data) =>{
    // console.log(data)
    return fetchRequests.secured(`/api/admindashboard/spammed-replies/close/`,"PATCH",data)
  },
  deleteSpamReply:(data) =>{
    console.log(data)
    return fetchRequests.delete(`/api/admindashboard/spammed-replies/delete/`,data)
  },
  //Settings Module
  getSettings:()=>{
    return fetchRequests.secured(`/api/admindashboard/limit/`) 
  },
  updateSettings:(data)=>{
    console.log(data)
    return fetchRequests.secured(`/api/admindashboard/limit/update/`,"PATCH",data)
  },
  //Admin Module
  getAdmins: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/?search=${data.searchField}&status=${data.status}&from_date=&to_date=&limit=${data.limit}&offset=${data.offset}`
    );
  },
  getAdminsCount: () => {
    return fetchRequests.secured(`/api/admindashboard/admin/count/`);
  },
  getPermissions: () => {
    return fetchRequests.secured(
      `/api/admindashboard/dashboard_permissions/all/`
    );
  },
  getAdminsDetails: (id) => {
    return fetchRequests.secured(`/api/admindashboard/admin/${id}`);
  },
  getMyAdminsDetails: (id) => {
    return fetchRequests.secured(`/api/admindashboard/admin/my/`);
  },
  getUserPermissions: (id) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/${id}/dashboard_permissions`
    );
  },
  changeStatusAdmins: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/status/edit/`,
      "PATCH",
      data
    );
  },
  createAdmin: (data) => {
    console.log(data)
    return fetchRequests.secured(
      `/api/admindashboard/admin/create/`,
      "POST",
      data
    );
  },
  togglePermissions: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/dashboard_permissions/toggle/`,
      "POST",
      data
    );
  },
  updateAdminPassword: (data) => {
    return fetchRequests.secured(
      `/api/admindashboard/admin/password-edit`,
      "PATCH",
      data
    );
  },
  deleteAdmins: (data) => {
    return fetchRequests.delete(`/api/admindashboard/admin/delete/`, data);
  },

  //ANALYTICS
  getUserGraph: (params) => {
    return fetchRequests.secured(
      `/api/admindashboard/analytics/user-graph/?type=${params.type}&from_date=${params.start_date}&to_date=${params.end_date}`
    );
  },
  getQuestionGraph: (params) => {
    return fetchRequests.secured(
      `/api/admindashboard/analytics/question-graph/?type=${params.type}&from_date=${params.start_date}&to_date=${params.end_date}`
    );
  },
  getDashboardCounters: (params) => {
    return fetchRequests.secured(`/api/admindashboard/analytics/counter/`);
  },
};

export default Api;
