import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Api from "../../../Api";
import Spinner from "../../Common/Spinner/Spinner";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
// import PopupForm from "./PopupForm";
import { connect, useDispatch } from "react-redux";
import queryString from "query-string";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, TextField } from "@mui/material";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import Header from "../../Common/Header/Header";
import "./CategoryManagement.scss";
import Pagination from "../../Common/Pagination/Pagination";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import AddCategoryPopup from "./AddCategory";
import EditCategoryPopup from "./EditCategory";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { useParams, useNavigate } from "react-router-dom";
import { CategoryActors } from "../../../redux/Actors/CategoryActors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CategoryManagement = ({ CategoryList, InactiveList ,permissionIds}) => {
  const [categories, setCategories] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState();
  const permission = permissionIds.permissionIds['category-management']
  console.log(permission)
  const [recordCount, setRecordCount] = useState(0);
  const [clearSearch, setClearSearch] = useState(false);
  const [compLoading, setCompLoading] = useState(false);

  // const [searchphrase, setsearchphrase] = useState("");
  const [limit, setLimit] = useState("");
  const [offset, setOffset] = useState("");

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  const [categoryPagination, setCategoryPagination] = useState();
  const [type, setType] = useState("All");
  const { status } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchcount, setsearchout] = useState(0);

  const [optionData, setoptionData] = useState([]);
  // console.log(InactiveList);
  const getStatus = (stat) => {
    if (stat === "active") return "Active";
    else if (stat === "inactive") return "Inactive";
  };

  useEffect(() => {
    CategoryDataFunction({ status: status, offset: "", limit: "" });
  }, [CategoryList.isLoaded, InactiveList.isLoaded]);

  const paginationData = (data) => {
    var start = data.indexOf("?");
    var parsed = queryString.parse(data.substr(start));
    console.log(parsed)
    const offset = parsed.offset ? parsed.offset : 0;
    CategoryDataFunction({status,offset, limit:parsed.limit});
    navigate(
      `/category-management/${status}?limit=${parsed.limit}&offset=${offset}`
    );
  };
  const handleClose = () => {
    setOpenMessage(false);
  };

  const CategoryDataFunction = async ({ status, offset, limit }) => {
    let temp = getStatus(status);
    console.log({ temp, offset, limit });
    const apiCall = async () => {
      setCompLoading(false);
      const res = await Api.getCategoryList({ status: temp, offset, limit });
      if (limit === "" && offset === "") {
      //  console.log("dispatching")
        if (temp === "Active")
          dispatch({
            type: "CATEGORY_LIST",
            payload: { data: res?.data, isLoaded: true },
          });
        else
          dispatch({
            type: CategoryActors.INACTIVE_LIST,
            payload: { data: res?.data, isLoaded: true },
          });
      }
      setCategoryPagination(res?.data);
      setCategories([res.data?.body]);
      setCompLoading(true);
    };
    // console.log(
    //   !CategoryList.isLoaded || temp !== "Active",
    //   CategoryList,InactiveList,
    //   temp
    // );
    if (
      (temp === "Active" && !CategoryList.isLoaded) ||
      (temp === "Inactive" && !InactiveList.isLoaded) ||
      limit !== "" ||
      offset !== ""
    )
      apiCall();
    else {
      if (temp === "Active") {
        setCategoryPagination(CategoryList.data);
        setCategories([CategoryList.data.body]);
      } else {
        setCategoryPagination(InactiveList.data);
        setCategories([InactiveList.data.body]);
      }
      setCompLoading(true);
    }
  };

  const handleStatusChange = (event) => {
    let temp = event.target.value;
    CategoryDataFunction({ status: temp, offset, limit });
    navigate(`/category-management/${event.target.value}`);
  };

  const openEditPopup = (data) => {
    setEditData(data);
    setEditOpen(true);
  };

  const changeStatus = (stat, categoryID) => {
    let data = { id: categoryID, status: stat };
    Api.categoryStatusChange(data).then((res) => {
      if (res?.status === 200) {
        dispatch({ type: "TOGGLE_CATEGORY" });
        dispatch({type:CategoryActors.TOGGLE_INACTIVE_LIST})
        setMessage(
          stat === "Active" ? "Category Activated" : "Category Deactivated"
        );
        setSeverity("success");
        setOpenMessage(true);
        CategoryDataFunction({ status, offset: "", limit: "" });
      }
    });
  };

  // useEffect(() => {
  //   if(clearSearch === true){
  //     setClearSearch(false)
  //     setsearchphrase('')
  //   }
  //   if(searchphrase === ''){
  //     CategoryDataFunction()
  //   }
  // },[clearSearch])

  if (!compLoading) {
    return <Spinner />;
  }
  return (
    <div className="CM">
      <Header name="Category Management" />

      <div style={{ float: "left" }} className="topbar">
        {
          <Button
            variant="contained"
            style={{ backgroundColor: "#3abd98" }}
            onClick={() => {if(permissionIds.userPermissions[permission]?.is_enabled===true)setAddOpen(true)}}
          >
            <AddIcon />
          </Button>
        }
        <TextField
          label="Status"
          onChange={handleStatusChange}
          value={status}
          defaultValue={status}
          select
          variant="standard"
          className="select-status"
        >
          <MenuItem value={"active"}>Active</MenuItem>
          <MenuItem value={"inactive"}>Inactive</MenuItem>
        </TextField>
      </div>
      {categories && categories[0].length === 0 ? (
        <h2 className="text-center mt-160" style={{ color: "grey" }}>
          No data available
        </h2>
      ) : (
        <div className="customTable-div">
          <Pagination page={categoryPagination} onChange={paginationData} />
          <Grid container spacing={4}>
            {categories[0].map((category, id) => (
              <Grid
                /* className="ml-30 mt-30" */ key={id}
                item
                sm={12}
                xs={12}
                lg={4}
                md={6}
                className={"main-grid"}
              >
                <Paper
                  className="card-category"
                  style={{
                    borderTop: `10px solid ${category.color}`,
                  }}
                >
                  <div className="divider">
                    <div
                      className="cursor-pointer customizeDropdown"
                      style={{ float: "right" }}
                    >
                      <IconButton style={{ color: "#222" }}>
                        <MoreVertIcon style={{ color: "#222" }} />
                      </IconButton>
                      <div className="dropdownContent b-6">
                        {
                          <button
                            className="flex justify-between items-center text-14 w-full"
                            onClick={() => {if(permissionIds.userPermissions[permission]?.is_enabled===true)openEditPopup(category)}}
                          >
                            Edit
                          </button>
                        }
                        {status === "active" ? (
                          <button
                            style={{ color: "red" }}
                            className="flex justify-between items-center text-14 w-full"
                            onClick={() =>
                              changeStatus("Inactive", category.id)
                            }
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            style={{ color: "#009933" }}
                            className="flex justify-between items-center text-14 w-full"
                            onClick={() => changeStatus("Active", category.id)}
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </div>
                    <h4
                      className="mb-15 flex "
                      style={{ color: "#222", wordBreak: "break-word" }}
                    >
                      {category.category_svg ? (
                        <>
                          <Avatar
                            src={category.category_svg}
                            sx={{ width: 40, height: 40 }}
                          />{" "}
                          <span className="ml-20 mt-4 text-20">
                            {category.name}
                          </span>
                        </>
                      ) : (
                        <>
                          <Avatar sx={{ width: 40, height: 40 }}>
                            {category.name.slice(0, 1).toUpperCase()}
                          </Avatar>
                          <span className="ml-20 mt-4 text-20">
                            {category.name}
                            {/* <div
                            className="flex justify-between"
                            style={{ float: "right",marginTop:"-7px",marginLeft:10 }}
                          >
                            {category.status === "Active" && (
                              <button
                                className="normalButton py-6 height-30 my-8 "
                                style={{
                                  color: "#42da93",
                                  border: "1px solid #42da93",
                                }}
                              >
                                {category.status}
                              </button>
                            )}
                            {category.status === "Inactive" && (
                              <button
                                className="normalButton py-6 height-30 my-8"
                                style={{
                                  color: "#FF5B5B",
                                  border: "1px solid #FF5B5B",
                                }}
                              >
                                {category.status}
                              </button>
                            )}
                          </div> */}
                          </span>
                        </>
                      )}
                    </h4>
                    <Typography style={{ color: "#222" }}>
                      {category.details}
                    </Typography>
                  </div>
                  <div className="flex justify-between mt-30">
                    <div
                      className="flex justify-between w-full"
                      style={{ color: "#222" }}
                    >
                      <span>Total Questions: {category.total_questions}</span>
                      <span>Total Answers: {category.total_answers}</span>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <AddCategoryPopup
        open={addOpen}
        setOpen={() => setAddOpen(!addOpen)}
        CategoryDataFunction={CategoryDataFunction}
        status={status}
      />
      <EditCategoryPopup
        open={editOpen}
        setOpen={() => setEditOpen(!editOpen)}
        editData={editData}
        CategoryDataFunction={CategoryDataFunction}
        status={status}
      />

      {/* <ConfirmPopup
        open={todeletePromo}
        setOpen={settodeletePromo}
        name="Delete"
        page="category"
        action="Delete"
        handleChange={() => handleDelete()}
      />

      <ConfirmPopup
        open={toggelpromo}
        setOpen={settoggelpromo}
        name={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        page="category"
        action={toggelval !== "ACTIVE" ? "INACTIVE": "ACTIVE"}
        handleChange={() => handleToggle()}
      /> */}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // infoPopup: state.userList.infoPopup,
  CategoryList: state.category.CategoryList,
  InactiveList: state.category.InactiveCategoryList,
});

export default connect(mapStateToProps)(CategoryManagement);
