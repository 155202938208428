import { AdminsActors } from "../Actors/Admin";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    AdminsList:{res:{},res2:{},res3:{},isLoaded:false},
    ActiveAdminsList:{res:{},res2:{},res3:{},isLoaded:false},
    DeactiveAdminsList:{res:{},res2:{},res3:{},isLoaded:false},
    DeletedAdminsList:{res:{},res2:{},res3:{},isLoaded:false},

};

const AdminsListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminsActors.ADMINS_LIST:
            return {
                ...state,
                AdminsList:action.payload
            }
        case AdminsActors.TOGGLE_ADMINS:
            console.log("Reached")
            return {
                ...state,
                AdminsList:{...state.AdminsList,isLoaded:false}
            }
            case AdminsActors.ACTIVE_ADMINS_LIST:
                return {
                    ...state,
                    ActiveAdminsList:action.payload
                }
            case AdminsActors.TOGGLE_ACTIVE_ADMINS:
                console.log("Reached")
                return {
                    ...state,
                    ActiveAdminsList:{...state.ActiveAdminsList,isLoaded:false}
                }
                case AdminsActors.DEACTIVATE_ADMINS_LIST:
                    return {
                        ...state,
                        DeactiveAdminsList:action.payload
                    }
                case AdminsActors.TOGGLE_DEACTIVATE_ADMINS:
                    console.log("Reached")
                    return {
                        ...state,
                    DeactiveAdminsList:{...state.DeactiveList,isLoaded:false}
                    }
                    case AdminsActors.DELETED_ADMINS_LIST:
                        return {
                            ...state,
                            DeletedAdminsList:action.payload
                        }
                    case AdminsActors.TOGGLE_DELETED_ADMINS:
                        console.log("Reached")
                        return {
                            ...state,
                            DeletedAdminsList:{...state.DeletedAdminsList,isLoaded:false}
                        }
        
        default:
            return state;
    }
};
export default AdminsListReducer