import React, { useState, useEffect } from 'react';
import './Pagination.scss';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useStyles = makeStyles({
    root: {
        border: '1px solid #434343',
        borderRadius: '100%',
        transform: 'scale(1.2)',
        color: '#434343',
        cursor: 'pointer',
    },
});

export default function Pagination(props) {
    const classes = useStyles();
    const location = useLocation();
    const { onChange, page } = props;
    const [count, setCount] = useState(1);

    const nextPage = () => {
        // setCount(count + 1);
        onChange(page?.next);
    };

    const previousPage = () => {
        // setCount(count - 1);
        onChange(page?.previous);
    };

    useEffect(() => {
        var parsed = queryString.parse(location.search);
        if (isNaN(parsed.offset)) {
            setCount(1);
        } else if (parsed.offset === '' && parsed.limit === '') {
            setCount(1);
        } else {
            setCount(parsed.offset / parsed.limit + 1);
        }
    }, [location.search]);
    return (
        <>
            <div className="text-12 table-pagination mt-10 mb-4">
                <div className="flex items-center h-full pagination-nav justify-end">
                    <div
                        className={
                            page?.previous == null ? 'disabled mr-10' : 'mr-10'
                        }
                        onClick={previousPage}
                    >
                        <ChevronLeftOutlinedIcon className={classes.root} />
                    </div>
                    <h3
                        className="text-16 text-center"
                        style={{ width: '3rem' }}
                    >{`${count}-${
                        Math.ceil(page?.count / 20)
                            ? Math.ceil(page?.count / 20)
                            : 1
                    }`}</h3>
                    <div
                        className={
                            page?.next == null ? 'disabled ml-10' : 'ml-10'
                        }
                        onClick={nextPage}
                    >
                        <ChevronRightOutlinedIcon className={classes.root} />
                    </div>
                </div>
            </div>
        </>
    );
}
