import axios from 'axios'
import { setApiError,permissionsError } from '../Actions/Auth'
import store from '../store'
import baseURL from './baseURL'

const instance  = axios.create({
baseURL: baseURL,
  timeout: 10000,

})


const destroyToken = () => {
    localStorage.clear();
    window.location.href = '/';
}

axios.interceptors.response.use(
    response => response,
    error => {
        var originalRequest = error.config;
        const refresh_token = localStorage.getItem('refresh_token');
        console.log("Error",error.response.data)
        if(error.response.status === 403 ){
            store.dispatch(permissionsError(error.response.data.message.detail))
            store.dispatch(setApiError(error.response.data.message.detail));
            return 
            }

        if (error.response.status === 401 ){

            if(error?.response?.data?.message?.detail === "Given token not valid for any token type" && originalRequest?._retry){
                destroyToken()
            }
        }

        if ((error.response.status === 401 ) && !originalRequest._retry){
            if(refresh_token?.length < 20){
                destroyToken()    
            }
            const url= `/api/admindashboard/admin/token/refresh/`
            
            originalRequest._retry = true
            return instance.post(url,{refresh:refresh_token}).then((result) => {
                localStorage.setItem('access_token', result.data.access);
                localStorage.setItem('refresh_token', result.data.refresh);
                originalRequest.headers['Authorization'] = "Bearer " + result.data.access;
                return axios(originalRequest)

            })
            .catch(err => {
                if(err?.response?.data?.message?.detail === "Token is invalid or expired" && originalRequest?._retry){
                    destroyToken()
                }
                if(err?.response?.data?.message?.detail === "Token is blacklisted" && !originalRequest?._retry){
                    destroyToken()
                }
                if(err?.response?.data?.message?.detail === 'Token is blacklisted' && originalRequest?._retry){
                    originalRequest._retry = true
                    originalRequest.headers['Authorization'] = "Bearer " + localStorage.getItem("access_token");
                    return axios(originalRequest)
                }
                else{
                    Promise.reject(err)   
                }
                
            });
        }

        return Promise.reject(error)

    }
)