import React from 'react';

const UserLogo = ({ width = '52', height = '52', color = '#FF5B5B' }) => {
    return (
        <div style={{ display: 'inline' }}>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M26 32.5C30.4873 32.5 34.125 28.8623 34.125 24.375C34.125 19.8877 30.4873 16.25 26 16.25C21.5127 16.25 17.875 19.8877 17.875 24.375C17.875 28.8623 21.5127 32.5 26 32.5Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M12.959 40.4979C14.183 38.0913 16.0492 36.0703 18.3508 34.6587C20.6524 33.2471 23.2998 32.5 25.9998 32.5C28.6998 32.5 31.3471 33.2471 33.6488 34.6587C35.9504 36.0703 37.8165 38.0912 39.0406 40.4978"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.625 14.625C42.4199 14.625 43.875 13.1699 43.875 11.375C43.875 9.58007 42.4199 8.125 40.625 8.125C38.8301 8.125 37.375 9.58007 37.375 11.375C37.375 13.1699 38.8301 14.625 40.625 14.625Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.625 8.125V5.6875"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.8102 9.75L35.6992 8.53125"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.8102 13L35.6992 14.2188"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.625 14.625V17.0625"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.4395 13L45.5504 14.2188"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.4395 9.75L45.5504 8.53125"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.3615 23.6641C45.4539 24.4393 45.5002 25.2193 45.5 26C45.5 29.8567 44.3563 33.6269 42.2137 36.8336C40.071 40.0404 37.0255 42.5397 33.4623 44.0156C29.8992 45.4916 25.9784 45.8777 22.1957 45.1253C18.4131 44.3729 14.9385 42.5157 12.2114 39.7886C9.4843 37.0615 7.62711 33.5869 6.8747 29.8043C6.12228 26.0216 6.50845 22.1008 7.98436 18.5377C9.46027 14.9745 11.9596 11.929 15.1664 9.78634C18.3731 7.64365 22.1433 6.5 26 6.5C26.6101 6.5 27.2133 6.52761 27.8095 6.58284"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default UserLogo;
