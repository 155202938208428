import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Button from '../Button/Button';
import CloseIcon from '../../../assets/icons/CloseIcon';
import { Dialog } from '@mui/material';
// import { send } from '../../../WebSocket/WebSocket';
import '../../../assets/stylesheets/common.scss';
import './DownloadPopup.scss';
import { connect } from 'react-redux';

const DownloadPopup = ({
    open,
    setOpen,
    page,
    type,
    all,
    list,
    module,
    downloadUrl,
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const download = async () => {
            // send({
            //     ids: all ? 'all' : list,
            //     module: module,
            //     download_type: type,
            // });
        };
        if (open === true) download();
    }, [open]);

    useEffect(() => {
        const download = async () => {
            try {
                downloadUrl !== '' &&
                    axios({
                        url: downloadUrl,
                        method: 'GET',
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => {
                            let progress = Math.round(
                                (progressEvent.loaded / progressEvent.total) *
                                    100
                            );

                            setProgress(progress);
                        },
                    });
            } catch (error) {
                console.log(error);
            }
        };
        if (open === true) download();
    }, [open, downloadUrl]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
        >
            <div
                className="downloadPopup px-42 py-24"
                style={{ width: '550px' }}
            >
                <div className="flex justify-between items-center mb-10">
                    <h3 className="weight-500 text-18">Downloading</h3>
                    <div className="flex justify-between">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-between text-14 items-end weight-500">
                        <p className="w-50">
                            Downloading {type} document of {page} data for{' '}
                            {all ? 'all' : list.length} {page}s
                        </p>
                        <p>24/36 {page}s</p>
                    </div>
                    <div className="progressBar">
                        <div style={{ width: `${progress}%` }}></div>
                    </div>
                    <div
                        className="text-center mt-30"
                        onClick={() => setOpen(false)}
                    >
                        <Button popupBtn>OK</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    downloadUrl: state.auth.downloadUrl,
});

export default connect(mapStateToProps)(DownloadPopup);

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// import Button from '../Button/Button';
// import CloseIcon from '../../../Icons/CloseIcon';
// import { Dialog } from '@material-ui/core';
// import { send } from '../../../WebSocket/WebSocket';
// import '../../../assets/stylesheets/common.scss';
// import './DownloadPopup.scss';
// import { connect } from 'react-redux';

// const DownloadPopup = ({
//     open,
//     setOpen,
//     page,
//     type,
//     all,
//     list,
//     module,
//     downloadUrl,
// }) => {
//     const [progress, setProgress] = useState(0);

//     useEffect(() => {
//         const download = async () => {
//             send({
//                 ids: all ? 'all' : list,
//                 module: module,
//                 download_type: type,
//             });
//         };
//         if (open === true) download();
//     }, [open]);

//     useEffect(() => {
//         const download = async () => {
//             try {
//                 if (downloadUrl !== '') {
//                     var req = new XMLHttpRequest();
//                     req.open('POST', downloadUrl, true);
//                     req.addEventListener(
//                         'progress',
//                         function (evt) {
//                             if (evt.lengthComputable) {
//                                 var percentComplete = evt.loaded / evt.total;
//                                 setProgress(percentComplete);
//                             }
//                         },
//                         false
//                     );

//                     req.responseType = 'blob';
//                     req.onreadystatechange = function () {
//                         if (req.readyState === 4 && req.status === 200) {
//                             //var filename = $(that).data('filename');
//                             if (typeof window.chrome !== 'undefined') {
//                                 // Chrome version
//                                 var link = document.createElement('a');
//                                 link.href = window.URL.createObjectURL(
//                                     req.response
//                                 );
//                                 link.download = true;
//                                 link.click();
//                             } else if (
//                                 typeof window.navigator.msSaveBlob !==
//                                 'undefined'
//                             ) {
//                                 // IE version
//                                 var blob = new Blob([req.response], {
//                                     type: 'application/force-download',
//                                 });
//                                 window.navigator.msSaveBlob(blob, true);
//                             } else {
//                                 // Firefox version
//                                 var file = new File([req.response], true, {
//                                     type: 'application/force-download',
//                                 });
//                                 window.open(URL.createObjectURL(file));
//                             }
//                         }
//                     };
//                     req.send();
//                 }
//             } catch (error) {
//                 console.log(error);
//             }
//         };
//         if (open === true) download();
//     }, [open, downloadUrl]);

//     return (
//         <Dialog
//             open={open}
//             onClose={() => {
//                 setOpen(false);
//             }}
//         >
//             <div
//                 className="downloadPopup px-42 py-24"
//                 style={{ width: '550px' }}
//             >
//                 <div className="flex justify-between items-center mb-10">
//                     <h3 className="weight-500 text-18">Downloading</h3>
//                     <div className="flex justify-between">
//                         <div
//                             className="cursor-pointer"
//                             onClick={() => {
//                                 setOpen(false);
//                             }}
//                         >
//                             <CloseIcon />
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div className="flex justify-between text-14 items-end weight-500">
//                         <p className="w-50">
//                             Downloading {type} document of {page} data for{' '}
//                             {all ? 'all' : list.length} {page}s
//                         </p>
//                         <p>24/36 {page}s</p>
//                     </div>
//                     <div className="progressBar">
//                         <div style={{ width: `${progress}%` }}></div>
//                     </div>
//                     <div
//                         className="text-center mt-30"
//                         onClick={() => setOpen(false)}
//                     >
//                         <Button popupBtn>OK</Button>
//                     </div>
//                 </div>
//             </div>
//         </Dialog>
//     );
// };

// const mapStateToProps = (state) => ({
//     downloadUrl: state.auth.downloadUrl,
// });

// export default connect(mapStateToProps)(DownloadPopup);
