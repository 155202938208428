import React from 'react';
import millify from 'millify';

import './InfoGroup.scss';
import '../../../assets/stylesheets/common.scss';

import UsersIcon from '../../../assets/icons/UsersIcon';
import Lightbulb from '../../../assets/icons/Lightbulb';
import Warning from '../../../assets/icons/Warning';
import Chats from '../../../assets/icons/Chats';

const InfoGroup = ({ name, users, lightbulb, warning, chats, count }) => {
    return (
        <div className="infoGroup b-10 flex items-center mb-28">
            <div className="mr-20">
                {users && <UsersIcon />}
                {lightbulb && <Lightbulb />}
                {warning && <Warning height="29" width="47" />}
                {chats && <Chats />}
            </div>
            <div>
                <h3 className="weight-600 text-18 text-center mb-4">
                    {millify(count)}
                </h3>
                <p className="text-14 text-center">Total {name}</p>
            </div>
        </div>
    );
};

export default InfoGroup;
