import { SpamQuestionActors } from "../Actors/SpamQuestionActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    SpamQuestionList:{data:{body:[]},count:{},isLoaded:false},
};

const SpamQuestionListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SpamQuestionActors.SPAM_QUESTION_LIST:
            return {
                ...state,
                SpamQuestionList:action.payload
            }
        case SpamQuestionActors.SPAM_QUESTION_TOGGLE:
            return {
                ...state,
                SpamQuestionList:{...state.SpamQuestionList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default SpamQuestionListReducer