import React, { useState, useEffect } from "react";
import Table from "../../Common/Table/Table";
import queryString from "query-string";
import "./Spam.scss";
import { useLocation, withRouter, Redirect } from "react-router-dom";
import StatTile from "../../Common/StatsTile/StatTile";
import Spinner from "../../Common/Spinner/Spinner";
import Api from "../../../Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import BlockedPopup from "../../Common/BlockedPopup/BlockedPopup";
import { connect, useDispatch } from "react-redux";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import HeaderWithSearch from "../../Common/HeaderWithSearch/HeaderWithSearch";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import Button from "../../Common/Button/Button";
// import { send } from '../../WebSocket/WebSocket';
import PDFIcon from "../../../assets/icons/PDFIcon";
import CSVIcon from "../../../assets/icons/CSVIcon";
import XLSIcon from "../../../assets/icons/XLSIcon";
import DraggableDialog from "../../Common/DialogBox/DialogBox";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SpamQuestions({ loading, SpamQuestionList,permissionIds }) {
  const navigate = useNavigate();
  let { status } = useParams();
  const dispatch = useDispatch();
  const permission = permissionIds.permissionIds['spam-questions']
  const [SpamQuestionStatData, setSpamQuestionStatData] = useState();
  const [SpamQuestionDataSource, setSpamQuestionDataSource] = useState();
  // const [name, setName] = useState(status);
  const [limit, setLimit] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [offset, setOffset] = useState("");
  const today = moment(new Date()).format('YYYY-MM-DD');
    const monthly = moment(today).subtract(5, 'months').format('YYYY-MM-DD');
    const [fromDate, setFromDate] = useState(monthly);
    const [toDate, setToDate] = useState(today);
    
  const [searchGroup, setSearchGroup] = useState("");
  const [checked, setChecked] = useState(false);
  const [deleteElement, setDeleteElement] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [SpamQuestionPagination, setSpamQuestionPagination] = useState();
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [compLoading, setCompLoading] = useState(true);
  const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  // const [activatePopup, setActivatePopup] = useState(false);
  // const [deletePopup, setDeletePopup] = useState(false);
  const location = useLocation();
  const headerLayout = "40px 40px 4fr 3fr 3fr";
  const columnLayout = "40px 40px 4fr 3fr 3fr";
  const header = [
    // <input type="checkbox" />,
    <div className="checkbox">
      <input
        type="checkbox"
        id="header"
        checked={selectAllChecked}
        onChange={() => {
          setSelectAllChecked(!selectAllChecked);
          !selectAllChecked && SpamQuestionDataSource
            ? setSelectedElements(SpamQuestionDataSource.map((data) => data.id))
            : setSelectedElements([]);
        }}
      />
      <label htmlFor="header" />
    </div>,
    "#",
    "Question",
    "Reported By",
    "Reported On",
    
  ];
  // console.log(SpamQuestionDataSource)
  useEffect(() => {
    SpamQuestionStatFunction();
    SpamQuestionDataFunction(searchGroup, status, fromDate, toDate, "", "");
  }, [SpamQuestionList.isLoaded]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    SpamQuestionDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset
    );
  }, [location, fromDate, toDate]);

  useEffect(() => {
    if (searchGroup === "") {
      const parsed = queryString.parse(location.search);
      SpamQuestionDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset
      );
    }
  }, [searchGroup]);

  const SpamQuestionStatFunction = () => {
    setSpamQuestionStatData({
      active: 0,
    });
  };
  const close = async () => {
    let data = {};
    data["spam_question_ids"] = selectedElements;
    Api.closeSpamQuestion([1]).then((res)=>console.log(res)).catch(err=>console.log(err))
    const response = await Api.closeSpamQuestion(data);
    console.log(response);
    if (response?.status === 200) {
      const newData = SpamQuestionDataSource.filter(
        (item) => !selectedElements.includes(item.id)
      );
      setMessage(`Question${selectedElements.length > 1 ? "s " : " "}Closed`);
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "SPAM_QUESTION_TOGGLE" });
      // console.log(newData)
      setSelectedElements([]);
    } else {
      setMessage(`Some Error Occured`);
      setSeverity("error");
      setOpenMessage(true);
      setSelectedElements([]);
    }
  };
  const deleteSpam = async () => {
    let data = {};
    data["spam_question_ids"] = selectedElements;
    const response = await Api.deleteSpamQuestion(data);
    console.log(response, "reached");
    if (response?.status === 204) {
      const newData = SpamQuestionDataSource.filter(
        (item) => !selectedElements.includes(item.id)
      );
      setMessage(`Question${selectedElements.length > 1 ? "s " : " "}deleted`);
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "SPAM_QUESTION_TOGGLE" });
      // console.log(newData)
      setSelectedElements([]);
    } else {
      setMessage(`Some Error Occured`);
      setSeverity("error");
      setOpenMessage(true);
      setSelectedElements([]);
    }
  };

  console.log(SpamQuestionList);
  const SpamQuestionDataFunction = async (
    searchGroup,
    status,
    fromDate,
    toDate,
    limit,
    offset
  ) => {
    setSelectedElements([]);
    const apiCall = async () => {
      setCompLoading(true);
      try {
        offset = offset ? offset : 0;
        console.log("Called",searchGroup,
        status,
        fromDate,
        toDate,
        limit,
        offset);
        await Api.getSpamQuestions(
         { searchGroup,
          status,
          fromDate,
          toDate,
          limit,
          offset}
        ).then((response) => {
          console.log(response);
          setSpamQuestionStatData({
            active: response?.data?.count ? response.data.count : 0,
          });
          setSpamQuestionDataSource(
            response.data?.body?.map(({ question, by,created_at,pk }, index) => {
              return {
                no: index + 1,
                id: pk,
                question: question,
                asked_by: by.fullname,
                created_at: created_at,

              };
            })
          );
          setSpamQuestionPagination(response?.data)
          if (!SpamQuestionList.isLoaded && toDate===today && fromDate===monthly )
            dispatch({
              type: "SPAM_QUESTION_LIST",
              payload: { data: response.data, isLoaded: true },
            });
          setPageLoading(false);
          setCompLoading(false);
        });
      } catch (error) {
        setGetErrorMessage(error.response?.data?.detail);
        setOpenGetError(true);
      }
    };
    if (!SpamQuestionList.isLoaded || fromDate!==monthly || toDate!==today) apiCall();
    else {
      setSpamQuestionStatData({
        active: SpamQuestionList.data?.count ? SpamQuestionList.data.count : 0,
      });
      setSpamQuestionDataSource(
        SpamQuestionList.data?.body?.map(({ question, by,created_at,pk }, index) => {
          return {
            no: index + 1,
            id: pk,
            question: question,
            asked_by: by.fullname,
            created_at: created_at,

          };
        })
      );
      setSpamQuestionPagination(SpamQuestionList.data)
      setPageLoading(false);
      setCompLoading(false);
    }
  };

  const paginationData = (data) => {
    var parsed = queryString.parse(data);
    // setLimit(parsed.limit);
    // setOffset(parsed.offset);
    const offset = parsed.offset ? parsed.offset : 0;
    SpamQuestionDataFunction(
      searchGroup,
      status,
      fromDate,
      toDate,
      parsed.limit,
      parsed.offset
    );
    navigate(`/spam-replies/${status}?limit=${parsed.limit}&offset=${offset}`);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleCheck = (key) => {
    selectedElements.includes(key)
      ? setSelectedElements(
          selectedElements.filter(function (item) {
            return item !== key;
          })
        )
      : setSelectedElements([...selectedElements, key]);
  };

  useEffect(() => {
    if (selectedElements?.length === SpamQuestionDataSource?.length)
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
  }, [selectedElements]);

  const colorMap = {
    closed: "#FF5B5B",
    active: "#42DA93",
    // deleted: '#FF5B5B',
    all: "#000000",
  };

  const statNameMap = {
    active: "Active",
    closed: "Closed",
    inactive: "Inactive",
    deleted: "Deleted",
    all: "Total",
  };

  const tileClickHandler = (status) => {
    navigate(`/spam-replies/${status}?limit=${""}&offset=${""}`);
    SpamQuestionDataFunction(searchGroup, status, fromDate, toDate, limit, offset);
    setSelectedElements([]);
  };

  const startDate = (date, dateString) => {
    setFromDate(dateString);
  };

  const endDate = (date, dateString) => {
    setToDate(dateString);
  };

  const search = (event) => {
    setSearchGroup(event.target.value);
  };

  const keyPress = (event) => {
    if (event.key === "Enter") {
      const parsed = queryString.parse(location.search);
      SpamQuestionDataFunction(
        searchGroup,
        status,
        fromDate,
        toDate,
        parsed.limit,
        parsed.offset
      );
    }
  };

  const download = ({ type, all }) => {
    setSelectedElements([]);
  };

  function disabledStartDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrAfter(toDate)
    );
  }
  function disabledEndDate(current) {
    return (
      (current && current.valueOf() > Date.now()) ||
      current.isSameOrBefore(fromDate)
    );
  }

  return loading || pageLoading ? (
    <Spinner />
  ) : (
    <div className="SQ w-full">
      <HeaderWithSearch
        name="Spam Questions"
        handleChange={search}
        keyPress={keyPress}
      />
      {/* <StatTile
        statData={SpamQuestionStatData}
        statNameMap={statNameMap}
        colorMap={colorMap}
        onClick={tileClickHandler}
        keyPress={keyPress}
      /> */}
      {selectedElements.length > 0 ? (
        <>
          <div className="mid flex justify-between items-center my-16">
            <div className="flex btns">
              <div className="downloadBtnDropdown">
                <Button popupBtn blackOutline>
                  Download All
                </Button>
                <div className="dropdownContent b-6">
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() =>
                      download({
                        type: "pdf",
                        all: true,
                      })
                    }
                  >
                    <PDFIcon />
                    Download as PDF
                  </button>
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() =>
                      download({
                        type: "csv",
                        all: true,
                      })
                    }
                  >
                    <CSVIcon />
                    Download as CSV
                  </button>
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={() =>
                      download({
                        type: "xlsx",
                        all: true,
                      })
                    }
                  >
                    <XLSIcon />
                    Download as XLSX
                  </button>
                </div>
              </div>
              <div className="downloadBtnDropdown">
                <Button popupBtn blackOutline>
                  {`Download ${selectedElements.length} ${
                    selectedElements.length === 1 ? "Question" : "Questions"
                  }`}
                </Button>
                <div className="dropdownContent b-6" style={{ width: "14rem" }}>
                  <button
                    className="flex justify-between items-center text-14 w-full px-30"
                    onClick={() => download({ type: "pdf" })}
                  >
                    <PDFIcon />
                    Download as PDF
                  </button>
                  <button
                    className="flex justify-between items-center text-14 w-full px-30"
                    onClick={() => download({ type: "csv" })}
                  >
                    <CSVIcon />
                    Download as CSV
                  </button>
                  <button
                    className="flex justify-between items-center text-14 w-full px-30"
                    onClick={() => download({ type: "xlsx" })}
                  >
                    <XLSIcon />
                    Download as XLSX
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mid flex justify-between items-center my-16">
            <div className="flex btns">
              <div>
                <Button
                  popupBtn
                  blackOutline
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => {
                    setSelectedElements(
                      SpamQuestionDataSource.map((item) => item.id)
                    );
                    setBlockPopup(true);
                  }}
                >
                  Close All
                </Button>
              </div>
              <div>
                <Button
                  popupBtn
                  blackOutline
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => {
                    setBlockPopup(true);
                  }}
                >
                  {`Close ${selectedElements.length} ${
                    selectedElements.length === 1 ? "Question" : "Questions"
                  }`}
                </Button>
              </div>

              <div>
                <Button
                  popupBtn
                  blackOutline
                  style={{ marginRight: "1.5rem",color:"red",borderColor:"red"}}
                  onClick={() => {
                    setSelectedElements(
                      SpamQuestionDataSource.map((item) => item.id)
                    );
                    setDeletePopup(true);
                  }}
                >
                  Delete All
                </Button>
              </div>
              <div>
                <Button
                  popupBtn
                  blackOutline
                  style={{ marginRight: "1.5rem" ,color:"red",borderColor:"red"}}
                  onClick={() => setDeletePopup(true)}
                >
                  {`Delete ${selectedElements.length} ${
                    selectedElements.length === 1 ? "Question" : "Questions"
                  }`}
                </Button>
              </div>
              {/* {status === 'inactive' && (
                                <Button
                                    popupBtn
                                    blackOutline
                                    onClick={() => setActivatePopup(true)}
                                >
                                    {selectedElements.length === 1
                                        ? 'Activate SpamQuestion'
                                        : 'Activate SpamQuestions'}
                                </Button>
                            )} */}
              {status === "active" && (
                <Button popupBtn onClick={() => setDeactivatePopup(true)}>
                  {selectedElements.length === 1
                    ? "Close Match"
                    : "Close Selected Matches"}
                </Button>
              )}
            </div>
            <Pagination page={SpamQuestionPagination} onChange={paginationData} />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="mid flex justify-between my-16">
            <div className="flex">
              <DatePicker
                className="datePickerStyle"
                onChange={startDate}
                disabledDate={disabledStartDate}
              />
              <div className="dash mx-14 mt-14"></div>
              <DatePicker
                className="datePickerStyle"
                onChange={endDate}
                disabledDate={disabledEndDate}
              />
            </div>
            <Pagination page={SpamQuestionPagination} onChange={paginationData} />
          </div>{" "}
        </>
      )}

      {compLoading ? (
        <Spinner top="50%" left="50%" />
      ) : (
        <div className="customTable">
          <div className="customTable-div">
            <Table
              headerLayout={headerLayout}
              columnLayout={columnLayout}
              headers={header}
              dataSource={SpamQuestionDataSource}
              dataKey={[
                {
                  name: "SelectBox",
                  render: (record) => {
                    return (
                      <div className="py-4 self-center checkbox">
                        <input
                          type="checkbox"
                          className="py-6"
                          id={record.id}
                          checked={selectedElements.includes(record.id)}
                          onChange={() => handleCheck(record.id)}
                        />
                        <label htmlFor={record.id} />
                      </div>
                    );
                  },
                },
                {
                  name: "no",
                  render: (record) => {
                    return (
                      <div className="self-center truncate py-4">
                        {record.no}
                      </div>
                    );
                  },
                },
                {
                  name: "Question",
                  render: (record) => {
                    return (
                      <div>
                        <div
                          style={{ padding: "4px 0px 0px 0px" }}
                          className="flex items-center"
                        >
                          {record.question.substring(0, 100)}
                          {record.question.length > 100 ? "..." : ""}
                        </div>
                        {record.question.length > 100 && (
                          <DraggableDialog
                            title="Question"
                            content={record.question}
                          />
                        )}
                      </div>
                    );
                  },
                },
                {
                  name: "asked_by",
                  render: (record) => {
                    return (
                      <div className="self-center truncate py-4 my-8 height-30">
                        {record.asked_by}
                      </div>
                    );
                  },
                },
                {
                  name: "asked_on",
                  render: (record) => {
                    return (
                      <div className="self-center truncate py-4">
                        {moment(record.created_at).format(
                          "DD/MM/YYYY, hh:mm a"
                        )}
                      </div>
                    );
                  },
                },

               
              ]}
            />
          </div>
        </div>
      )}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <BlockedPopup
        open={openGetError}
        setOpen={setOpenGetError}
        message={getErrorMessage}
      />
      <ConfirmPopup
        open={blockPopup}
        setOpen={setBlockPopup}
        name="Close"
        page="Question"
        num={selectedElements.length}
        action="close"
        handleChange={() => close()}
      />
      <ConfirmPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        name="Delete"
        page="Question"
        num={selectedElements.length}
        action="Delete"
        handleChange={() => deleteSpam()}
      />
      {/* <ConfirmPopup
                open={deactivatePopup}
                setOpen={setDeactivatePopup}
                name="Close"
                page="matches"
                num={selectedElements.length}
                action="close"
                handleChange={() => closeMatch()}
            /> */}
      {/* <ConfirmPopup
                open={activatePopup}
                setOpen={setActivatePopup}
                name="Activate"
                page="SpamQuestion"
                action="activate"
                handleChange={() => activateSpamQuestion()}
            /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  SpamQuestionList: state.spamQuestion.SpamQuestionList,
});

export default connect(mapStateToProps)(SpamQuestions);
