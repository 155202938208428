import { SpamAnswerActors } from "../Actors/SpamAnswerActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    SpamAnswerList:{data:{body:[]},count:{},isLoaded:false},
};

const SpamAnswerListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SpamAnswerActors.SPAM_ANSWER_LIST:
            return {
                ...state,
                SpamAnswerList:action.payload
            }
        case SpamAnswerActors.SPAM_ANSWER_TOGGLE:
            return {
                ...state,
                SpamAnswerList:{...state.SpamAnswerList,isLoaded:false}
            }
        default:
            return state;
    }
};
export default SpamAnswerListReducer