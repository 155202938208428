import React from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import { Navigate , useNavigate } from 'react-router-dom';

import '../../assets/stylesheets/common.scss';
import './LoginPage.scss';
// import Logo from '../../../assets/Logo';
// import thoughtzLogo from '../../assets/Icons/ThoughtzLogoIcon.jpg'
import FormInput from '../Common/FormInput/FormInput';
import Button from '../Common/Button/Button';

import { login } from '../../redux/Actions/Auth';


// const withRouter = (Component) => {
//     const Wrapper = (props) => {
//     return <Component history={navigate} {...props} />;
// };

function LoginPage(props) {

    const [formData,setFormData] =  useState({
        email: '',
        password: '',
    })
    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { email, password } = formData;
        await props.login({ email, password });

        if (props.isAuthenticated) {
            navigate('/users/all');
        }
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        setFormData({...formData, [name]: value });
    };

        if (!localStorage.getItem('access_token')) {
            return (
                <div className="loginPage w-100">
                    <div className="mt-30 ml-20 flex logo">
                        <span className='logo-txt'>
                            Collectnea 
                        </span>
                    </div>
                    <div className="formDiv">
                        <div className="loginText text-center">
                            <h1 className="weight-700 text-36">Admin Login</h1>
                            <p className="weight-400 text-18">
                                Login to your account
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <FormInput
                                    type="email"
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Your Official E-Mail ID"
                                    handleChange={handleChange}
                                    value={formData.email}
                                    required
                                    message={
                                        !props.errorMessage?.includes(
                                            'Password'
                                        )
                                            ? props.errorMessage
                                            : null
                                    }
                                />
                                <FormInput
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="••••••••"
                                    letter_spacing
                                    handleChange={handleChange}
                                    value={formData.password}
                                    required
                                    message={
                                        props.errorMessage?.includes(
                                            'Password'
                                        )
                                            ? props.errorMessage
                                            : null
                                    }
                                />
                            </div>
                            <p className='colored-text forgot' onClick={()=>navigate('/forgotPassword')}>
                            Forgot Password?
                            </p>
                            
                            {/* <p
                                className="forgot weight-500 text-end cursor-pointer"
                                onClick={() =>
                                    props.history.push('/forgot-password')
                                }
                            >
                                Forgot Password?
                            </p> */}
                            <span className='signup-btn' >
                                <Button  loginPage type="submit">
                                    Sign In
                                </Button>
                            </span>
                            <p className='colored-text forgot'>
                               
                            </p>
                        </form>
                    </div>
                </div>
            );
        } else {
            return <Navigate to="/users/all" />;
        }
    }

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
});

export default connect(mapStateToProps, { login })(LoginPage);
