import { UserListPageActors } from "../Actors/UserListPageActors";

const INITIAL_STATE = {
  // infoPopup: false,
  // downloadPopup: '',
  selectedRows: [
    { name: "avatar", label: "Avatar", checked: true, width: "15%" },
    { name: "fullname", label: "Name", checked: true, width: "30%" },
    { name: "username", label: "Username", checked: true, width: "30%" },
    // { name: 'user_id', label: 'User ID', checked: true, width: '10%' },
    { name: "email", label: "Email ID", checked: true, width: "35%" },
    { name: "bio", label: "Bio", checked: true, width: "40%" },
  ],
  selectedUsers: [],
  selectedUser: {},
  userList: { data: { body: [] }, count: {}, isLoaded: false },
  ActiveUserList: { data: { body: [] }, count: {}, isLoaded: false },
  BlockedUserList: { data: { body: [] }, count: {}, isLoaded: false },
  DeletedUserList: { data: { body: [] }, count: {}, isLoaded: false },
};

const UserListPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case UserListPageActors.TOGGLE_INFO_POPUP:
    //     return {
    //         ...state,
    //         infoPopup: !state.infoPopup,
    //     };
    case UserListPageActors.TOGGLE_DOWNLOAD_POPUP:
      return {
        ...state,
        downloadPopup: action.payload,
      };
    case UserListPageActors.SELECTED_ROWS:
      return {
        ...state,
        selectedUsers: [],
        selectedRows: action.payload.rows.map((item, index) => {
          return {
            name: action.payload.rows[index].id,
            label: action.payload.rows[index].label,
            checked: action.payload.checkedState[index],
            width: action.payload.rows[index].width,
          };
        }),
      };
    case UserListPageActors.SELECTED_USERS:
      return {
        ...state,
        selectedUsers: state.selectedUsers.includes(action.payload)
          ? state.selectedUsers.filter(function (item) {
              return item !== action.payload;
            })
          : [...state.selectedUsers, action.payload],
      };
    case UserListPageActors.SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case UserListPageActors.EMPTY_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: [],
      };
    case UserListPageActors.USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case UserListPageActors.TOGGLE_USER_LIST:
      console.log("Reached");
      return {
        ...state,
        userList: { ...state.userList, isLoaded: false },
      };
    case UserListPageActors.ACTIVE_USER_LIST:
      return {
        ...state,
        ActiveUserList: action.payload,
      };
    case UserListPageActors.TOGGLE_ACTIVE_USER_LIST:
      return {
        ...state,
        ActiveUserList: { ...state.ActiveUserList, isLoaded: false },
      };
    case UserListPageActors.BLOCKED_USER_LIST:
      return {
        ...state,
        BlockedUserList: action.payload,
      };
    case UserListPageActors.TOGGLE_BLOCKED_USER_LIST:
      return {
        ...state,
        BlockedUserList: { ...state.BlockedUserList, isLoaded: false },
      };
    case UserListPageActors.DELETED_USER_LIST:
      return {
        ...state,
        DeletedUserList: action.payload,
      };
    case UserListPageActors.TOGGLE_DELETED_USER_LIST:
      return {
        ...state,
        DeletedUserList: { ...state.DeletedUserList, isLoaded: false },
      };
    default:
      return state;
  }
};

export default UserListPageReducer;
