import React from 'react';

const UsersIcon = ({ width = '42', height = '42' }) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.4375 26.25C19.1492 26.25 22.9688 22.4304 22.9688 17.7188C22.9688 13.0071 19.1492 9.1875 14.4375 9.1875C9.72582 9.1875 5.90625 13.0071 5.90625 17.7188C5.90625 22.4304 9.72582 26.25 14.4375 26.25Z"
                    stroke="#FFDF37"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M25.4971 9.50529C26.6705 9.17468 27.9011 9.09937 29.1061 9.28442C30.311 9.46947 31.4624 9.91059 32.4825 10.5781C33.5026 11.2455 34.3679 12.1239 35.02 13.1539C35.6721 14.1839 36.0958 15.3417 36.2628 16.5494C36.4297 17.757 36.3359 18.9863 35.9878 20.1546C35.6396 21.323 35.0451 22.4031 34.2443 23.3223C33.4435 24.2415 32.4551 24.9784 31.3455 25.4835C30.2359 25.9885 29.031 26.2499 27.8119 26.25"
                    stroke="#FFDF37"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.62451 32.3854C3.95692 30.4901 5.72577 28.9433 7.78173 27.8754C9.8377 26.8076 12.1204 26.2501 14.4371 26.25C16.7539 26.2499 19.0366 26.8072 21.0927 27.8749C23.1487 28.9426 24.9177 30.4893 26.2503 32.3845"
                    stroke="#FFDF37"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.812 26.25C30.129 26.2483 32.4122 26.805 34.4684 27.8728C36.5246 28.9406 38.2933 30.4882 39.6246 32.3845"
                    stroke="#FFDF37"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default UsersIcon;
