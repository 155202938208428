import { CategoryActors } from "../Actors/CategoryActors";

const INITIAL_STATE = {
    // infoPopup: false,
    // downloadPopup: '',
    CategoryList:{data:{body:[]},count:{},isLoaded:false},
    InactiveCategoryList:{data:{body:[]},count:{},isLoaded:false}
};

const CategoryListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CategoryActors.CATEGORY_LIST:
            return {
                ...state,
                CategoryList:action.payload
            }
        case CategoryActors.TOGGLE_CATEGORY:
            console.log("Reached")
            return {
                ...state,
                CategoryList:{...state.CategoryList,isLoaded:false}
            }
            case CategoryActors.INACTIVE_LIST:
                return {
                    ...state,
                    InactiveCategoryList:action.payload
                }
            case CategoryActors.TOGGLE_INACTIVE_LIST:
                console.log("Reached")
                return {
                    ...state,
                    InactiveCategoryList:{...state.InactiveCategoryList,isLoaded:false}
                }
        default:
            return state;
    }
};
export default CategoryListReducer