import React, { useState, useEffect } from "react";
import "./StatTile.scss";
import { useParams } from "react-router-dom";
import UserCount from "../UserCount/UserCount";

function StatTile(props) {
  const { statData, statNameMap, colorMap, onClick,total } = props;
  let { status } = useParams();

  const [statTile, setStatTile] = useState();
  const [count, setCount] = useState(statData);

  useEffect(() => {
    if (statData) {
      var totalGroupValue = Object.values(statData).reduce(
        (accum, key) => accum + key
      );
      if(status === 'all'){
        var updatedState = Object.assign({ all: totalGroupValue }, statData);
      }
      else if(status === 'All'){
        var updatedState = Object.assign({ All: totalGroupValue }, statData);
      }
      else if(status === 'open')
      {
        var updatedState = Object.assign({ open: totalGroupValue }, statData);
      }
      
      else{
        var updatedState = Object.assign({ all: totalGroupValue }, statData);
      }
      setCount(updatedState);
      setStatTile(Object.keys(!total?updatedState:statData));
    }
  }, [statData]);


  return (
    <>
      <div className="flex flex-row StatTile mt-0 mb-0">
        
        {statTile?.map((element) => {
          return (
            <div
              className={`userCount text-center flex flex-col w-25 cursor-pointer ${
                element === status && "active"
              }`}
              onClick={() => onClick(element)}
              key={statNameMap[element]}
            >
              <UserCount
                name={statNameMap[element]}
                count={count[element]}
                color={colorMap[element]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default StatTile;
