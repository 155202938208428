import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Button from "../../Common/Button/Button";
import Api from "../../../Api";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "../../ListPages/AdminListPage/icons";
import TextIcon from "../../Common/TextIcon/TextIcon";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import BlockedPopup from "../../Common/BlockedPopup/BlockedPopup";
import Spinner from "../../Common/Spinner/Spinner";
import { connect, useDispatch } from "react-redux";
import ConfirmPopup from "../../Common/ConfirmPopup/ConfirmPopup";
import { useLocation } from "react-router";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminDetails = ({ loading, permissionIds }) => {
  const [adminDetails, setAdminDetails] = useState({});
  const [userPermissions, setUserPermissions] = useState([]);
  const { id } = useParams();
  const permission = permissionIds.permissionIds["admins"];
  console.log(
    permission,
    permissionIds?.userPermissions[permission - 1]?.is_enabled === true
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openGetError, setOpenGetError] = useState(false);
  const [getErrorMessage, setGetErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);

  const pageLoad = () => {
    const apiCall = async () => {
      try {
        let response = await Api.getAdminsDetails(id);
        setAdminDetails(response.data?.body);

        if (response.data.body.status !== "Deleted")
          await Api.getUserPermissions(id).then((res) =>
            setUserPermissions(res.data?.body)
          );
        setPageLoading(false);
      } catch (error) {
        // setPageLoading(false)
        console.log(error.response);
        setMessage("User Deleted");
        setSeverity("success");
        setOpenMessage(true);
        navigate("/account");
        // setGetErrorMessage(error.response?.data?.detail);
        // setOpenGetError(true);
      }
    };
    apiCall();
  };

  useEffect(() => {
    pageLoad();
  }, [id]);

  const handleClose = () => {
    setOpenMessage(false);
  };

  const deleteAdmin = async () => {
    var data = {};
    data["ids"] = [id];
    Api.deleteAdmins(data).then(() => {
      navigate("/admins/all");
      setMessage("User Deleted");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_ADMINS" });
    });
  };

  const deactivateAdmin = async () => {
    var data = {};
    data["status"] = "Deactivated";
    data["ids"] = [id];

    const res = await Api.changeStatusAdmins(data);

    if (res?.status === 200) {
      pageLoad();
      setMessage("Admin Deactivated");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_ADMINS" });
    }
  };
  const activateAdmin = async () => {
    var data = {};
    data["status"] = "Activated";
    data["ids"] = [id];

    const res = await Api.changeStatusAdmins(data);

    if (res?.status === 200) {
      pageLoad();
      setMessage("Admin Activated");
      setSeverity("success");
      setOpenMessage(true);
      dispatch({ type: "TOGGLE_ADMINS" });
    }
  };
  // const blockAdmin = async () => {
  //     var data = {}
  //     data["status"] = "Activated"
  //     data["id"] = [id]

  //     const res = await Api.changeStatusAdmins(data);

  //     if (res?.status === 200) {
  //         pageLoad();
  //         setMessage('Admin Blocked');
  //         setSeverity('success');
  //         setOpenMessage(true);
  //     }
  // };

  const togglePermissions = ({ id, checked }) => {
    console.log(id, "permss", userPermissions[id - 1]);
    const apiCall = async () => {
      var data = {};
      data["admin_id"] = adminDetails.id;
      data["permission_id"] = id;

      await Api.togglePermissions(data);
      if (
        id === 1 ||
        id === 4 ||
        id === 7 ||
        id === 9 ||
        id === 13 ||
        id === 14 ||
        id === 17 ||
        id === 20 ||
        id === 23
      ) {
        if (checked === true) {
          if (userPermissions[id].is_enabled === true) {
            data["permission_id"] = id + 1;
            await Api.togglePermissions(data);
          }
          if (userPermissions[id + 1].is_enabled === true) {
            data["permission_id"] = id + 2;
            await Api.togglePermissions(data);
          }
        }
      } else if (
        id === 2 ||
        id === 5 ||
        id === 8 ||
        id === 10 ||
        id === 15 ||
        id === 18 ||
        id === 21 ||
        id === 24
      ) {
        if (checked === false) {
          if (userPermissions[id - 2].is_enabled === false) {
            data["permission_id"] = id - 1;
            await Api.togglePermissions(data);
          }
        } else if (checked === true) {
          if (userPermissions[id].is_enabled === true) {
            data["permission_id"] = id + 1;
            await Api.togglePermissions(data);
          }
        }
      } else if (
        id === 3 ||
        id === 6 ||
        id === 11 ||
        id === 16 ||
        id === 19 ||
        id === 22
      ) {
        if (checked === false) {
          if (userPermissions[id - 2].is_enabled === false) {
            data["permission_id"] = id - 1;
            await Api.togglePermissions(data);
          }
          if (userPermissions[id - 3].is_enabled === false) {
            data["permission_id"] = id - 2;
            await Api.togglePermissions(data);
          }
        }
      }

      pageLoad();
    };
    apiCall();
  };

  return loading || pageLoading ? (
    <Spinner />
  ) : (
    <div className="adminDetailsPage w-full">
      <Header
        name={adminDetails.email ? adminDetails.email : "[Deleted User]"}
      />
      <div className="adminInfo flex justify-between pb-30 mb-40">
        <div>
          {adminDetails.status === "Activated" && (
            <div className="tag">{adminDetails.status}</div>
          )}
          {adminDetails.status === "Deactivated" && (
            <div className="tag inactive">{adminDetails.status}</div>
          )}
          {adminDetails.status === "Deleted" && (
            <div className="tag deleted">{adminDetails.status}</div>
          )}
          {/* {adminDetails.status === 'Blocked' && (
                        <div className="tag blocked">{adminDetails.status}</div>
                    )} */}
          {/* <p>
                        <strong>Admin Name: </strong> {adminDetails.full_name}
                    </p> */}
          <p>
            <strong>Email ID: </strong> {adminDetails.email}
          </p>
          <p>
            <strong>Admin Role: </strong> {adminDetails.role}
          </p>
        </div>
        <div className="self-end">
          {adminDetails.status === "Activated" && (
            <Button
              profilePage
              onClick={() => {
                if (
                  permissionIds.userPermissions[permission]?.is_enabled === true
                )
                  setDeactivatePopup(true);
              }}
              // style={{borderColor:"rgb(212, 69, 186)"}}
            >
              <span className="colored-text">Deactivate Admin</span>
            </Button>
          )}
          {adminDetails.status === "Deactivated" && (
            <Button
              profilePage
              onClick={() => {
                if (
                  permissionIds.userPermissions[permission]?.is_enabled === true
                )
                  setActivatePopup(true);
              }}
              // style={{borderColor:"rgb(212, 69, 186)"}}
            >
              <span className="colored-text">Activate Admin</span>
            </Button>
          )}
          {/* {adminDetails.status === 'Activated' && (
                        <Button
                            profilePage
                            blackOutline
                            onClick={() => setBlockPopup(true)}
                        >
                            Block User
                        </Button>
                    )} */}
          {adminDetails.status !== "Deleted" && (
            <Button
              profilePage
              // orangeFill
              style={{ color: "red", borderColor: "red" }}
              onClick={() => {
                if (
                  permissionIds.userPermissions[permission + 1]?.is_enabled ===
                  true
                )
                  setDeletePopup(true);
              }}
            >
              Delete Admin
            </Button>
          )}
          {/* <Button
                            profilePage
                            onClick={() => navigate('/set-admin-password')}
                            style={{borderColor:"rgb(212, 69, 186)"}}
                        >
                            <span className='colored-text'>
                            Change password
                            </span>
                    </Button> */}
        </div>
      </div>
      {adminDetails.status !== "Deleted" && (
        <div className="customTable">
          <div className="customTable-div">
            <div className="iconSwitches flex">
              <div
                className="mr-100 flex flex-col justify-between"
                style={{ height: "25rem", marginTop: "45px" }}
              >
                {icons.map((route) => {
                  return <TextIcon icon={route.icon} text={route.text} />;
                })}
              </div>
              <div className="switches">
                <p className="text-center" style={{ width: "60px" }}>
                  Read
                </p>
                <p className="text-center" style={{ width: "60px" }}>
                  Update
                </p>
                <p className="text-center" style={{ width: "60px" }}>
                  Delete
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={userPermissions[11].is_enabled}
                    onChange={() => togglePermissions({ id: 12 })}
                  />
                  <span className="slider round"></span>
                </label>
                <div />
                <div />
                {userPermissions.map((perm) => {
                  if (perm.id === 12) {
                    return <></>;
                  }

                  return (
                    <>
                      {14 == perm.id ? (
                        <>
                          <label className="switch">
                            <br />
                          </label>
                          <label className="switch">
                            <br />
                          </label>
                        </>
                      ) : [9].includes(perm.id) ? (
                        <label className="switch">
                          <br />
                        </label>
                      ) : null}
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={perm.is_enabled}
                          onChange={() => {
                            if (
                              permissionIds.userPermissions[permission]
                                ?.is_enabled === true
                            )
                              togglePermissions({
                                id: perm.id,
                                checked: perm.is_enabled,
                              });
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <ConfirmPopup
        open={deactivatePopup}
        setOpen={setDeactivatePopup}
        name="Deactivate"
        page="admin"
        action="deactivate"
        handleChange={() => deactivateAdmin()}
      />
      <ConfirmPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        name="Delete"
        page="admin"
        action="delete"
        handleChange={() => deleteAdmin()}
      />
      <ConfirmPopup
        open={activatePopup}
        setOpen={setActivatePopup}
        name="Activate"
        page="admin"
        action="activate"
        handleChange={() => activateAdmin()}
      />
      {/* <ConfirmPopup
                open={blockPopup}
                setOpen={setBlockPopup}
                name="Block"
                page="admin"
                action="block"
                handleChange={() => blockAdmin()}
            /> */}
      <BlockedPopup
        open={openGetError}
        setOpen={setOpenGetError}
        message={getErrorMessage}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  permissionIds: state.auth,
});

export default connect(mapStateToProps)(AdminDetails);
