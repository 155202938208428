import { combineReducers } from 'redux';

import UserListPageReducer from './Reducers/UserListPage';
import AuthReducer from './Reducers/Auth';
import QuestionListReducer from './Reducers/QuestionList';
import CategoryListReducer from './Reducers/Category';
import AnalyticsListReducer from './Reducers/Analytics';
import ReportIssueListReducer from './Reducers/ReportIssues';
import AdminsListReducer from './Reducers/AdminList';
import SpamQuestionListReducer from './Reducers/SpamQuestions';
import SpamAnswerListReducer from './Reducers/SpamAnswers';
import SpamReplyListReducer from './Reducers/SpamReplies';
import SettingsListReducer from './Reducers/Settings';

export default combineReducers({
    userList: UserListPageReducer,
    auth: AuthReducer,
    question: QuestionListReducer,
    category:CategoryListReducer,
    analytics:AnalyticsListReducer,
    reportIssue:ReportIssueListReducer,
    admins:AdminsListReducer,
    spamQuestion:SpamQuestionListReducer,
    spamAnswer:SpamAnswerListReducer,
    spamReply:SpamReplyListReducer,
    settings:SettingsListReducer
});